import React, { CSSProperties, useState, useCallback } from 'react';
import doctors from '../../img/doctors-bg.png';
import mdoctors from '../../img/m-doctors-bg.png';
import arrow from '../../img/icon-arrow.png';
import { Container, Row, Col } from 'reactstrap';
import { Animated } from 'react-animated-css';
import dict from '../../utils/dict';

// const description = [
//     "At Nucleus Farma, our scientist, researcher, and employee always discover a better product and business process to improve people's health.",
//     'At Nucleus Farma, we operate with the highest conduct of integrity and ethics, abinding by the most stringent code of health and business guidelines to ensure that we deliver the most cost effective products to our consumers, both in Indonesia and overseas.',
//     // 'Our ultimate goal is to provide a better alternative medicine at the lowest cost so people can get best inn class in treatment and quality health care cost-effectively.',
// ];

const Content = ({
    index,
    toggleNext,
    lang,
}: {
    index: string;
    toggleNext: (index: any) => void;
    lang: 'en' | 'id';
}) => {
    return (
        <Row>
            <Col sm={12} md={{ size: 5, offset: 6 }}>
                <div
                    style={{
                        height: '350px',
                        padding: '30px 0px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    {index === '0' && (
                        <Animated
                            animationIn="fadeInLeft"
                            animationOut="fadeOutRight"
                            isVisible={true}
                        >
                            <label style={styles.description}>{dict.home_desc_slider0[lang]}</label>
                        </Animated>
                    )}
                    {index === '1' && (
                        <Animated
                            animationIn="fadeInLeft"
                            animationOut="fadeOutRight"
                            isVisible={true}
                        >
                            <label style={styles.description}>{dict.home_desc_slider1[lang]}</label>
                        </Animated>
                    )}
                    {/* {index === 2 && (
                        <Animated
                            animationIn="fadeInLeft"
                            animationOut="fadeOutRight"
                            isVisible={true}
                        >
                            <label style={styles.description}>{description[2]}</label>
                        </Animated>
                    )} */}
                </div>
            </Col>
            <a onClick={toggleNext} style={styles.btn_next}>
                <img src={arrow} style={styles.arrow} />
            </a>
        </Row>
    );
};

export default ({ lang }: { lang: 'en' | 'id' }) => {
    const [index, setIndex] = useState('0');

    const toggleNext = useCallback(() => {
        if (index === '1') {
            setIndex('0');
        } else {
            setIndex(`${parseInt(index, 10) + 1}`);
        }
    }, [index]);

    return (
        <>
            <div
                style={{
                    height: '45vw',
                    minHeight: '300px',
                    maxHeight: '350px',
                    position: 'relative',
                }}
                className="d-none d-md-block"
            >
                <img src={doctors} style={styles.doctors} />

                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <Container
                        className="d-md-none d-lg-block"
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                        }}
                    >
                        <Content index={index} toggleNext={toggleNext} lang={lang} />
                    </Container>

                    <Container
                        className="d-md-block d-lg-none"
                        fluid
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                        }}
                    >
                        <Content index={index} toggleNext={toggleNext} lang={lang} />
                    </Container>
                </div>
            </div>

            <div className="d-sm-block d-md-none" style={{ position: 'relative' }}>
                <img
                    className="d-sm-block d-md-none"
                    src={mdoctors}
                    style={{ width: '100%', height: '450px', objectFit: 'cover' }}
                />
                <div
                    style={{
                        padding: '30px 20px',
                        position: 'absolute',
                        width: '100%',
                        top: 0,
                    }}
                >
                    {index === '0' && (
                        <Animated
                            animationIn="fadeInLeft"
                            animationOut="fadeOutRight"
                            isVisible={true}
                        >
                            <label style={styles.description2}>
                                {dict.home_desc_slider0[lang]}
                            </label>
                        </Animated>
                    )}
                    {index === '1' && (
                        <Animated
                            animationIn="fadeInLeft"
                            animationOut="fadeOutRight"
                            isVisible={true}
                        >
                            <label style={styles.description2}>
                                {dict.home_desc_slider1[lang]}
                            </label>
                        </Animated>
                    )}
                    {/* {index === 2 && (
                        <Animated
                            animationIn="fadeInLeft"
                            animationOut="fadeOutRight"
                            isVisible={true}
                        >
                            <label style={styles.description2}>{description[2]}</label>
                        </Animated>
                    )} */}
                    <a
                        onClick={toggleNext}
                        style={{
                            width: '60px',
                            height: '60px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '20px',
                        }}
                    >
                        <img src={arrow} style={styles.arrow} />
                    </a>
                </div>
            </div>
        </>
    );
};

const styles: { [x: string]: CSSProperties } = {
    doctors: {
        width: '100%',
        height: '350px',
        objectFit: 'cover',
    },
    description: {
        color: 'white',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
    },
    description2: {
        color: 'white',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '14px',
    },
    btn_next: {
        width: '60px',
        height: '60px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        position: 'absolute',
        right: 0,
        bottom: '80px',
        cursor: 'pointer',
    },
    arrow: {
        width: '35px',
        height: '35px',
        objectFit: 'contain',
    },
};
