import { BASE_URL } from './../../constants';

const URL_TESTIMONIY = `${BASE_URL}/testimonies`;
const URL_SUBSCRIBE = `${BASE_URL}/news-letters`;

export const getTestimonies = (onSuccess: (data: any) => void, onFailed: (e: any) => void) => {
    fetch(URL_TESTIMONIY, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const subscribeEmail = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(URL_SUBSCRIBE, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};
