import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import blogcover from '../../img/blog-thumb.jpeg';
import { Link } from 'react-router-dom';
import dict from '../../utils/dict';
import { getLatestPosts } from '../Blog/apiBlog';

const Content = ({
    lang,
    img,
    category,
    title,
}: {
    lang: 'en' | 'id';
    img: any;
    category: any;
    title: any;
}) => {
    return (
        <div style={{ position: 'relative' }}>
            <div className="d-none d-md-block">
                <img
                    alt="cover"
                    src={img}
                    style={{
                        width: '100%',
                        height: '25vw',
                        maxHeight: '200px',
                        objectFit: 'cover',
                        WebkitBoxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                        MozBoxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                        boxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                    }}
                />
                <div style={{ padding: '12px 16px' }}>
                    <p
                        style={{
                            ...styles.label,
                            fontSize: '0.85em',
                        }}
                    >
                        {category}
                    </p>
                    <p
                        style={{
                            ...styles.title,
                            fontSize: '1.4em',
                            lineHeight: '1.3em',
                        }}
                    >
                        {title}
                    </p>
                </div>
            </div>

            <div className="d-sm-block d-md-none">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '20px',
                    }}
                >
                    <div style={{ maxWidth: '280px' }}>
                        <img
                            alt="cover"
                            src={img}
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '250px',
                                objectFit: 'cover',
                                objectPosition: '0px 0%',
                                WebkitBoxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                                MozBoxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                                boxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                            }}
                        />

                        <p
                            style={{
                                ...styles.label,
                                fontSize: '13px',
                                textAlign: 'center',
                                color: '#fff',
                            }}
                        >
                            {category}
                        </p>

                        <p
                            style={{
                                ...styles.title,
                                fontSize: '20px',
                                lineHeight: '24px',
                                textAlign: 'center',
                                color: '#fff',
                            }}
                        >
                            {title}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    label: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        marginBottom: '8px',
        marginTop: '12px',
    },
    title: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginBottom: '20px',
    },
};

export default ({ lang }: { lang: 'en' | 'id' }) => {
    const [loading, setLoading] = useState(false);
    const [latestPosts, setLatestPosts] = useState<any[]>([]);

    useEffect(() => {
        setLoading(true);
        getLatestPosts(
            (data) => {
                setLoading(false);
                console.log(data);
                setLatestPosts(data.posts);
            },
            (e) => {
                console.log(e);
                setLoading(false);
            }
        );
    }, []);

    if (loading || latestPosts.length === 0) return null;

    return (
        <div style={{ backgroundColor: '#0e509f' }}>
            <div className="d-none d-md-block">
                <Container fluid style={{ marginTop: '-15vw' }}>
                    <Row>
                        <Col xl={1} lg={1} md={0} />
                        <Col xl={11} lg={11} md={12}>
                            <Col lg={3} md={4}>
                                <div style={{ height: '70px' }}>
                                    <p
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontSize: '1.4em',
                                            color: '#fff',
                                            fontWeight: 700,
                                        }}
                                    >
                                        {dict.footer_lattest_post[lang]}
                                    </p>
                                </div>
                            </Col>

                            <Row>
                                {latestPosts.map((d, i) => {
                                    return (
                                        <Col lg={3} md={4} key={i}>
                                            <Link
                                                to={`/blog/${d.id}`}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: '#fff',
                                                }}
                                            >
                                                <Content
                                                    lang={lang}
                                                    img={d.image_url}
                                                    category={d.category.name[lang]}
                                                    title={d.title[lang]}
                                                />
                                            </Link>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="d-sm-block d-md-none" style={{ position: 'relative' }}>
                <Container fluid style={{ marginTop: '-100px' }}>
                    <Row>
                        <Col sm={12}>
                            <Col lg={3} md={4}>
                                <div style={{ height: '50px' }}>
                                    <p
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontSize: '1.4em',
                                            color: '#fff',
                                            fontWeight: 700,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {dict.footer_lattest_post[lang]}
                                    </p>
                                </div>
                            </Col>

                            <Row>
                                {latestPosts.map((d, i) => {
                                    return (
                                        <Col lg={3} md={4} key={i}>
                                            <Link
                                                to={`/blog/${d.id}`}
                                                style={{ textDecoration: 'none', color: '#0e509f' }}
                                            >
                                                <Content
                                                    lang={lang}
                                                    img={d.image_url}
                                                    category={d.category.name[lang]}
                                                    title={d.title[lang]}
                                                />
                                            </Link>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};
