import React from 'react';
import HeroImage from './HeroImage';
import Description from './Description';
import ProductSlider from './ProductSlider';
import Footer from './Footer';
import LatestPost from './LatestPost';
import Navbar from '../Navbar';

export default ({ lang, setLang, isLoggedIn, settings }: any) => {
    return (
        <>
            <HeroImage lang={lang} />

            <Description lang={lang} />

            <ProductSlider lang={lang} settings={settings} />

            <LatestPost lang={lang} />

            <Footer lang={lang} settings={settings} />

            <Navbar {...{ lang, setLang, selected: 'home' }} />
        </>
    );
};
