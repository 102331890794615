import React, { useState } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './modules/Home/Home';
import ProductDetail from './modules/ProductDetail/ProductDetail';
import About from './modules/About/About';
import Contact from './modules/Contact/Contact';
import Blog from './modules/Blog/Blog';
import BlogDetail from './modules/Blog/BlogDetail';
import Login from './modules/Login/Login';
import ScrollToTop from './modules/util';
import { createBrowserHistory } from 'history';
import FAQ from './modules/FAQ/FAQ';
import PrivacyPolicy from './modules/PrivacyPolicy/PrivacyPolicy';
import Products from './modules/Products/Products';
import Store from './modules/Store/Store';
import LegalStatement from './modules/LegalStatement/LegalStatement';
import Prescription from './modules/Products/Prescription';
import Otc from './modules/Products/Otc';

const reversedMenu = ['/onoiwa', '/onoake', '/onogate', '/rafa-khomsah'];

const settings = {
    showTestimony: false,
    showFAQ: false,
    showLegal: false,
    showJournalResearch: false,
    showPrivacy: false,
    showDisclaimer: false,
    showPopularPost: false,
    showComment: false,
};

function App() {
    const customHistory = createBrowserHistory();

    const [lang, setLang] = useState<'en' | 'id'>('en');
    const [isLoggedIn, setIsLoggedIn] = useState('');

    const defaultProps = { lang, setLang, isLoggedIn, settings };

    return (
        <Router>
            <ScrollToTop />

            <Switch>
                <Route key="login" path="/login">
                    <Login {...defaultProps} />
                </Route>

                <Route key="about" path="/about">
                    <About {...defaultProps} />
                </Route>

                <Route key="contact" path="/contact">
                    <Contact {...defaultProps} />
                </Route>

                <Route key="detail" path="/blog/:id">
                    <BlogDetail {...defaultProps} history={customHistory} lang={lang} />
                </Route>

                <Route key="blog" path="/blog">
                    <Blog {...defaultProps} />
                </Route>

                <Route key="products" path="/products">
                    <Products {...defaultProps} />
                </Route>

                <Route key="prescription" path="/prescription">
                    <Prescription {...defaultProps} />
                </Route>

                <Route key="otc" path="/otc">
                    <Otc {...defaultProps} />
                </Route>

                <Route key="onoiwa" path="/onoiwa">
                    <ProductDetail type="onoiwa" {...defaultProps} />
                </Route>
                <Route key="onogate" path="/onogate">
                    <ProductDetail type="onogate" {...defaultProps} />
                </Route>
                <Route key="onoake" path="/onoake">
                    <ProductDetail type="onoake" {...defaultProps} />
                </Route>
                <Route key="rafa" path="/rafa-khomsah">
                    <ProductDetail type="rafa-khomsah" {...defaultProps} />
                </Route>

                <Route key="faq" path="/faq">
                    <FAQ {...defaultProps} />
                </Route>

                <Route key="privacy-policy" path="/privacy-policy">
                    <PrivacyPolicy {...defaultProps} />
                </Route>

                <Route key="store" path="/store">
                    <Store {...defaultProps} />
                </Route>

                <Route key="legal-statement" path="/legal-statement">
                    <LegalStatement {...defaultProps} />
                </Route>

                <Route key="home" path="/">
                    <Home {...defaultProps} />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
