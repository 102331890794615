import React, { useState, useCallback, CSSProperties } from 'react';
import { Container, Carousel, CarouselItem, CarouselIndicators, Row, Col } from 'reactstrap';
import Footer from '../Home/Footer';
import './ProductDetail.css';
import arrow from '../../img/icon-arrow-next.png';
import RafaKhomsah from './RafaKhomsah';
import Onogate from './Onogate';
import Onoake from './Onoake';
import Onoiwa from './Onoiwa';

export default ({
    type,
    lang,
    setLang,
    isLoggedIn,
    settings,
}: {
    type: string;
    lang: any;
    setLang: any;
    isLoggedIn: any;
    settings: any;
}) => {
    if (type === 'rafa-khomsah') {
        return <RafaKhomsah {...{ lang, setLang, isLoggedIn, settings }} />;
    } else if (type === 'onogate') {
        return <Onogate {...{ lang, setLang, isLoggedIn, settings }} />;
    } else if (type === 'onoake') {
        return <Onoake {...{ lang, setLang, isLoggedIn, settings }} />;
    }

    return <Onoiwa {...{ lang, setLang, isLoggedIn, settings }} />;
};
