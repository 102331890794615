import React from 'react';
import { Container } from 'reactstrap';
import Footer from '../Home/Footer';
import Navbar from '../Navbar';

export default ({ lang, setLang, isLoggedIn, settings }: any) => {
    return (
        <div>
            <div
                style={{
                    paddingTop: '120px',
                    paddingBottom: '30px',
                    backgroundColor: 'rgba(14, 80, 159, 1)',
                }}
            >
                <Container>
                    <p
                        style={{
                            fontSize: '28px',
                            color: '#fff',
                            fontFamily: 'Roboto',
                            fontWeight: 700,
                        }}
                    >
                        Legal Statement
                    </p>
                    <p style={{ fontSize: '13px', color: '#fff', fontFamily: 'Roboto' }}>
                        Akses dan penggunaan situs ini tunduk terhadap syarat dan ketentuan sebagai
                        berikut serta sesuai dengan hukum yang berlaku di Republik Indonesia.
                    </p>
                </Container>
            </div>

            <Container style={{ paddingTop: '40px' }}>
                <p style={styles.title}>Hak Cipta</p>
                <p style={styles.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                </p>

                <div style={{ height: '20px' }} />

                <p style={styles.title}>Merek-merek</p>
                <p style={styles.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate.
                </p>

                <div style={{ height: '20px' }} />

                <p style={styles.title}>Ketersediaan Produk</p>
                <p style={styles.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate.
                </p>

                <div style={{ height: '20px' }} />

                <p style={styles.title}>Surat Berharga</p>
                <p style={styles.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate.
                </p>

                <div style={{ height: '20px' }} />

                <p style={styles.title}>Isi</p>
                <p style={styles.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate.
                </p>

                <div style={{ height: '20px' }} />

                <p style={styles.title}>Pemberitahuan Terbatas</p>
                <p style={styles.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate.
                </p>

                <div style={{ height: '20px' }} />

                <p style={styles.title}>Domain</p>
                <p style={styles.text}>PT. Nucleus Farma Indonesia</p>

                <div style={{ height: '40px' }} />
            </Container>

            <Footer lang={lang} settings={settings} />

            <Navbar {...{ lang, setLang }} />
        </div>
    );
};

const styles = {
    title: {
        fontSize: '18px',
        color: 'rgba(14, 80, 159, 1)',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginBottom: '8px',
    },
    text: {
        fontSize: '13px',
        fontFamily: 'Roboto',
        marginBottom: '12px',
    },
};
