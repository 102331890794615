export default {
    menu_home: {
        en: 'HOME',
        id: 'HOME',
    },
    menu_products: {
        en: 'OUR PRODUCTS',
        id: 'PRODUK KAMI',
    },
    menu_about: {
        en: 'ABOUT US',
        id: 'TENTANG KAMI',
    },
    menu_contact: {
        en: 'CONTACT US',
        id: 'HUBUNGI KAMI',
    },
    menu_blog: {
        en: 'BLOG',
        id: 'BLOG',
    },
    menu_sign_in: {
        en: 'SIGN IN / SIGN UP',
        id: 'MASUK / DAFTAR',
    },
    menu_find_store: {
        en: 'FIND STORE',
        id: 'TEMUKAN TOKO',
    },
    //

    hero_title1: {
        en: 'We strive everyday to be better',
        id: 'Kami berusaha setiap hari untuk',
    },
    hero_title2: {
        en: 'through innovation',
        id: 'menjadi lebih baik melalui innovasi',
    },
    hero_tag: {
        en: 'For a better tomorrow',
        id: 'Untuk hari esok yang lebih baik',
    },
    hero_desc: {
        en: "We improve people's life by providing the best quality natural healthcare product",
        id:
            'Kami meningkatkan kehidupan masyarakat dengan menyediakan produk perawatan kesehatan alami kualitas terbaik',
    },
    //
    home_desc_slider0: {
        en:
            "At Nucleus Farma, our scientist, researcher, and employee always discover a better product and business process to improve people's health.",
        id:
            'Di Nucleus Farma, ilmuwan, peneliti dan karyawan kami selalu temukan produk dan proses bisnis yang lebih baik untuk meningkatkan kesehatan masyarakat.',
    },
    home_desc_slider1: {
        en:
            'At Nucleus Farma, we operate with the highest conduct of integrity and ethics, abinding by the most stringent code of health and business guidelines to ensure that we deliver the most cost effective products to our consumers, both in Indonesia and overseas.',
        id:
            'Di Nucleus Farma, kami beroperasi dengan integritas dan etika tertinggi, mematuhi aturan kesehatan dan pedoman bisnis yang paling ketat untuk memastikan bahwa kami memberikan produk yang paling hemat biaya bagi konsumen kami, baik di Indonesia maupun di luar negeri.',
    },
    //
    home_product_slider_title: {
        en: 'A gift from nature for our life',
        id: 'Hadiah dari alam untuk hidup kita',
    },
    home_product_slider_desc: {
        en:
            'As written in our Vision statement that we want to be the preeminent manufacturer of Indonesian natural medicine which applied good manufacturing standard and Halal Assurance System as the manifestation of corporate commitment towards better living in society.',
        id:
            'Seperti tertulis dalam pernyataan Visi kami bahwa kami ingin menjadi produsen obat alami Indonesia terkemuka yang menerapkan standar manufaktur yang baik dan Sistem Jaminan Halal sebagai perwujudan komitmen perusahaan untuk kehidupan yang lebih baik di masyarakat.',
    },
    home_product_slider_tag: {
        en:
            'We are the first indonesian company to get International FDA Certificate of Recognition.',
        id:
            'Kami adalah perusahaan Indonesia pertama yang mendapatkan Sertifikat Pengakuan FDA Internasional.',
    },
    //
    footer_lattest_post: {
        en: 'Latest Post',
        id: 'Posting terbaru',
    },
    footer_site_map: {
        en: 'Site Map',
        id: 'Peta Situs',
    },
    footer_home: {
        en: 'Home',
        id: 'Home',
    },
    footer_about: {
        en: 'About Us',
        id: 'Tentang Kami',
    },
    footer_contact: {
        en: 'Contact Us',
        id: 'Hubungi Kami',
    },
    footer_onoiwa: {
        en: 'Onoiwa',
        id: 'Onoiwa',
    },
    footer_onogate: {
        en: 'Onogate',
        id: 'Onogate',
    },
    footer_onoake: {
        en: 'Onoake',
        id: 'Onoake',
    },
    footer_rafa: {
        en: 'Rafa Khomsah',
        id: 'Rafa Khomsah',
    },
    footer_healthy_tips: {
        en: 'Healthy Tips',
        id: 'Tips Sehat',
    },
    footer_faq: {
        en: 'FAQ',
        id: 'FAQ',
    },
    footer_legal: {
        en: 'Legal Statement',
        id: 'Pernyataan Hukum',
    },
    footer_privacy: {
        en: 'Privacy Policy',
        id: 'Kebijakan Privasi',
    },
    footer_disclaimer: {
        en: 'Disclaimer',
        id: 'Disclaimer',
    },
    footer_subscribe: {
        en: 'Subscribe Email',
        id: 'Berlangganan Email',
    },
    footer_subscribe_desc: {
        en: 'Get latest tips, promo, and valuable information directly in your email',
        id: 'Dapatkan tips, promo, dan informasi berharga terbaru langsung di email Anda',
    },
    footer_follow: {
        en: 'Or follow us on social media',
        id: 'Atau ikuti kami di media sosial',
    },
    //
    products_title: {
        en: 'A gift from nature for our life',
        id: 'Hadiah dari alam untuk hidup kita',
    },
    products_desc: {
        en:
            'As written in our Vision statement that we want to be the preeminent manufacturer of Indonesian natural medicine which applied good manufacturing standard and Halal Assurance System as the manifestation of corporate commitment towards better living in society.',
        id:
            'Seperti tertulis dalam pernyataan Visi kami bahwa kami ingin menjadi produsen obat alami Indonesia terkemuka yang menerapkan standar manufaktur yang baik dan Sistem Jaminan Halal sebagai perwujudan komitmen perusahaan untuk kehidupan yang lebih baik di masyarakat.',
    },
    products_tag: {
        en:
            'We are the first indonesian company to get International FDA Certificate of Recognition.',
        id:
            'Kami adalah perusahaan Indonesia pertama yang mendapatkan Sertifikat Pengakuan FDA Internasional.',
    },
    //
    prescription_onoiwa: {
        en:
            'The combination of the three ingredients makes ONOIWA Capsule have a holistic benefit to help maintain good health.',
        id:
            'Kombinasi ketiga bahan tersebut menjadikan ONOIWA Capsule memiliki manfaat holistik untuk membantu menjaga kesehatan.',
    },
    prescription_onoiwa_plus: {
        en:
            'The combination of the three ingredients makes ONOIWA PLUS have a holistic benefit to help maintain good health.',
        id:
            'Kombinasi ketiga bahan tersebut membuat ONOIWA PLUS memiliki manfaat holistik untuk membantu menjaga kesehatan.',
    },
    prescription_onoiwa_mx: {
        en:
            'The combination of the three ingredients makes ONOIWA MIX have a holistic benefit to help maintain good health.',
        id:
            'Kombinasi ketiga bahan tersebut menjadikan ONOIWA MIX memiliki manfaat holistik untuk membantu menjaga kesehatan.',
    },
    prescription_onogate: {
        en:
            'The combination of the three ingredients makes ONOGATE have a holistic benefit to help maintain good health.',
        id:
            'Kombinasi ketiga bahan tersebut membuat ONOGATE memiliki manfaat holistik untuk membantu menjaga kesehatan.',
    },
    //
    otc_onoake: {
        en:
            'The combination of the three ingredients makes ONOAKE have a holistic benefit to help maintain good health.',
        id:
            'Kombinasi ketiga bahan tersebut membuat ONOAKE memiliki manfaat holistik untuk membantu menjaga kesehatan.',
    },
    otc_rafa: {
        en:
            'The combination of the three ingredients makes RAFA KHOMSAH have a holistic benefit to help maintain good health.',
        id:
            'Kombinasi ketiga bahan tersebut menjadikan RAFA KHOMSAH memiliki manfaat holistik untuk membantu menjaga kesehatan.',
    },
    //
    about_1: {
        en: 'VISION STATEMENT',
        id: 'VISI',
    },
    about_1_desc: {
        en:
            'To be the preeminent manufacturer of Indonesian natural medicine which applied good manufacturing standard and Halal Assurance System as the manifestation of corporate commitment towards better living in society.',
        id:
            'Menjadi produsen obat alami Indonesia terkemuka yang menerapkan standar manufaktur yang baik dan Sistem Jaminan Halal sebagai perwujudan komitmen perusahaan terhadap kehidupan yang lebih baik di masyarakat.',
    },
    about_2: {
        en: 'MISSION STATEMENT',
        id: 'MISI',
    },
    about_2_desc: {
        en: 'Improve people’s life by providing the best quality natural healthcare product.',
        id:
            'Tingkatkan kehidupan masyarakat dengan menyediakan produk perawatan kesehatan alami kualitas terbaik.',
    },
    about_3: {
        en: 'CORPORATE VALUE',
        id: 'NILAI PERUSAHAAN',
    },
    about_3_1: {
        en: 'INNOVATION',
        id: 'INOVASI',
    },
    about_3_1_desc: {
        en:
            'At Nucleus Farma, our scientist, researcher, and employee always discover a better product and business process to improve people’s health.',
        id:
            'Di Nucleus Farma, ilmuwan, peneliti, dan karyawan kami selalu menemukan produk dan proses bisnis yang lebih baik untuk meningkatkan kesehatan masyarakat.',
    },
    about_3_2: {
        en: 'INTEGRITY',
        id: 'INTEGRITAS',
    },
    about_3_2_desc: {
        en:
            'At Nucleus Farma, we implement the highest conduct of integrity, business ethics, and code of healthcare business-standard.',
        id:
            'Di Nucleus Farma, kami menerapkan perilaku integritas, etika bisnis, dan kode standar bisnis perawatan kesehatan tertinggi.',
    },
    about_3_3: {
        en: 'COMMITMENT TO SOCIETY',
        id: 'KOMITMEN TERHADAP MASYARAKAT',
    },
    about_3_3_desc: {
        en:
            'Our ultimate goal is to support a better living standard in society through our product and innovation.',
        id:
            'Tujuan utama kami adalah untuk mendukung standar kehidupan yang lebih baik di masyarakat melalui produk dan inovasi kami.',
    },
    //
    contact_title: {
        en: 'Contact Us',
        id: 'Hubungi Kami',
    },
    contact_desc: {
        en:
            'Send your enquiries and question to Us by simply fill out this form and we will respond to you in shortly',
        id:
            'Kirim keperluan dan pertanyaan Anda kepada Kami hanya dengan mengisi formulir ini dan kami akan segera menanggapi Anda',
    },
    contact_lets_keep: {
        en: "Let's Keep In Touch!",
        id: 'Mari Tetap Terhubung!',
    },
    contact_msg_email: {
        en: 'Email',
        id: 'Email',
    },
    contact_msg_email_placeholder: {
        en: 'Enter your email address',
        id: 'Masukkan alamat email Anda',
    },
    contact_msg_name: {
        en: 'Full Name',
        id: 'Nama Lengkap',
    },
    contact_msg_name_placeholder: {
        en: 'Enter your name',
        id: 'Masukkan nama Anda',
    },
    contact_msg_subject: {
        en: 'Subject',
        id: 'Subjek',
    },
    contact_msg_subject_placeholder: {
        en: 'Enter your subject email',
        id: 'Masukkan email subjek Anda',
    },
    contact_msg_phone: {
        en: 'Phone',
        id: 'Telepon',
    },
    contact_msg_phone_placeholder: {
        en: 'Enter your phone number',
        id: 'Masukkan nomor telepon Anda',
    },
    contact_msg_country: {
        en: 'Country',
        id: 'Negara',
    },
    contact_msg_country_placeholder: {
        en: 'Enter your country',
        id: 'Masukkan negara Anda',
    },
    contact_msg_message: {
        en: 'Message',
        id: 'Pesan',
    },
    contact_msg_message_placeholder: {
        en: 'Enter your message here',
        id: 'Masukkan pesan Anda di sini',
    },
    //
    blog_title: {
        en: 'Get the latest news and healthy tips with Nucleus Farma',
        id: 'Dapatkan berita terbaru dan tips sehat bersama Nucleus Farma',
    },
    healthy_tips: {
        en: 'Healthy Tips',
        id: 'Tips Sehat',
    },
    //
    blog_back: {
        en: 'Back to Timeline',
        id: 'Kembali ke Timeline',
    },
    //
    store_title: {
        en: 'Find our products nearby',
        id: 'Temukan produk kami di area terdekat',
    },
    store_category: {
        en: 'Category',
        id: 'Kategori',
    },
    store_location: {
        en: 'Location',
        id: 'Lokasi',
    },
    store_search: {
        en: 'Search',
        id: 'Cari',
    },
    //
    product_detail_onoiwa: {
        en:
            "Onoiwa maintain and improve the body's immune system, increase level of albumin in the blood, and accelerate wound healing.",
        id:
            'Onoiwa memelihara dan meningkatkan sistem kekebalan tubuh, meningkatkan kadar albumin dalam darah, dan mempercepat penyembuhan luka.',
    },
    product_detail_onogate: {
        en:
            'ONOGATE is a natural product in capsule form, which contains extracts of Stichopus variegatus (golden sea cucumbers).',
        id:
            'ONOGATE adalah produk alami dalam bentuk kapsul, yang mengandung ekstrak Stichopus variegatus (teripang emas).',
    },
    product_detail_onoake: {
        en:
            'Onoake is a natural product in the capsule form, which contains extracts of Moringa oleifera folium (moringa leaf).',
        id:
            'Onoake is a natural product in the capsule form, which contains extracts of Moringa oleifera folium (moringa leaf).',
    },
    product_detail_rafa: {
        en:
            'Rafa Khomsah is a natural product in the form of capsules containing 5 ingredients of Nigella sativa extract, Psidium guajava extract, Curcuma xanthorrhiza extract, Oryza sativa extract, Centella asiatica extract.',
        id:
            'Rafa Khomsah adalah produk alami dalam bentuk kapsul yang mengandung 5 bahan ekstrak Nigella sativa, ekstrak Psidium guajava, ekstrak Curcuma xanthorrhiza, ekstrak Oryza sativa, ekstrak Centella asiatica.',
    },
    //
    product_detail_onoiwa_desc: {
        en:
            'ONOIWA is a natural product available in capsule form. ONOIWA contains Chana striata extract which is useful for the body especially in maintaining a healthy body.',
        id:
            'ONOIWA adalah produk alami yang tersedia dalam bentuk kapsul. ONOIWA mengandung ekstrak Chana striata yang berguna bagi tubuh terutama dalam menjaga kesehatan tubuh.',
    },
    product_detail_onoiwa_tag: {
        en:
            'The combination of the three ingredients makes ONOIWA have a holistic benefit to help maintain good health',
        id:
            'Kombinasi ketiga bahan tersebut membuat ONOIWA memiliki manfaat holistik untuk membantu menjaga kesehatan',
    },
    //
    product_detail_onoiwa_plus_desc: {
        en:
            'ONOIWA PLUS is a natural product on the form of powder with an orange flavor that is easily dissolved in cold water. ONOIWA PLUS contains Chana striata extract, Moringa oleifera extract, and Curcuma xanthorrhiza extract.',
        id:
            'ONOIWA PLUS adalah produk alami dalam bentuk bubuk dengan rasa jeruk yang mudah larut dalam air dingin. ONOIWA PLUS mengandung ekstrak Chana striata, ekstrak Moringa oleifera, dan ekstrak Curcuma xanthorrhiza.',
    },
    product_detail_onoiwa_plus_tag: {
        en:
            'The combination of the three ingredients provides holistic benefits to help maintain a healthy body.',
        id:
            'Kombinasi ketiga bahan ini memberikan manfaat holistik untuk membantu menjaga kesehatan tubuh.',
    },
    //
    product_detail_onoiwa_mx_desc: {
        en:
            'ONOIWA MX is prepared in oral liquid dosage from with blueberry flavor that can be taken directly. ONOIWA MX contains Chana striata extract, Moringa oleifera extract, and Curcuma xanthorrhiza extract.',
        id:
            'ONOIWA MX dibuat dalam dosis cairan oral dengan rasa blueberry yang dapat dikonsumsi secara langsung. ONOIWA MX mengandung ekstrak Chana striata, ekstrak Moringa oleifera, dan ekstrak Curcuma xanthorrhiza.',
    },
    product_detail_onoiwa_mx_tag: {
        en:
            'The combination of the three ingredients makes ONOIWA MX have a holistic benefit to help maintain good health',
        id:
            'Kombinasi ketiga bahan tersebut membuat ONOIWA MX memiliki manfaat holistik untuk membantu menjaga kesehatan',
    },
    //
    product_detail_onogate_desc: {
        en:
            'ONOGATE is a natural product in capsule form, which contains extracts of Stichopus variegatus (golden sea cucumbers).',
        id:
            'ONOGATE adalah produk alami dalam bentuk kapsul, yang mengandung ekstrak Stichopus variegatus (teripang emas).',
    },
    product_detail_onogate_tag: {
        en:
            'ONOGATE is a natural product in capsule form, which contains extracts of Stichopus variegatus (golden sea cucumbers).This golden sea cucumber extract is believed to be efficacious to help relieve joint pain and maintain a healthy body.',
        id:
            'ONOGATE adalah produk alami dalam bentuk kapsul, yang mengandung ekstrak Stichopus variegatus (teripang emas) .Ekstrak teripang emas ini dipercaya berkhasiat membantu meredakan nyeri sendi dan menjaga kesehatan tubuh.',
    },
    //
    product_detail_onoake_desc: {
        en:
            'ONOAKE contains Moringa oleifera extract, Phyllanthus niruri extract, Nigella sativa extract which can help maintain a healthy body.',
        id:
            'ONOAKE mengandung ekstrak Moringa oleifera, ekstrak Phyllanthus niruri, ekstrak Nigella sativa yang dapat membantu menjaga kesehatan tubuh.',
    },
    product_detail_onoake_tag: {
        en:
            'And Moringa oleifera which is one of the main ingredients in ONOAKE has been widely known in the world as a magical tree because of its high nutritional content.',
        id:
            'Dan Moringa oleifera yang merupakan salah satu bahan utama dalam ONOAKE telah dikenal luas di dunia sebagai pohon ajaib karena kandungan nutrisinya yang tinggi.',
    },
    //
    product_detail_rafa_desc: {
        en:
            'Rafa Khomsah is a natural product in the form of capsules containing 5 ingredients of Nigella sativa extract, Psidium guajava extract, Curcuma xanthorrhiza extract, Oryza sativa extract, Centella asiatica extract.',
        id:
            'Rafa Khomsah adalah produk alami dalam bentuk kapsul yang mengandung 5 bahan ekstrak Nigella sativa, ekstrak Psidium guajava, ekstrak Curcuma xanthorrhiza, ekstrak Oryza sativa, ekstrak Centella asiatica.',
    },
    product_detail_rafa_tag: {
        en: 'The combination of these extracts is useful to help maintain a healthy body.',
        id: 'Kombinasi ekstrak ini bermanfaat untuk membantu menjaga kesehatan tubuh.',
    },
    //
    btn_learn_more: {
        en: 'Learn more',
        id: 'Pelajari lebih',
    },
    btn_view_product: {
        en: 'View products',
        id: 'Lihat produk',
    },
    input_your_email: {
        en: 'Your Email',
        id: 'Email Anda',
    },
    btn_product_detail: {
        en: 'Product Details',
        id: 'Detail Produk',
    },
    btn_send_message: {
        en: 'Send Message',
        id: 'Kirim Pesan',
    },
    find_us_socmed: {
        en: 'Find us on Social Media',
        id: 'Temui kami di Media Sosial',
    },
    search_articles: {
        en: 'Search Articles',
        id: 'Cari Artikel',
    },
    comment: {
        en: 'Comment',
        id: 'Komentar',
    },
    other_product: {
        en: 'Other Products',
        id: 'Produk Lainnya',
    },
};
