import React, { useState, useCallback, CSSProperties } from 'react';
import Footer from '../Home/Footer';
import { Container, Row, Col } from 'reactstrap';
import logo_white from '../../img/logo-onoiwa-white.png';
import bpom from '../../img/logo-bpom.png';
import mui from '../../img/logo-mui.png';
import fda from '../../img/logo-fda.png';
import badge from '../../img/icon-badge.png';
import productcover from '../../img/onoiwa-product-cover.png';
import ProductSpec from './ProductSpec';
import logo_ori1 from '../../img/logo-onoiwa-ori.png';
import logo_ori2 from '../../img/logo-onoiwa-plus-ori.png';
import logo_ori3 from '../../img/logo-onoiwa-mx-ori.png';
import productimg1 from '../../img/product-onoiwa-detail.png';
import productimg2 from '../../img/product-onoiwa-plus-detail.png';
import productimg3 from '../../img/product-onoiwa-mx-detail.png';
import MoreProducts from './MoreProducts';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import onoiwa_extra1 from '../../img/onoiwa-extra-1.png';
import onoiwa_extra2 from '../../img/onoiwa-extra-2.png';
import onoiwa_extra3 from '../../img/onoiwa-extra-3.png';
import R1 from '../../img/onoiwa-nav-r1.png';
import L2 from '../../img/onoiwa-nav-l2.png';
import R2 from '../../img/onoiwa-nav-r2.png';
import L3 from '../../img/onoiwa-nav-l3.png';
import dict from '../../utils/dict';

export default ({
    lang,
    setLang,
    isLoggedIn,
    settings,
}: {
    lang: 'en' | 'id';
    setLang: any;
    isLoggedIn: any;
    settings: any;
}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const goToIndex = useCallback(
        (newIndex: number) => {
            setActiveIndex(newIndex);
        },
        [activeIndex]
    );

    return (
        <>
            <div className="d-none d-md-block" style={{ height: '95px' }} />
            <div className="d-block d-md-none" style={{ height: '56px' }} />

            <div
                style={{
                    background:
                        'linear-gradient(180deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1)',
                    width: '100%',
                    paddingTop: '95px',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                <Container>
                    <Row>
                        <Col lg={5} md={12} style={{ position: 'relative' }}>
                            <img
                                className="d-none d-md-block"
                                src={logo_white}
                                style={{
                                    width: 'auto',
                                    maxWidth: '400px',
                                    height: '10vw',
                                    maxHeight: '70px',
                                    minHeight: '60px',
                                    objectFit: 'contain',
                                    marginBottom: '30px',
                                }}
                            />
                            <img
                                className="d-sm-block d-md-none"
                                src={logo_white}
                                style={{
                                    width: 'auto',
                                    maxWidth: '240px',
                                    height: '10vw',
                                    maxHeight: '70px',
                                    minHeight: '60px',
                                    objectFit: 'contain',
                                    marginBottom: '30px',
                                }}
                            />

                            <div style={{ maxWidth: '360px' }}>
                                <p
                                    style={{
                                        fontFamily: 'Roboto',
                                        color: '#fff',
                                        fontSize: '18px',
                                        marginBottom: '20px',
                                    }}
                                >
                                    {dict.product_detail_onoiwa[lang]}
                                </p>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: '40px',
                                }}
                            >
                                <img
                                    src={badge}
                                    style={{
                                        width: '45px',
                                        height: '45px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                    alt="certified"
                                />
                                <img
                                    src={mui}
                                    alt="mui"
                                    style={{
                                        width: '47px',
                                        height: '47px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                />
                                <img
                                    src={fda}
                                    alt="fda"
                                    style={{
                                        width: '55px',
                                        height: '45px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                />
                                <img
                                    src={bpom}
                                    alt="bpom"
                                    style={{ width: '44px', height: '44px', objectFit: 'contain' }}
                                />
                            </div>
                        </Col>

                        <Col
                            lg={7}
                            md={12}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={productcover}
                                style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                            />
                        </Col>
                    </Row>

                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'row',
                            top: '20px',
                        }}
                    >
                        <Link to="/" style={{ color: '#fff', ...styles.breadcrumbs }}>
                            {dict.menu_home[lang]}
                        </Link>
                        <span
                            style={{
                                fontFamily: 'Roboto Condensed',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: '#fff',
                                padding: '8px',
                            }}
                        >
                            /
                        </span>
                        <Link to="/products" style={{ color: '#fff', ...styles.breadcrumbs }}>
                            {dict.menu_products[lang]}
                        </Link>
                        <span
                            style={{
                                fontFamily: 'Roboto Condensed',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: '#fff',
                                padding: '8px',
                            }}
                        >
                            /
                        </span>
                        <Link to="/onoiwa" style={{ color: '#17cde4', ...styles.breadcrumbs }}>
                            {`ONOIWA ${
                                activeIndex === 0 ? 'CAPSULE' : activeIndex === 1 ? 'PLUS' : 'MX'
                            }`}
                        </Link>
                    </div>
                </Container>

                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <div
                        style={{
                            height: '50px',
                            width: '100%',
                            maxWidth: '850px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <a
                            className="d-none d-md-block"
                            onClick={() => goToIndex(0)}
                            style={{
                                fontSize: '18px',
                                backgroundColor: '#48e4fd',
                                paddingTop: '16px',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA CAPSULE
                        </a>
                        <a
                            className="d-none d-sm-block d-md-none"
                            onClick={() => goToIndex(0)}
                            style={{
                                fontSize: '15px',
                                backgroundColor: '#48e4fd',
                                paddingTop: '16px',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA CAPSULE
                        </a>
                        <a
                            className="d-xs-block d-sm-none"
                            onClick={() => goToIndex(0)}
                            style={{
                                fontSize: '15px',
                                backgroundColor: '#48e4fd',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA CAPSULE
                        </a>

                        <a
                            className="d-none d-md-block"
                            onClick={() => goToIndex(1)}
                            style={{
                                fontSize: '18px',
                                paddingTop: '16px',
                                backgroundColor: '#273b91',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA PLUS
                        </a>
                        <a
                            className="d-none d-sm-block d-md-none"
                            onClick={() => goToIndex(1)}
                            style={{
                                fontSize: '15px',
                                paddingTop: '16px',
                                backgroundColor: '#273b91',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA PLUS
                        </a>
                        <a
                            className="d-xs-block d-sm-none"
                            onClick={() => goToIndex(1)}
                            style={{
                                fontSize: '15px',
                                backgroundColor: '#273b91',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA PLUS
                        </a>

                        <a
                            className="d-none d-md-block"
                            onClick={() => goToIndex(2)}
                            style={{
                                fontSize: '18px',
                                paddingTop: '16px',
                                backgroundColor: '#7e2778',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA MX
                        </a>
                        <a
                            className="d-none d-sm-block d-md-none"
                            onClick={() => goToIndex(2)}
                            style={{
                                fontSize: '15px',
                                paddingTop: '16px',
                                backgroundColor: '#7e2778',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA MX
                        </a>
                        <a
                            className="d-xs-block d-sm-none"
                            onClick={() => goToIndex(2)}
                            style={{
                                fontSize: '15px',
                                backgroundColor: '#7e2778',
                                ...styles.product_type_container,
                            }}
                        >
                            ONOIWA MX
                        </a>
                    </div>
                </div>
            </div>

            <div style={{ position: 'relative' }}>
                {activeIndex === 0 && (
                    <ProductSpec
                        logo={logo_ori1}
                        extra1={onoiwa_extra1}
                        logostyle={{
                            width: '60%',
                            height: 'auto',
                            objectFit: 'contain',
                            marginTop: '30px',
                            marginBottom: '30px',
                        }}
                        generalSpec={dict.product_detail_onoiwa_desc[lang]}
                        specialColor="#32b8d1"
                        specialSpec={dict.product_detail_onoiwa_tag[lang]}
                        specDetails={[
                            {
                                title: lang === 'en' ? 'INGREDIENTS' : 'KOMPOSISI',
                                text:
                                    lang === 'en'
                                        ? [
                                              'Each capsule contains 500 mg of Channa Striata extract.',
                                          ]
                                        : ['Tiap kapsul mengandung Channa striata extract 500 mg'],
                            },
                            {
                                title: lang === 'en' ? 'INDICATIONS' : 'INDIKASI',
                                text:
                                    lang === 'en'
                                        ? ['Helps maintain health']
                                        : ['Membantu memelihara kesehatan'],
                            },
                            {
                                title: lang === 'en' ? 'DOSAGE' : 'DOSIS',
                                text:
                                    lang === 'en'
                                        ? ['3 times a day 1-2 capsules, taken before meals']
                                        : ['3x sehari 1-2 kapsul, diminum sebelum makan'],
                            },
                            {
                                title: lang === 'en' ? 'STORAGE' : 'PENYIMPANAN',
                                text:
                                    lang === 'en'
                                        ? [
                                              'Store below 30°C in a dry and tightly closed container, keep it away from direct sunlight.',
                                          ]
                                        : [
                                              'Simpan dibawah suhu 30◦C dalam wadah kering dan tertutup rapat hindarkan dari sinar matahari',
                                          ],
                            },
                        ]}
                        productimg={productimg1}
                    />
                )}

                {activeIndex === 1 && (
                    <ProductSpec
                        logo={logo_ori2}
                        extra1={onoiwa_extra2}
                        logostyle={{
                            width: '78%',
                            height: 'auto',
                            objectFit: 'contain',
                            marginTop: '30px',
                            marginBottom: '30px',
                        }}
                        generalSpec={dict.product_detail_onoiwa_plus_desc[lang]}
                        specialColor="#24398c"
                        specialSpec={dict.product_detail_onoiwa_plus_tag[lang]}
                        specDetails={[
                            {
                                title: lang === 'en' ? 'INGREDIENTS' : 'KOMPOSISI',
                                text:
                                    lang === 'en'
                                        ? [
                                              'Each 10 g contains:',
                                              '5000 mg of Channa striata extract',
                                              '250 mg of Curcuma xanthorrhiza rhizome extract',
                                              '250 mg of Moringa loafer folium extract, and',
                                              'Orange Flavoring',
                                          ]
                                        : [
                                              'Tiap 10 g mengandung:',
                                              'Ekstrak Channa striata 5000 mg',
                                              'Ekstrak Curcuma xanthorrhiza rhizome 250 mg',
                                              'Ekstrak Moringa oleifera folium 250 mg',
                                              'Orange flavour',
                                          ],
                            },
                            {
                                title: lang === 'en' ? 'INDICATIONS' : 'INDIKASI',
                                text:
                                    lang === 'en'
                                        ? ['Helps maintain health']
                                        : ['Membantu memelihara kesehatan'],
                            },
                            {
                                title: lang === 'en' ? 'DOSAGE' : 'DOSIS',
                                text:
                                    lang === 'en'
                                        ? ['3 times a day 1-2 sachet, taken before meals']
                                        : ['3x sehari 1-2 sachet, diminum sebelum makan'],
                            },
                            {
                                title: lang === 'en' ? 'HOW TO USE' : 'CARA PEMAKAIAN',
                                text:
                                    lang === 'en'
                                        ? [
                                              'Add 50 mL of water, stir until homogeneous, drink immediately until finished.',
                                          ]
                                        : [
                                              'Tambahkan 50 mL air, aduk hingga homogen, langsung diminum sampai habis',
                                          ],
                            },
                            {
                                title: lang === 'en' ? 'CAUTIONS' : 'PERINGATAN',
                                text:
                                    lang === 'en'
                                        ? ['Do not brew with hot water.']
                                        : ['Jangan diseduh dengan air panas'],
                            },
                            {
                                title: lang === 'en' ? 'STORAGE' : 'PENYIMPANAN',
                                text:
                                    lang === 'en'
                                        ? [
                                              'Store below 30°C in a dry and tightly closed container, keep it away from direct sunlight.',
                                          ]
                                        : [
                                              'Simpan dibawah suhu 30◦C dalam wadah kering dan tertutup rapat, hindarkan dari sinar matahari langsung',
                                          ],
                            },
                        ]}
                        productimg={productimg2}
                    />
                )}

                {activeIndex === 2 && (
                    <ProductSpec
                        logo={logo_ori3}
                        extra1={onoiwa_extra3}
                        logostyle={{
                            width: '74%',
                            height: 'auto',
                            objectFit: 'contain',
                            marginTop: '30px',
                            marginBottom: '30px',
                        }}
                        generalSpec={dict.product_detail_onoiwa_mx_desc[lang]}
                        specialColor="#7f3b76"
                        specialSpec={dict.product_detail_onoiwa_mx_tag[lang]}
                        specDetails={[
                            {
                                title: lang === 'en' ? 'INGREDIENTS' : 'KOMPOSISI',
                                text:
                                    lang === 'en'
                                        ? [
                                              'Each 15 ml contains:',
                                              '5100 mg of Channa Striata extract',
                                              '240 mg of Curcuma xanthorrhiza rhizome extract',
                                              '240 mg of Moringa oleifera folium extract',
                                              '15 mg of Potassium Sorbate, and',
                                              'Blueberry Flavoring',
                                          ]
                                        : [
                                              'Tiap 15 ml mengandung:',
                                              'Ekstrak Channa striata 5100 mg',
                                              'Ekstrak Curcuma xanthorrhiza rhizome 240 mg',
                                              'Ekstrak Moringa oleifera folium 240 mg',
                                              'Potassium Sorbate 15 mg',
                                              'Blueberry Flavor',
                                          ],
                            },
                            {
                                title: lang === 'en' ? 'INDICATIONS' : 'INDIKASI',
                                text:
                                    lang === 'en'
                                        ? ['Helps maintain health']
                                        : ['Membantu memelihara kesehatan'],
                            },
                            {
                                title: lang === 'en' ? 'DOSAGE' : 'DOSIS',
                                text:
                                    lang === 'en'
                                        ? ['3 times a day 1-2 sachets, taken before meals']
                                        : ['3x sehari 1-2 sachet, diminum sebelum makan'],
                            },
                            {
                                title: lang === 'en' ? 'HOW TO USE' : 'CARA PEMAKAIAN',
                                text:
                                    lang === 'en'
                                        ? ['Drink it immediately.']
                                        : ['Langsung di minum sampai habis'],
                            },
                            {
                                title: lang === 'en' ? 'CAUTIONS' : 'PERINGATAN',
                                text:
                                    lang === 'en'
                                        ? ['Shake well before drink.']
                                        : ['Kocok dahulu sebelum diminum'],
                            },
                            {
                                title: lang === 'en' ? 'STORAGE' : 'PENYIMPANAN',
                                text:
                                    lang === 'en'
                                        ? [
                                              'Store below 30°C in a dry and tightly closed container, keep it away from direct sunlight.',
                                          ]
                                        : [
                                              'Simpan dibawah suhu 30◦C dalam wadah kering dan tertutup rapat, hindarkan sinar matahari langsung',
                                          ],
                            },
                        ]}
                        productimg={productimg3}
                    />
                )}

                {activeIndex !== 0 && (
                    <>
                        <div className="d-none d-md-block">
                            <div
                                onClick={() => setActiveIndex(activeIndex - 1)}
                                style={{
                                    position: 'absolute',
                                    width: '50px',
                                    height: '100px',
                                    left: '20px',
                                    top: '250px',
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    src={activeIndex === 1 ? L2 : L3}
                                    style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                />
                            </div>
                        </div>
                        <div className="d-sm-block d-md-none">
                            <div
                                onClick={() => setActiveIndex(activeIndex - 1)}
                                style={{
                                    position: 'absolute',
                                    width: '50px',
                                    height: '100px',
                                    left: '20px',
                                    top: '370px',
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    src={activeIndex === 1 ? L2 : L3}
                                    style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                />
                            </div>
                        </div>
                    </>
                )}

                {activeIndex !== 2 && (
                    <>
                        <div className="d-none d-md-block">
                            <div
                                onClick={() => setActiveIndex(activeIndex + 1)}
                                style={{
                                    position: 'absolute',
                                    width: '50px',
                                    height: '100px',
                                    top: '250px',
                                    right: '20px',
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    src={activeIndex === 0 ? R1 : R2}
                                    style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                />
                            </div>
                        </div>
                        <div className="d-sm-block d-md-none">
                            <div
                                onClick={() => setActiveIndex(activeIndex + 1)}
                                style={{
                                    position: 'absolute',
                                    width: '50px',
                                    height: '100px',
                                    top: '370px',
                                    right: '20px',
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    src={activeIndex === 0 ? R1 : R2}
                                    style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>

            <MoreProducts exclude="onoiwa" lang={lang} />

            <Footer
                bgColor={activeIndex === 0 ? '#32b8d1' : activeIndex === 1 ? '#24398c' : '#7f3b76'}
                lang={lang}
                settings={settings}
            />

            <Navbar
                lang={lang}
                setLang={setLang}
                upperColor={'#fff'}
                footerColor={'rgba(14, 80, 159, 1)'}
                mode="reversed"
                productType="onoiwa"
                selected="products"
            />
        </>
    );
};

const styles: { [x: string]: CSSProperties } = {
    breadcrumbs: {
        fontFamily: 'Roboto Condensed',
        fontSize: '14px',
        fontWeight: 700,
        padding: '8px 0px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    product_type_container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Roboto Condensed',
        fontWeight: 700,
        color: '#fff',
        height: '50px',
        cursor: 'pointer',
        textAlign: 'center',
        lineHeight: '20px',
    },
};
