import React, { useCallback, useEffect, useState } from 'react';
import { Container, Input, Row, Col } from 'reactstrap';
import search from '../../img/icon-search.png';
import Footer from '../Home/Footer';
import Navbar from '../Navbar';
import { getFaqTopics } from './apiFaq';

export default ({ lang, setLang, isLoggedIn, settings }: any) => {
    const [topics, setTopics] = useState([]);
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleSearch = useCallback(() => {}, []);

    useEffect(() => {
        getFaqTopics(
            (data) => {
                setTopics(data.topics);
            },
            (e) => {}
        );
    });

    return (
        <div>
            <div className="d-none d-md-block" style={{ height: '95px' }} />
            <div className="d-block d-md-none" style={{ height: '56px' }} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#17cde4',
                    padding: '50px 20px',
                    alignItems: 'center',
                }}
            >
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontWeight: 700,
                        fontSize: '36px',
                        color: '#fff',
                    }}
                >
                    How Can We Help You?
                </p>
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        color: '#fff',
                    }}
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </p>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '45px',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        overflow: 'hidden',
                    }}
                >
                    <Input
                        placeholder="Type your search"
                        style={{
                            fontFamily: 'Roboto',
                            fontSize: '13px',
                            display: 'flex',
                            flex: 1,
                            height: '45px',
                            border: 'none',
                            minWidth: '300px',
                        }}
                    />
                    <a
                        onClick={handleSearch}
                        style={{
                            backgroundColor: '#e9bc1d',
                            width: '45px',
                            height: '45px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={search}
                            style={{ width: '16px', height: '16px', objectFit: 'contain' }}
                        />
                    </a>
                </div>
            </div>

            <Container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
                <Row>
                    <Col
                        sm={4}
                        style={{ display: 'flex', flexDirection: 'column', marginBottom: '60px' }}
                    >
                        <p
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '18px',
                                fontWeight: 700,
                                marginBottom: '30px',
                            }}
                        >
                            Category
                        </p>

                        <a
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: 'rgba(14, 80, 159, 1)',
                                marginBottom: '12px',
                                cursor: 'pointer',
                            }}
                        >
                            Category 1
                        </a>
                        <a
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: 'rgba(14, 80, 159, 1)',
                                marginBottom: '12px',
                                cursor: 'pointer',
                            }}
                        >
                            Category 2
                        </a>
                        <a
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: 'rgba(14, 80, 159, 1)',
                                marginBottom: '12px',
                                cursor: 'pointer',
                            }}
                        >
                            Category 3
                        </a>
                    </Col>

                    <Col sm={8} style={{ display: 'flex', flexDirection: 'column' }}>
                        <p
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '18px',
                                fontWeight: 700,
                                marginBottom: '30px',
                            }}
                        >
                            Questions Asked
                        </p>
                        <a
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontWeight: 700,
                                paddingBottom: '20px',
                                borderBottom: '1px solid #ddd',
                                marginBottom: '20px',
                            }}
                        >
                            1. Lorem Ipsum
                        </a>
                        <a
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontWeight: 700,
                                paddingBottom: '20px',
                                borderBottom: '1px solid #ddd',
                                marginBottom: '20px',
                            }}
                        >
                            2. Lorem Ipsum
                        </a>
                        <a
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                fontWeight: 700,
                                paddingBottom: '20px',
                                borderBottom: '1px solid #ddd',
                                marginBottom: '20px',
                            }}
                        >
                            3. Lorem Ipsum
                        </a>
                    </Col>
                </Row>
            </Container>

            <Footer lang={lang} settings={settings} />

            <Navbar {...{ lang, setLang }} />
        </div>
    );
};
