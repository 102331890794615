import React, { CSSProperties } from 'react';
import bpom from '../../img/logo-bpom.png';
import mui from '../../img/logo-mui.png';
import fda from '../../img/logo-fda.png';
import mountain from '../../img/mountain-bg.png';
import { Link } from 'react-router-dom';
import dict from '../../utils/dict';
import arrow from '../../img/icon-arrow.png';
import badge from '../../img/icon-badge.png';

interface IProductSliderMobileProps {
    products: {
        image: string;
        link: string;
    }[];
    index: number;
    lang: 'en' | 'id';
    setIndex(index: number): void;
}

export default ({ products, index, setIndex, lang }: IProductSliderMobileProps) => {
    return (
        <div
            className="d-sm-block d-md-none"
            style={{
                marginTop: '-100px',
                position: 'relative',
                height: '210vw',
                maxHeight: '900px',
            }}
        >
            <div style={{ position: 'absolute', bottom: 0 }}>
                <img
                    src={mountain}
                    alt="mountain"
                    style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                        backgroundColor: '#fff',
                    }}
                />
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingRight: '5vw',
                    }}
                >
                    <a
                        onClick={() => setIndex(0)}
                        style={
                            index === 0 ? styles.mobile_indicator_active : styles.mobile_indicator
                        }
                    />
                    <a
                        onClick={() => setIndex(1)}
                        style={
                            index === 1 ? styles.mobile_indicator_active : styles.mobile_indicator
                        }
                    />
                    <a
                        onClick={() => setIndex(2)}
                        style={
                            index === 2 ? styles.mobile_indicator_active : styles.mobile_indicator
                        }
                    />
                    <a
                        onClick={() => setIndex(3)}
                        style={
                            index === 3 ? styles.mobile_indicator_active : styles.mobile_indicator
                        }
                    />
                </div>
                <Link to={products[index].link} style={{ marginLeft: '8vw' }}>
                    <img
                        src={products[index].image}
                        alt="product"
                        style={{
                            width: '80vw',
                            height: '80vw',
                            maxWidth: '400px',
                            maxHeight: '400px',
                            objectFit: 'contain',
                        }}
                    />
                </Link>
            </div>

            <div
                style={{
                    padding: '0px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    marginBottom: '100px',
                    maxWidth: '500px',
                }}
            >
                <span style={styles.title2}>{dict.home_product_slider_title[lang]}</span>
                <div
                    style={{
                        margin: '12px 0px',
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                    }}
                >
                    {dict.home_product_slider_desc[lang]}
                </div>
                <span
                    style={{
                        color: '#0e509f',
                        fontFamily: 'Roboto',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginBottom: '16px',
                    }}
                >
                    {dict.home_product_slider_tag[lang]}
                </span>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Link to="/products" style={{ ...styles.btn, height: '40px' }}>
                        <div style={{ flex: 1 }}>
                            <p style={styles.btn_text}>{dict.btn_view_product[lang]}</p>
                        </div>
                        <img src={arrow} style={styles.arrow} />
                    </Link>

                    <div style={{ width: '20px' }} />

                    <img
                        src={badge}
                        style={{
                            width: '30px',
                            height: '30px',
                            objectFit: 'contain',
                            marginRight: '12px',
                        }}
                        alt="certified"
                    />
                    <img
                        src={bpom}
                        alt="bpom"
                        style={{
                            width: '30px',
                            height: '30px',
                            objectFit: 'contain',
                        }}
                    />
                    <img
                        src={mui}
                        alt="mui"
                        style={{
                            width: '30px',
                            height: '30px',
                            objectFit: 'contain',
                            margin: '0px 12px',
                        }}
                    />
                    <img
                        src={fda}
                        alt="fda"
                        style={{
                            width: '50px',
                            height: '30px',
                            objectFit: 'contain',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const styles: { [x: string]: CSSProperties } = {
    btn: {
        width: '150px',
        background: 'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 10px',
        overflow: 'hidden',
    },
    btn_text: {
        color: '#fff',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'Roboto',
        fontWeight: 500,
        marginBottom: 0,
    },
    arrow: {
        width: '20px',
        height: '15px',
        objectFit: 'contain',
    },
    title2: {
        background: 'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontSize: '1.3em',
        fontFamily: 'Roboto',
        fontWeight: 700,
        lineHeight: '1.3em',
    },
    indicator: {
        width: '15px',
        height: '15px',
        backgroundColor: '#01c7ce',
        marginRight: '12px',
        cursor: 'pointer',
    },
    indicator_active: {
        width: '15px',
        height: '15px',
        backgroundColor: '#f2c731',
        marginRight: '12px',
        cursor: 'pointer',
    },
    mobile_indicator: {
        width: '10px',
        height: '10px',
        backgroundColor: '#01c7ce',
        marginRight: '10px',
        cursor: 'pointer',
    },
    mobile_indicator_active: {
        width: '10px',
        height: '10px',
        backgroundColor: '#f2c731',
        marginRight: '10px',
        cursor: 'pointer',
    },
};
