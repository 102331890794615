import React from 'react';
import Footer from '../Home/Footer';
import { Container, Row, Col } from 'reactstrap';
import logo_white from '../../img/logo-rafa-white.png';
import bpom from '../../img/logo-bpom.png';
import mui from '../../img/logo-mui.png';
import fda from '../../img/logo-fda.png';
import badge from '../../img/icon-badge.png';
import productcover from '../../img/rafa-khomsah-product-cover.png';
import ProductSpec from './ProductSpec';
import logo_ori from '../../img/logo-rafa-ori.png';
import productimg from '../../img/product-rafa-detail.png';
import MoreProducts from './MoreProducts';
import extra1 from '../../img/rafa-extra-1.png';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import dict from '../../utils/dict';

export default ({
    lang,
    setLang,
    isLoggedIn,
    settings,
}: {
    lang: 'en' | 'id';
    setLang: any;
    isLoggedIn: any;
    settings: any;
}) => {
    return (
        <>
            <div className="d-none d-md-block" style={{ height: '95px' }} />
            <div className="d-block d-md-none" style={{ height: '56px' }} />
            <div
                style={{
                    background:
                        'linear-gradient(180deg, rgba(42,79,59, 1) 0%, rgba(106,176,68, 1) 100%)',
                    width: '100%',
                    paddingTop: '95px',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                <Container>
                    <Row>
                        <Col
                            md={5}
                            sm={12}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                className="d-none d-md-block"
                                src={logo_white}
                                style={{
                                    width: 'auto',
                                    maxWidth: '400px',
                                    height: '10vw',
                                    maxHeight: '120px',
                                    minHeight: '100px',
                                    objectFit: 'contain',
                                    marginBottom: '20px',
                                    objectPosition: '0px 0%',
                                }}
                            />
                            <img
                                className="d-sm-block d-md-none"
                                src={logo_white}
                                style={{
                                    width: 'auto',
                                    maxWidth: '240px',
                                    height: '10vw',
                                    maxHeight: '120px',
                                    minHeight: '100px',
                                    objectFit: 'contain',
                                    marginBottom: '20px',
                                    objectPosition: '0px 0%',
                                }}
                            />

                            <p
                                style={{
                                    fontFamily: 'Roboto',
                                    color: '#fff',
                                    fontSize: '18px',
                                    marginBottom: '20px',
                                }}
                            >
                                {dict.product_detail_rafa[lang]}
                            </p>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: '40px',
                                }}
                            >
                                <img
                                    src={badge}
                                    style={{
                                        width: '45px',
                                        height: '45px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                    alt="certified"
                                />
                                <img
                                    src={mui}
                                    alt="mui"
                                    style={{
                                        width: '47px',
                                        height: '47px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                />
                                <img
                                    src={fda}
                                    alt="fda"
                                    style={{
                                        width: '55px',
                                        height: '45px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                />
                                <img
                                    src={bpom}
                                    alt="bpom"
                                    style={{ width: '44px', height: '44px', objectFit: 'contain' }}
                                />
                            </div>
                        </Col>

                        <Col
                            md={7}
                            sm={12}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={productcover}
                                style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                            />
                        </Col>
                    </Row>

                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'row',
                            top: '20px',
                        }}
                    >
                        <Link to="/" style={{ color: '#fff', ...styles.breadcrumbs }}>
                            {dict.menu_home[lang]}
                        </Link>
                        <span
                            style={{
                                fontFamily: 'Roboto Condensed',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: '#fff',
                                padding: '8px',
                            }}
                        >
                            /
                        </span>
                        <Link to="/products" style={{ color: '#fff', ...styles.breadcrumbs }}>
                            {dict.menu_products[lang]}
                        </Link>
                        <span
                            style={{
                                fontFamily: 'Roboto Condensed',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: '#fff',
                                padding: '8px',
                            }}
                        >
                            /
                        </span>
                        <Link
                            to="/rafa-khomsah"
                            style={{ color: '#e9bc1d', ...styles.breadcrumbs }}
                        >
                            RAFA KHOMSAH
                        </Link>
                    </div>
                </Container>
            </div>

            <ProductSpec
                logo={logo_ori}
                extra1={extra1}
                logostyle={{
                    width: '60%',
                    height: 'auto',
                    objectFit: 'contain',
                    marginTop: '30px',
                    marginBottom: '30px',
                }}
                generalSpec={dict.product_detail_rafa_desc[lang]}
                specialColor="#023a19"
                specialSpec={dict.product_detail_rafa_tag[lang]}
                specDetails={[
                    {
                        title: lang === 'en' ? 'INGREDIENTS' : 'KOMPOSISI',
                        text:
                            lang === 'en'
                                ? [
                                      'Each 500 mg contains:',
                                      '100 mg of Nigella sativa semen extract',
                                      '100 mg of Centella asiatica herbal extract',
                                      '100 mg of Curcuma xanthorrhiza rhizome extract',
                                      '100 mg of Psidium guajava folium extract, and',
                                      '100 mg of Oryza sativa bran extract',
                                  ]
                                : [
                                      'Tiap 500 mg mengandung:',
                                      'Ekstrak Nigella sativa semen 100 mg',
                                      'Ekstrak Centella asiatica herba 100 mg',
                                      'Ekstrak Curcuma xanthorrhiza rhizome 100 mg',
                                      'Ekstrak Psidium guajava folium 100 mg',
                                      'Ekstrak Oryza sativa bran 100 mg',
                                  ],
                    },
                    {
                        title: lang === 'en' ? 'INDICATIONS' : 'INDIKASI',
                        text:
                            lang === 'en'
                                ? ['Helps maintain health.']
                                : ['Membantu memelihara kesehatan'],
                    },
                    {
                        title: lang === 'en' ? 'DOSAGE' : 'DOSIS',
                        text:
                            lang === 'en'
                                ? ['3 times a day 1-2 capsules, taken after meals.']
                                : ['3x sehari 1-2 kapsul, diminum sesudah makan'],
                    },
                    {
                        title: lang === 'en' ? 'STORAGE' : 'PENYIMPANAN',
                        text:
                            lang === 'en'
                                ? [
                                      'Store below 30°C in a dry and tightly closed container, keep it away from direct sunlight.',
                                  ]
                                : [
                                      'Simpan dibawah suhu 30◦C dalam wadah kering dan tertutup rapat, hindarkan dari sinar matahari langsung',
                                  ],
                    },
                ]}
                productimg={productimg}
            />

            <MoreProducts exclude="rafa" lang={lang} />

            <Footer bgColor="#023a19" lang={lang} settings={settings} />

            <Navbar
                lang={lang}
                setLang={setLang}
                upperColor={'#fff'}
                footerColor={'rgba(42,79,59, 1)'}
                mode="reversed"
                productType="rafa"
                selected="products"
            />
        </>
    );
};

const styles = {
    breadcrumbs: {
        fontFamily: 'Roboto Condensed',
        fontSize: '14px',
        fontWeight: 700,
        padding: '8px 0px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
};
