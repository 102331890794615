import { BASE_URL } from './../../constants';

const URL_SEND_MESSAGE = `${BASE_URL}/contact-us`;

export const sendMessage = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(URL_SEND_MESSAGE, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};
