import { BASE_URL } from './../../constants';
import Cookies from 'js-cookie';

const URL_REGISTER = `${BASE_URL}/auth/register`;
const URL_LOGIN = `${BASE_URL}/auth/login`;
const URL_LOGOUT = `${BASE_URL}/auth/logout`;
const URL_REFRESH_TOKEN = `${BASE_URL}/auth/refresh-token`;
const URL_PROFILE = `${BASE_URL}/me`;

export const refreshToken = (
    refresh_token: string,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(URL_REFRESH_TOKEN, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refresh_token,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const logout = (
    token: string,
    onSuccess?: (data: any) => void,
    onFailed?: (e: any) => void
) => {
    fetch(URL_LOGOUT, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            if (onSuccess) onSuccess(data);
        })
        .catch((e) => {
            if (onFailed) onFailed(e);
        });
};

export const getProfile = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(URL_PROFILE, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${data.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const loginEmail = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(URL_LOGIN, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 200) return res.json();
            else if (res.status === 400) {
                onSuccess('register');
            } else {
                onFailed('error');
            }
        })
        .then((res) => {
            if (res) onSuccess(res);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const loginFacebook = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(URL_LOGIN, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((res) => {
            onSuccess(res);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const loginGoogle = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    console.log(URL_LOGIN, JSON.stringify(data));
    fetch(URL_LOGIN, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => {
            console.log(res);
            return res.json();
        })
        .then((res) => {
            requestAccessGoogle(onSuccess);
            // onSuccess(res);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const requestAccessGoogle = (onSuccess: (data: any) => void) => {
    const fd = new FormData();

    fd.append('grant_type', 'authorization_code');
    fd.append('code', '');
    fd.append('client_id', '');
    fd.append('client_secret', '');
    fd.append('redirect_uri', '');

    fetch('https://oauth2.googleapis.com/token', {
        method: 'POST',
        body: fd,
    })
        .then((res) => {
            console.log(res);
            return res.json();
        })
        .then((res) => {
            console.log(res);
            // onSuccess(res);
        })
        .catch((e) => {
            console.log(e);
        });
};

export const registerEmail = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    console.log(JSON.stringify(data));
    fetch(URL_REGISTER, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 200 || res.status === 201) return res.json();
            else if (res.status === 422) {
                onSuccess('taken');
            } else {
                onFailed('error');
            }
        })
        .then((res) => {
            if (res) onSuccess(res);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const registerFacebook = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(URL_REGISTER, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            onSuccess(res);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const registerGoogle = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(URL_REGISTER, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((res) => {
            onSuccess(res);
        })
        .catch((e) => {
            onFailed(e);
        });
};
