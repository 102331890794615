import { BASE_URL } from './../../constants';
import Cookies from 'js-cookie';

const URL_POSTS = `${BASE_URL}/posts?item_per_page=9999999`;
const URL_POST_DETAIL = `${BASE_URL}/posts`;
const URL_LATEST_POSTS = `${BASE_URL}/posts?item_per_page=3&sort_by=published_at&sort_type=desc`;
const URL_COMMENTS = `${BASE_URL}/post-comments?item_per_page=99999&sort_type=latest_comment_first&post_id=`;

export const getPosts = (onSuccess: (data: any) => void, onFailed: (e: any) => void) => {
    fetch(URL_POSTS, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const getPostDetail = (
    postId: number,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(`${URL_POST_DETAIL}/${postId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => {
            if (res.status === 200) return res.json();
            else if (res.status === 404) {
                onSuccess('not_found');
            } else {
                onFailed('error');
            }
        })
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const getLatestPosts = (onSuccess: (data: any) => void, onFailed: (e: any) => void) => {
    fetch(URL_LATEST_POSTS, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const getComments = (
    postId: number,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(`${URL_COMMENTS}${postId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const postComments = (
    data: any,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    const cookies = Cookies.get('auth');
    const token = JSON.parse(cookies!).accessToken;

    fetch(`${URL_COMMENTS}${data.post_id}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};
