import React, { CSSProperties, useCallback, useState, useRef } from 'react';
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Spinner,
} from 'reactstrap';
import logo_white from '../../img/logo-nucleus-white.png';
import arrowtop from '../../img/arrow-top.png';
import arrow_submit from '../../img/arrow-submit.png';
import facebook from '../../img/logo-facebook.png';
import instagram from '../../img/logo-instagram.png';
import { Link } from 'react-router-dom';
import dict from '../../utils/dict';
import { subscribeEmail } from './apiHome';

interface IFooterProps {
    lang: 'en' | 'id';
    bgColor?: string;
    settings: any;
}

export default ({ bgColor, lang, settings }: IFooterProps) => {
    const [showModal, setShowModal] = useState(false);
    const [textModal, setTextModal] = useState('');
    const [emailSubscription, setEmailSubscription] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const gotoTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleSubmit = useCallback(() => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(emailSubscription).toLowerCase())) {
            setTextModal(
                lang === 'en'
                    ? 'Email is not valid, please input a valid email.'
                    : 'Email tidak benar, tolong masukkan email yang benar.'
            );
            setShowModal(true);
            return;
        }

        setLoading(true);
        subscribeEmail(
            {
                email: emailSubscription,
            },
            (res) => {
                console.log(res);
                setEmailSubscription('');
                setLoading(false);
                setTextModal(
                    lang === 'en'
                        ? 'You have been successfully subscribed to our newsletter.'
                        : 'Anda telah berhasil berlangganan.'
                );
                setShowModal(true);
            },
            (e) => {
                setLoading(false);
            }
        );
    }, [emailSubscription]);

    return (
        <div style={{ backgroundColor: bgColor || '#0e509f', ...styles.container }}>
            <Container fluid style={{ padding: '0vw 4.5vw' }}>
                {/* <Row>
                    <Col xs={12} sm={6}>
                        <img src={logo_white} style={styles.logo_white} />
                    </Col>
                </Row> */}

                <Row>
                    <Col
                        md={3}
                        xs={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '30px',
                            // justifyContent: 'center',
                        }}
                    >
                        <img src={logo_white} style={styles.logo_white} />
                        {/* <p style={{ fontFamily: 'Roboto', fontSize: '14px', color: '#fff' }}>
                            Our ultimate goal is to provide a better alternative medicine at the
                            lowest cost so people can get best in calss in treatment and quality
                            health care cost-effectively.
                        </p> */}
                    </Col>

                    <Col
                        md={6}
                        xs={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '30px',
                        }}
                    >
                        <label style={styles.title}>Site Map</label>
                        <Row>
                            <Col xs={6} sm={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link to="/" style={styles.menu}>
                                    {dict.footer_home[lang]}
                                </Link>
                                <Link to="/about" style={styles.menu}>
                                    {dict.footer_about[lang]}
                                </Link>
                                <Link to="/contact" style={styles.menu}>
                                    {dict.footer_contact[lang]}
                                </Link>
                            </Col>
                            <Col xs={6} sm={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link to="/onoiwa" style={styles.menu}>
                                    Onoiwa
                                </Link>
                                <Link to="/onogate" style={styles.menu}>
                                    Onogate
                                </Link>
                                <Link to="/onoake" style={styles.menu}>
                                    Onoake
                                </Link>
                                <Link to="/rafa-khomsah" style={styles.menu}>
                                    Rafa Khomsah
                                </Link>
                            </Col>
                            <Col xs={6} sm={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                {settings.showJournalResearch && (
                                    <Link to="/blog" style={styles.menu}>
                                        Journal & Research
                                    </Link>
                                )}
                                <Link to="/blog" style={styles.menu}>
                                    {dict.footer_healthy_tips[lang]}
                                </Link>
                                {settings.showFAQ && (
                                    <Link to="/faq" style={styles.menu}>
                                        {dict.footer_faq[lang]}
                                    </Link>
                                )}
                            </Col>
                            <Col xs={6} sm={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                {settings.showLegal && (
                                    <Link to="/legal" style={styles.menu}>
                                        {dict.footer_legal[lang]}
                                    </Link>
                                )}
                                {settings.showPrivacy && (
                                    <Link to="/privacy-policy" style={styles.menu}>
                                        {dict.footer_privacy[lang]}
                                    </Link>
                                )}
                                {settings.showDisclaimer && (
                                    <Link to="/disclaimer" style={styles.menu}>
                                        {dict.footer_disclaimer[lang]}
                                    </Link>
                                )}
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        md={3}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'column', marginBottom: '30px' }}
                    >
                        <label style={styles.title}>{dict.footer_subscribe[lang]}</label>
                        <p
                            style={{
                                fontFamily: 'Roboto',
                                color: '#fff',
                                fontSize: '14px',
                            }}
                        >
                            {dict.footer_subscribe_desc[lang]}
                        </p>

                        <div style={{ position: 'relative' }}>
                            <Input
                                value={emailSubscription}
                                onChange={(e) => {
                                    setEmailSubscription(e.target.value);
                                }}
                                placeholder={dict.input_your_email[lang]}
                                type="email"
                                style={styles.input}
                            />
                            {emailSubscription !== '' && (
                                <button
                                    type="button"
                                    disabled={loading}
                                    onClick={handleSubmit}
                                    style={{
                                        position: 'absolute',
                                        right: '8px',
                                        top: 0,
                                        width: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                    }}
                                >
                                    {loading ? (
                                        <Spinner size="sm" color="secondary" />
                                    ) : (
                                        <img
                                            src={arrow_submit}
                                            style={{
                                                width: '12px',
                                                height: '12px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    )}
                                </button>
                            )}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: '16px',
                            }}
                        >
                            <span style={styles.text2}>{dict.footer_follow[lang]}</span>
                            <div style={{ width: '20px' }} />
                            <a
                                href="https://www.facebook.com/Nucleus-Farma-Indonesia-108190173912320/"
                                target="_blank"
                            >
                                <img
                                    src={facebook}
                                    style={{
                                        width: '22px',
                                        height: '22px',
                                        objectFit: 'contain',
                                        marginRight: '8px',
                                    }}
                                />
                            </a>
                            <a href="https://www.instagram.com/nucleusfarma/" target="_blank">
                                <img
                                    src={instagram}
                                    style={{ width: '22px', height: '22px', objectFit: 'contain' }}
                                />
                            </a>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <span style={styles.footer}>&copy; Nucleus farma 2020</span>
                    </Col>
                    <Col
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
                    >
                        <a
                            onClick={gotoTop}
                            style={{
                                height: '40px',
                                width: '40px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={arrowtop}
                                style={{ width: '20px', height: '20px', objectFit: 'contain' }}
                            />
                        </a>
                    </Col>
                </Row>
            </Container>

            <Modal
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                style={{
                    marginTop: '150px',
                }}
            >
                <ModalBody>
                    <p style={{ marginBottom: 0, fontFamily: 'Roboto' }}>{textModal}</p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            backgroundColor: '#0e509f',
                            fontFamily: 'Roboto',
                            fontWeight: 700,
                        }}
                        onClick={() => setShowModal(false)}
                    >
                        OK
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

const styles: { [x: string]: CSSProperties } = {
    input: {
        borderRadius: 0,
        fontSize: '14px',
        height: '40px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        paddingRight: '60px',
        paddingLeft: '20px',
    },
    footer: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: 'white',
    },
    title: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        color: 'white',
        fontSize: '24px',
        marginBottom: '12px',
    },
    container: {
        padding: '80px 0px 40px 0px',
    },
    logo_white: {
        width: '80%',
        maxWidth: '200px',
        height: 'auto',
        objectFit: 'contain',
        marginBottom: '20px',
    },
    label: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        color: 'white',
        fontSize: '12px',
        marginBottom: '4px',
    },
    text: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: 'white',
        fontSize: '11px',
        lineHeight: '13px',
    },
    text2: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        color: 'white',
        fontSize: '14px',
        marginBottom: 0,
    },
    text3: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: 'white',
        fontSize: '12px',
        marginBottom: '12px',
    },
    menu: {
        fontFamily: 'Roboto Condensed',
        fontWeight: 700,
        color: 'white',
        fontSize: '15px',
        textDecorationColor: 'white',
        marginBottom: '8px',
    },
};
