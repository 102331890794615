import React, { CSSProperties, useCallback } from 'react';
import cover_prescription from '../../img/cover-products.png';
import m_cover_prescription from '../../img/m-cover-prescription.png';
import cover_otc from '../../img/cover-products-otc.png';
import m_cover_otc from '../../img/m-cover-otc.png';
import { Container, Col } from 'reactstrap';
import { Badges } from '../Home/HeroImage';
import arrow from '../../img/icon-arrow.png';
import dict from '../../utils/dict';

const Copy = ({ lang }: { lang: 'en' | 'id' }) => {
    const scrollToContent = useCallback(() => {
        window.scrollTo({ top: window.innerHeight - 65, behavior: 'smooth' });
    }, []);

    return (
        <Col
            lg={7}
            md={8}
            sm={10}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'center',
            }}
        >
            <span
                className="d-none d-md-block"
                style={{
                    ...styles.title,
                    fontSize: '2em',
                    lineHeight: '1.2em',
                    color: '#0e509f',
                }}
            >
                {dict.products_title[lang]}
            </span>

            {/* <span
                className="d-none d-lg-block"
                style={{ ...styles.title, fontSize: '2em', lineHeight: '1.2em' }}
            >
                the health of you and your family
            </span>
            <span
                className="d-md-block d-lg-none"
                style={{ ...styles.title, fontSize: '1.6em', lineHeight: '1.3em' }}
            >
                the health of you and your family
            </span> */}

            <div style={{ maxWidth: '450px' }}>
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}
                >
                    {dict.products_desc[lang]}
                </p>
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontWeight: 700,
                        color: 'rgba(14, 80, 159, 1)',
                        fontSize: '14px',
                        marginBottom: '30px',
                    }}
                >
                    {dict.products_tag[lang]}
                </p>
            </div>

            <a onClick={scrollToContent} style={styles.btn_view}>
                <div style={{ flex: 1 }}>
                    <p style={styles.btn_text}>{dict.btn_view_product[lang]}</p>
                </div>
                <img src={arrow} style={styles.arrow} />
            </a>

            {/* <div style={{ height: '' }}/> */}
        </Col>
    );
};

export default ({ cover, lang }: { cover?: any; lang: 'en' | 'id' }) => {
    const scrollToContent = useCallback(() => {
        window.scrollTo({ top: window.innerHeight - 65, behavior: 'smooth' });
    }, []);

    return (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                backgroundColor: '#fff',
            }}
        >
            <div
                className="d-xs-block d-md-none"
                style={{ position: 'relative', height: '200px' }}
            />
            {(cover === 'prescription' || cover === 'otc') && (
                <>
                    <img
                        className="d-none d-md-block"
                        src={cover === 'prescription' ? cover_prescription : cover_otc}
                        style={{
                            width: '100%',
                            height: '100vh',
                            position: 'absolute',
                            objectFit: 'cover',
                        }}
                    />
                    <img
                        className="d-xs-block d-md-none"
                        src={cover === 'prescription' ? m_cover_prescription : m_cover_otc}
                        style={{
                            width: '100%',
                            height: '100vh',
                            position: 'absolute',
                            objectFit: 'cover',
                        }}
                    />
                </>
            )}

            <Container fluid>
                <div className="d-none d-md-block">
                    <div style={{ position: 'absolute', right: '20px', top: '120px' }}>
                        <Badges />
                    </div>
                    <Copy lang={lang} />
                </div>

                <div className="d-sm-block d-md-none">
                    <div style={{ position: 'absolute', left: '20px', top: '80px' }}>
                        <Badges />
                    </div>

                    <div style={{ position: 'absolute', top: '140px' }}>
                        <span
                            className="d-md-block d-lg-none"
                            style={{
                                ...styles.title,
                                fontSize: '1.6em',
                                lineHeight: '1.3em',
                                color: '#0e509f',
                            }}
                        >
                            {dict.products_title[lang]}
                        </span>
                        <div style={{ maxWidth: '450px' }}>
                            <p
                                style={{
                                    fontFamily: 'Roboto',
                                    fontSize: '14px',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                }}
                            >
                                {dict.products_desc[lang]}
                            </p>
                            <p
                                style={{
                                    fontFamily: 'Roboto',
                                    fontWeight: 700,
                                    color: 'rgba(14, 80, 159, 1)',
                                    fontSize: '14px',
                                    marginBottom: '30px',
                                }}
                            >
                                {dict.products_tag[lang]}
                            </p>
                        </div>

                        <a onClick={scrollToContent} style={styles.btn_view}>
                            <div style={{ flex: 1 }}>
                                <p style={styles.btn_text}>{dict.btn_view_product[lang]}</p>
                            </div>
                            <img src={arrow} style={styles.arrow} />
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    );
};

const styles: { [x: string]: CSSProperties } = {
    title: {
        fontFamily: 'Roboto',
        fontWeight: 700,
    },
    btn_view: {
        width: '200px',
        height: '50px',
        background: 'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 16px',
        cursor: 'pointer',
    },
    btn_text: {
        color: '#fff',
        fontSize: '15px',
        fontFamily: 'Roboto',
        fontWeight: 500,
        marginBottom: 0,
    },
    arrow: {
        width: '25px',
        height: '20px',
        objectFit: 'contain',
    },
};
