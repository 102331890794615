import React from 'react';
import Footer from '../Home/Footer';
import ProductBanner from './ProductBanner';
import ProductContent from './ProductContent';
import Navbar from '../Navbar';

export default ({ lang, setLang, settings }: any) => {
    return (
        <>
            <ProductBanner cover="prescription" lang={lang} />
            <ProductContent
                title={lang === 'en' ? 'Our Products' : 'Produk Kami'}
                data={['onoiwa', 'onogate', 'onoake', 'rafa']}
                lang={lang}
            />
            <Footer lang={lang} settings={settings} />

            <Navbar {...{ lang, setLang, selected: 'products' }} />
        </>
    );
};
