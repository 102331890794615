import React, { CSSProperties, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import onoiwa from '../../img/onoiwa-preview.png';
import onoiwaplus from '../../img/onoiwa-plus-preview.png';
import onoiwamx from '../../img/onoiwa-mx-preview.png';
import onogate from '../../img/onogate-preview.png';
import onoake from '../../img/onoake-preview.png';
import rafa from '../../img/rafa-preview.png';
import { Link } from 'react-router-dom';
import bgcover from '../../img/bg-products.png';
import extra from '../../img/product-extra-shape.png';
import dict from '../../utils/dict';
import { getProducts } from './apiProducts';

const ProductItem = ({
    lang,
    img,
    description,
    linkTo,
}: {
    lang: 'en' | 'id';
    img: any;
    description: any;
    linkTo: any;
}) => {
    return (
        <div style={{ position: 'relative', marginBottom: '80px' }}>
            <div
                style={{
                    height: '170px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    position: 'relative',
                    // backgroundColor: '#f5f5f5',
                }}
            >
                <img
                    src={img}
                    style={{
                        width: '100%',
                        // height: 'auto',
                        height: '170px',
                        objectFit: 'contain',
                        objectPosition: '0px 100%',
                        maxWidth: '350px',
                        marginTop: '30px',
                    }}
                />
            </div>

            <div
                className="d-xs-block d-sm-none d-md-none d-lg-none"
                style={{
                    height: '100px',
                    maxWidth: '300px',
                    ...styles.desc_container,
                }}
            >
                <p style={{ fontFamily: 'Roboto', fontSize: '14px' }}>{description}</p>
            </div>

            <div
                className="d-none d-sm-block d-md-none d-lg-none"
                style={{
                    height: '160px',
                    ...styles.desc_container,
                }}
            >
                <p style={{ fontFamily: 'Roboto', fontSize: '14px' }}>{description}</p>
            </div>

            <div
                className="d-none d-sm-none d-md-block d-lg-none"
                style={{
                    height: '150px',
                    ...styles.desc_container,
                }}
            >
                <p style={{ fontFamily: 'Roboto', fontSize: '14px' }}>{description}</p>
            </div>

            <div
                className="d-none d-sm-none d-md-none d-lg-block"
                style={{
                    height: '130px',
                    ...styles.desc_container,
                }}
            >
                <p style={{ fontFamily: 'Roboto', fontSize: '14px' }}>{description}</p>
            </div>

            <Link to={linkTo} style={styles.btn}>
                {dict.btn_product_detail[lang]}
            </Link>
        </div>
    );
};

export default ({ title, data, lang }: { title: string; data: any; lang: 'en' | 'id' }) => {
    useEffect(() => {
        getProducts(
            (data) => {
                console.log(data);
            },
            (e) => {
                console.log(e);
            }
        );
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <div
                className="d-xs-block d-md-none"
                style={{ height: '200px', position: 'relative' }}
            />
            <img
                className="d-none d-md-block"
                src={extra}
                style={{ top: '-23px', height: '60px', width: 'auto', position: 'absolute' }}
            />
            <img
                className="d-sm-block d-md-none"
                src={extra}
                style={{ top: '177px', height: '40px', width: 'auto', position: 'absolute' }}
            />
            <img
                src={bgcover}
                style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                }}
            />
            <Container style={{ paddingTop: '60px' }}>
                <p
                    className="d-none d-lg-block"
                    style={{
                        fontWeight: 700,
                        fontFamily: 'Roboto',
                        fontSize: '3em',
                        color: 'rgba(14, 80, 159, 1)',
                        marginBottom: '40px',
                    }}
                >
                    {title}
                </p>
                <p
                    className="d-md-block d-lg-none"
                    style={{
                        fontWeight: 700,
                        fontFamily: 'Roboto',
                        fontSize: '2.5em',
                        color: 'rgba(14, 80, 159, 1)',
                        marginBottom: '40px',
                    }}
                >
                    {title}
                </p>

                <Row>
                    {data.includes('onoiwa') && (
                        <Col md={4} sm={6}>
                            <ProductItem
                                img={onoiwa}
                                description={dict.prescription_onoiwa[lang]}
                                linkTo="/onoiwa"
                                lang={lang}
                            />
                        </Col>
                    )}

                    {data.includes('onoiwa') && (
                        <Col md={4} sm={6}>
                            <ProductItem
                                img={onoiwaplus}
                                description={dict.prescription_onoiwa_plus[lang]}
                                linkTo="/onoiwa"
                                lang={lang}
                            />
                        </Col>
                    )}

                    {data.includes('onoiwa') && (
                        <Col md={4} sm={6}>
                            <ProductItem
                                img={onoiwamx}
                                description={dict.prescription_onoiwa_mx[lang]}
                                linkTo="onoiwa"
                                lang={lang}
                            />
                        </Col>
                    )}

                    {data.includes('onogate') && (
                        <Col md={4} sm={6}>
                            <ProductItem
                                img={onogate}
                                description={dict.prescription_onogate[lang]}
                                linkTo="onogate"
                                lang={lang}
                            />
                        </Col>
                    )}

                    {data.includes('onoake') && (
                        <Col md={4} sm={6}>
                            <ProductItem
                                img={onoake}
                                description={dict.otc_onoake[lang]}
                                linkTo="/onoake"
                                lang={lang}
                            />
                        </Col>
                    )}

                    {data.includes('rafa') && (
                        <Col md={4} sm={6}>
                            <ProductItem
                                img={rafa}
                                description={dict.otc_rafa[lang]}
                                linkTo="/rafa-khomsah"
                                lang={lang}
                            />
                        </Col>
                    )}
                </Row>

                <div style={{ height: '10vw', maxHeight: '250px' }} />
            </Container>
        </div>
    );
};

const styles: { [x: string]: CSSProperties } = {
    title: {
        background: 'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontFamily: 'Roboto',
        fontWeight: 700,
    },
    btn: {
        height: '40px',
        backgroundColor: 'rgba(14, 80, 159, 1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 700,
        color: '#fff',
        maxWidth: '140px',
        textDecoration: 'none',
        marginBottom: '40px',
    },
    desc_container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 0px',
    },
};
