import React from 'react';
import Footer from '../Home/Footer';
import { Container, Row, Col } from 'reactstrap';
import logo_white from '../../img/logo-onogate-white.png';
import bpom from '../../img/logo-bpom.png';
import mui from '../../img/logo-mui.png';
import fda from '../../img/logo-fda.png';
import badge from '../../img/icon-badge.png';
import productcover from '../../img/onogate-product-cover.png';
import ProductSpec from './ProductSpec';
import logo_ori from '../../img/logo-onogate-ori.png';
import productimg from '../../img/product-onogate-detail.png';
import MoreProducts from './MoreProducts';
import extra1 from '../../img/onogate-extra-1.png';
// import extra2 from '../../img/onogate-detail-extra.png';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import dict from '../../utils/dict';

export default ({
    lang,
    setLang,
    isLoggedIn,
    settings,
}: {
    lang: 'en' | 'id';
    setLang: any;
    isLoggedIn: any;
    settings: any;
}) => {
    return (
        <>
            <div className="d-none d-md-block" style={{ height: '95px' }} />
            <div className="d-block d-md-none" style={{ height: '56px' }} />
            <div
                style={{
                    background:
                        'linear-gradient(180deg, rgba(232,56,46,1) 0%, rgba(242,147,0,1) 100%)',
                    width: '100%',
                    paddingTop: '95px',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                <Container>
                    <Row>
                        <Col
                            md={5}
                            sm={12}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                className="d-none d-md-block"
                                src={logo_white}
                                style={{
                                    width: 'auto',
                                    maxWidth: '400px',
                                    height: '10vw',
                                    maxHeight: '70px',
                                    minHeight: '60px',
                                    objectFit: 'contain',
                                    marginBottom: '30px',
                                }}
                            />
                            <img
                                className="d-sm-block d-md-none"
                                src={logo_white}
                                style={{
                                    width: 'auto',
                                    maxWidth: '240px',
                                    height: '10vw',
                                    maxHeight: '70px',
                                    minHeight: '60px',
                                    objectFit: 'contain',
                                    marginBottom: '30px',
                                }}
                            />

                            <p
                                style={{
                                    fontFamily: 'Roboto',
                                    color: '#fff',
                                    fontSize: '18px',
                                    marginBottom: '20px',
                                }}
                            >
                                {dict.product_detail_onogate[lang]}
                            </p>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: '40px',
                                }}
                            >
                                <img
                                    src={badge}
                                    style={{
                                        width: '45px',
                                        height: '45px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                    alt="certified"
                                />
                                <img
                                    src={mui}
                                    alt="mui"
                                    style={{
                                        width: '47px',
                                        height: '47px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                />
                                <img
                                    src={fda}
                                    alt="fda"
                                    style={{
                                        width: '55px',
                                        height: '45px',
                                        objectFit: 'contain',
                                        marginRight: '12px',
                                    }}
                                />
                                <img
                                    src={bpom}
                                    alt="bpom"
                                    style={{ width: '44px', height: '44px', objectFit: 'contain' }}
                                />
                            </div>
                        </Col>

                        <Col
                            md={7}
                            sm={12}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                position: 'relative',
                            }}
                        >
                            <img
                                src={productcover}
                                style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                            />
                        </Col>
                    </Row>

                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'row',
                            top: '20px',
                        }}
                    >
                        <Link to="/" style={{ color: '#fff', ...styles.breadcrumbs }}>
                            {dict.menu_home[lang]}
                        </Link>
                        <span
                            style={{
                                fontFamily: 'Roboto Condensed',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: '#fff',
                                padding: '8px',
                            }}
                        >
                            /
                        </span>
                        <Link to="/products" style={{ color: '#fff', ...styles.breadcrumbs }}>
                            {dict.menu_products[lang]}
                        </Link>
                        <span
                            style={{
                                fontFamily: 'Roboto Condensed',
                                fontSize: '14px',
                                fontWeight: 700,
                                color: '#fff',
                                padding: '8px',
                            }}
                        >
                            /
                        </span>
                        <Link to="/onogate" style={{ color: '#e9bc1d', ...styles.breadcrumbs }}>
                            ONOGATE
                        </Link>
                    </div>
                </Container>
            </div>

            <ProductSpec
                logo={logo_ori}
                extra1={extra1}
                logostyle={{
                    width: '60%',
                    height: 'auto',
                    objectFit: 'contain',
                    marginTop: '30px',
                    marginBottom: '30px',
                }}
                generalSpec={dict.product_detail_onogate_desc[lang]}
                specialColor="#ea541c"
                specialSpec={dict.product_detail_onogate_tag[lang]}
                specDetails={[
                    {
                        title: lang === 'en' ? 'INGREDIENTS' : 'KOMPOSISI',
                        text:
                            lang === 'en'
                                ? ['Each capsule contains 500 mg of Stichopus variegatus extract.']
                                : ['Tiap kapsul mengandung ekstrak Stichopus variegatus 500 mg'],
                    },
                    {
                        title: lang === 'en' ? 'INDICATIONS' : 'INDIKASI',
                        text:
                            lang === 'en'
                                ? ['Helps maintain health.', 'Helps to relieve joint pain.']
                                : [
                                      'Membantu memelihara kesehatan',
                                      'Membantu meredakan nyeri sendi',
                                  ],
                    },
                    {
                        title: lang === 'en' ? 'DOSAGE' : 'DOSIS',
                        text:
                            lang === 'en'
                                ? ['3 times a day 1-2 capsules, taken before meals.']
                                : ['3 x sehari 1-2 kapsul, diminum sebelum makan'],
                    },
                    {
                        title: lang === 'en' ? 'STORAGE' : 'PENYIMPANAN',
                        text:
                            lang === 'en'
                                ? [
                                      'Store below 30°C in a dry and tightly closed container, keep it away from direct sunlight.',
                                  ]
                                : [
                                      'Simpan dibawah suhu 30◦C dalam wadah kering dan tertutup rapat hindarkan dari sinar matahari langsung',
                                  ],
                    },
                ]}
                productimg={productimg}
            />

            <MoreProducts exclude="onogate" lang={lang} />

            <Footer bgColor="#ea541c" lang={lang} settings={settings} />

            <Navbar
                lang={lang}
                setLang={setLang}
                upperColor={'#fff'}
                footerColor={'rgba(232,56,46,1)'}
                mode="reversed"
                productType="onogate"
                selected="products"
            />
        </>
    );
};

const styles = {
    breadcrumbs: {
        fontFamily: 'Roboto Condensed',
        fontSize: '14px',
        fontWeight: 700,
        padding: '8px 0px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
};
