import React, { useState, useCallback } from 'react';
import bglogin from '../../img/bg-login.png';
import {
    Form,
    InputGroup,
    InputGroupAddon,
    Input,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import Register from './Register';
import iconemail from '../../img/icon-email.png';
import iconpassword from '../../img/icon-password.png';
import iconeye from '../../img/icon-eye.png';
import logofb from '../../img/logo-fb.png';
import logogoogle from '../../img/logo-google.png';
import Navbar from '../Navbar';
import { useHistory } from 'react-router-dom';
import { loginEmail, loginGoogle, loginFacebook, registerFacebook } from './apiAuth';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Cookies from 'js-cookie';

export default ({ lang, setLang }: any) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hidePassword, setHidePassword] = useState(true);
    const [showModalLogin, setShowModalLogin] = useState(false);
    const [modalText, setModalText] = useState('');
    const [loginLoading, setLoginLoading] = useState(false);

    const [mode, setMode] = useState<'login' | 'register'>('login');

    const openForgetPassword = useCallback(() => {}, []);

    const handleRegister = useCallback(() => {}, []);

    const handleLogin = useCallback(() => {
        if (email !== '' && password !== '') {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(email).toLowerCase())) {
                setModalText(
                    lang === 'en'
                        ? 'Email is not valid, please input a valid email.'
                        : 'Email tidak benar, tolong masukkan email yang benar.'
                );
                setShowModalLogin(true);
                return;
            }

            setLoginLoading(true);
            loginEmail(
                {
                    grant_type: 'email',
                    email,
                    password,
                },
                (data) => {
                    if (data === 'register') {
                        setLoginLoading(false);
                        setModalText(
                            lang === 'en'
                                ? 'Email is not registered yet, please register first.'
                                : 'Email belum terdaftar, silakan daftar terlebih dahulu.'
                        );
                        setShowModalLogin(true);
                    } else {
                        Cookies.set('auth', {
                            accessToken: data.access_token,
                            refreshToken: data.refresh_token,
                            profile: data.user.name,
                        });
                        history.replace('/');
                    }
                },
                (e) => {
                    setLoginLoading(false);
                }
            );
        }
    }, [email, password, loginLoading]);

    const loginWithGoogle = useCallback(
        (res) => {
            console.log(res);
            setLoginLoading(true);
            loginGoogle(
                {
                    grant_type: 'google',
                    access_token: res.accessToken,
                },
                (res) => {
                    console.log(res);
                },
                (e) => {
                    console.log(e);
                }
            );
        },
        [loginLoading]
    );

    const loginWithFacebook = useCallback(
        (res) => {
            setLoginLoading(true);
            handleLoginFb(res);
        },
        [loginLoading]
    );

    const handleLoginFb = useCallback(
        (res) => {
            // console.log('RETURN BTN FB:', res);
            loginFacebook(
                {
                    grant_type: 'facebook',
                    access_token: res.accessToken,
                },
                (obj) => {
                    // console.log('RETURN LOGIN FB:', obj);

                    if (obj.user) {
                        setLoginLoading(false);
                        Cookies.set('auth', {
                            accessToken: obj.access_token,
                            refreshToken: obj.refresh_token,
                            profile: obj.user.name,
                        });
                        history.replace('/');
                    } else if (obj.message === 'Account not found. Please register.') {
                        const fb = obj.facebook_details;
                        registerFacebook(
                            {
                                register_from: 'facebook',
                                name: `${fb.first_name} ${fb.last_name}`,
                                facebook_id: fb.id,
                                facebook_photo: fb.picture.data.url,
                            },
                            (x) => {
                                // console.log('RETURN REGISTER FB', x);
                                handleLoginFb(res);
                            },
                            (e) => {
                                setLoginLoading(false);
                                // console.log('FAILED REGISTER FB', e);
                            }
                        );
                    } else {
                        setLoginLoading(false);
                    }
                },
                (e) => {
                    // console.log('FAILED LOGIN FB', e);
                    setLoginLoading(false);
                }
            );
        },
        [loginLoading]
    );

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            }}
        >
            <img
                src={bglogin}
                style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
            />

            <div
                style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                <Col
                    md={{ size: 6, offset: 3 }}
                    sm={{ size: 10, offset: 1 }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#fff',
                        padding: '30px 0px',
                        marginTop: '150px',
                        alignItems: 'center',
                        WebkitBoxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                        MozBoxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                        boxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                    }}
                >
                    <BlockUi tag="div" blocking={loginLoading}>
                        <p
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '32px',
                                textAlign: 'center',
                                fontWeight: 700,
                                marginBottom: '30px',
                            }}
                        >
                            {mode === 'login'
                                ? lang === 'en'
                                    ? 'Welcome Back'
                                    : 'Selamat Datang'
                                : lang === 'en'
                                ? 'Join Us'
                                : 'Mari Gabung'}
                        </p>
                        {/* <div style={{ maxWidth: '300px' }}>
                        <p
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '14px',
                                marginBottom: '30px',
                                textAlign: 'center',
                            }}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                    </div> */}

                        {mode === 'login' ? (
                            <Form
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <InputGroup
                                    style={{
                                        width: '100%',
                                        maxWidth: '300px',
                                        marginBottom: '12px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <InputGroupAddon
                                        addonType="prepend"
                                        style={{
                                            width: '45px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <img
                                            src={iconemail}
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </InputGroupAddon>
                                    <Input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email"
                                        type="email"
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontSize: '14px',
                                            height: '45px',
                                            border: 'none',
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup
                                    style={{
                                        width: '100%',
                                        maxWidth: '300px',
                                        marginBottom: '8px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <InputGroupAddon
                                        addonType="prepend"
                                        style={{
                                            width: '45px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <img
                                            src={iconpassword}
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </InputGroupAddon>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"
                                        type={hidePassword ? 'password' : 'text'}
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontSize: '14px',
                                            border: 'none',
                                            height: '45px',
                                        }}
                                    />
                                    <InputGroupAddon
                                        addonType="append"
                                        onClick={() => setHidePassword(!hidePassword)}
                                        style={{
                                            width: '45px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <img
                                            src={iconeye}
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </InputGroupAddon>
                                </InputGroup>

                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        maxWidth: '300px',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <a
                                        onClick={openForgetPassword}
                                        style={{ padding: '4px 12px', cursor: 'pointer' }}
                                    >
                                        <p
                                            style={{
                                                marginBottom: 0,
                                                fontFamily: 'Roboto',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: 'rgba(14, 80, 159, 1)',
                                            }}
                                        >
                                            {lang === 'en' ? 'Forgot password?' : 'Lupa password?'}
                                        </p>
                                    </a>
                                </div>

                                <button
                                    type="button"
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        maxWidth: '300px',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '45px',
                                        backgroundColor:
                                            loginLoading || email === '' || password === ''
                                                ? '#999'
                                                : 'rgba(14, 80, 159, 1)',
                                        color: '#fff',
                                        fontFamily: 'Roboto',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                    disabled={loginLoading}
                                    onClick={handleLogin}
                                >
                                    {lang === 'en' ? 'Login' : 'Masuk'}
                                </button>

                                <div style={{ marginTop: '20px' }}>
                                    <p style={{ fontFamily: 'Roboto', fontSize: '14px' }}>
                                        {lang === 'en'
                                            ? "Don't have account?"
                                            : 'Belum punya akun?'}{' '}
                                        <a
                                            onClick={() => setMode('register')}
                                            style={{
                                                fontWeight: 700,
                                                color: 'rgba(14, 80, 159, 1)',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {lang === 'en' ? 'Register' : 'Daftar'}
                                        </a>
                                    </p>
                                </div>

                                <div
                                    style={{
                                        marginTop: '20px',
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        maxWidth: '300px',
                                    }}
                                >
                                    {/* MASUK DENGAN FACEBOOK DAN GOOGLE */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            width: '100%',
                                            height: '1px',
                                            backgroundColor: '#d5d5d5',
                                        }}
                                    />
                                    <div style={{ padding: '0px 20px' }}>
                                        <p
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontSize: '12px',
                                                marginBottom: 0,
                                            }}
                                        >
                                            {lang === 'en' ? 'or login with' : 'atau masuk dengan'}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            width: '100%',
                                            height: '1px',
                                            backgroundColor: '#d5d5d5',
                                        }}
                                    />
                                </div>

                                <div
                                    style={{
                                        marginTop: '20px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        maxWidth: '300px',
                                    }}
                                >
                                    {/* <GoogleLogin
                                        clientId="392708838847-3iqf3fgjjrr1gjjn94dp2a6n7dsto2nb.apps.googleusercontent.com"
                                        render={(renderProps: any) => {
                                            return (
                                                <button
                                                    type="button"
                                                    onClick={renderProps.onClick}
                                                    style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        border: '1px solid #ddd',
                                                        borderRadius: '4px',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: '45px',
                                                        fontFamily: 'Roboto',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <img
                                                        src={logogoogle}
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            objectFit: 'contain',
                                                            marginRight: '8px',
                                                        }}
                                                    />
                                                    Google
                                                </button>
                                            );
                                        }}
                                        buttonText="Login"
                                        onSuccess={loginWithGoogle}
                                        onFailure={(e: any) => {
                                            console.log(e);
                                        }}
                                        cookiePolicy={'single_host_origin'}
                                    /> */}

                                    {/* <div style={{ width: '20px' }} /> */}

                                    <FacebookLogin
                                        appId="538645993529781"
                                        callback={loginWithFacebook}
                                        render={(renderProps: any) => {
                                            return (
                                                <button
                                                    type="button"
                                                    onClick={renderProps.onClick}
                                                    style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        border: '1px solid #ddd',
                                                        borderRadius: '4px',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: '45px',
                                                        fontFamily: 'Roboto',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <img
                                                        src={logofb}
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            objectFit: 'contain',
                                                            marginRight: '8px',
                                                        }}
                                                    />
                                                    Facebook
                                                </button>
                                            );
                                        }}
                                    />
                                </div>
                            </Form>
                        ) : (
                            <Register
                                lang={lang}
                                setMode={setMode}
                                handleRegister={handleRegister}
                            />
                        )}
                    </BlockUi>
                </Col>
            </div>

            <Navbar {...{ lang, setLang }} />

            <Modal
                isOpen={showModalLogin}
                toggle={() => setShowModalLogin(false)}
                style={{
                    marginTop: '150px',
                }}
            >
                <ModalBody>
                    <p style={{ marginBottom: 0, fontFamily: 'Roboto' }}>{modalText}</p>
                </ModalBody>
                <ModalFooter>
                    <button
                        onClick={() => setShowModalLogin(false)}
                        style={{
                            backgroundColor: '#0e509f',
                            fontFamily: 'Roboto',
                            fontWeight: 700,
                            width: '150px',
                            height: '40px',
                            borderRadius: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#fff',
                            textDecoration: 'none',
                        }}
                    >
                        OK
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
