import React from 'react';
import { Container, Row, Col, Table } from 'reactstrap';

interface IProductSpecProps {
    logo: any;
    logostyle: any;
    specialColor: string;
    generalSpec: string;
    specialSpec: string;
    specDetails: {
        title: string;
        text: string[];
    }[];
    productimg: any;
    extra1: any;
}

const Description = ({
    logo,
    logostyle,
    generalSpec,
    specialSpec,
    specialColor,
}: Partial<IProductSpecProps>) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                position: 'relative',
            }}
        >
            <div style={{ width: '70%', padding: '40px 40px 0px 40px' }}>
                <img src={logo} style={logostyle} />
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        marginBottom: '20px',
                    }}
                >
                    {generalSpec}
                </p>
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        marginBottom: '40px',
                        fontWeight: 700,
                        color: specialColor,
                    }}
                >
                    {specialSpec}
                </p>
            </div>
        </div>
    );
};

const MobileVer = ({
    logo,
    logostyle,
    specialColor,
    generalSpec,
    specialSpec,
    productimg,
    extra1,
    specDetails,
}: Partial<IProductSpecProps>) => {
    return (
        <div className="d-sm-block d-md-none">
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 40px',
                }}
            >
                <img src={logo} style={logostyle} />
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        marginBottom: '20px',
                    }}
                >
                    {generalSpec}
                </p>
                <p
                    style={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        marginBottom: '40px',
                        fontWeight: 700,
                        color: specialColor,
                    }}
                >
                    {specialSpec}
                </p>
            </div>

            <img
                src={extra1}
                style={{
                    width: 'auto',
                    height: '45px',
                    marginLeft: '-100px',
                    position: 'absolute',
                }}
            />
            <div
                style={{
                    paddingTop: '20px',
                    paddingLeft: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        height: '70px',
                        width: '50%',
                        backgroundColor: specialColor,
                        position: 'absolute',
                        right: 0,
                        bottom: '20px',
                    }}
                />
                <img
                    src={productimg}
                    style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        position: 'relative',
                    }}
                />
            </div>

            <div style={{ backgroundColor: '#efefef' }}>
                <TableSpec {...{ specDetails }} />
            </div>
        </div>
    );
};

export default ({
    logo,
    logostyle,
    specialColor,
    generalSpec,
    specialSpec,
    specDetails,
    productimg,
    extra1,
}: IProductSpecProps) => {
    return (
        <>
            <div className="d-none d-md-block">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            position: 'relative',
                            flexDirection: 'column',
                        }}
                    >
                        <img
                            src={extra1}
                            style={{
                                width: 'auto',
                                height: '45px',
                                marginLeft: '-100px',
                                position: 'absolute',
                            }}
                        />

                        <Description
                            {...{
                                logo,
                                logostyle,
                                specialColor,
                                generalSpec,
                                specialSpec,
                            }}
                        />

                        <div
                            style={{
                                paddingLeft: '110px',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                            }}
                        >
                            <div
                                style={{
                                    height: '70px',
                                    width: '50%',
                                    backgroundColor: specialColor,
                                    position: 'absolute',
                                    right: 0,
                                    top: '40px',
                                }}
                            />
                            <img
                                src={productimg}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'contain',
                                    position: 'relative',
                                }}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            flex: 1,
                            backgroundColor: '#efefef',
                            position: 'relative',
                        }}
                    >
                        <TableSpec {...{ specDetails }} />
                    </div>
                </div>
            </div>

            <MobileVer
                {...{
                    logo,
                    logostyle,
                    specialColor,
                    generalSpec,
                    specialSpec,
                    productimg,
                    extra1,
                    specDetails,
                }}
            />
        </>
    );
};

const TableSpec = ({ specDetails }: Partial<IProductSpecProps>) => {
    if (specDetails) {
        return (
            <Table borderless>
                <tbody>
                    {specDetails.map((spec, index) => {
                        return (
                            <tr
                                key={index}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#f4f4f4' : 'transparent',
                                }}
                            >
                                <th style={{ paddingLeft: '40px' }}>
                                    <p
                                        style={{
                                            fontFamily: 'Roboto Condensed',
                                            fontSize: '15px',
                                            marginBottom: 0,
                                        }}
                                    >
                                        {spec.title}
                                    </p>
                                </th>
                                <td style={{ paddingRight: '40px' }}>
                                    {spec.text.map((t, i) => {
                                        return (
                                            <p
                                                style={{
                                                    fontFamily: 'Roboto',
                                                    fontSize: '14px',
                                                    marginBottom: 0,
                                                }}
                                                key={i}
                                            >
                                                {t}
                                            </p>
                                        );
                                    })}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }

    return null;
};
