import React, { CSSProperties } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Parallax } from 'react-parallax';
import hero from '../../img/hero-bg.png';
import family from '../../img/family.png';
import badge from '../../img/icon-badge.png';
import arrow from '../../img/icon-arrow.png';
import hexa from '../../img/hexa.png';
import mheroimage from '../../img/m-heroimage.png';
import { Link } from 'react-router-dom';
import bpom from '../../img/logo-bpom.png';
import mui from '../../img/logo-mui.png';
import fda from '../../img/logo-fda.png';
import dict from '../../utils/dict';

export const Badges = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <a
                href="http://rekor-leprid.org/index.php/2019/07/17/perusahan-natural-medicine-pertama-di-indonesia-yang-mendapatkan-certificate-fda-registered-facility/"
                target="_blank"
            >
                <img
                    src={badge}
                    style={{
                        width: '5vw',
                        height: '5vw',
                        minWidth: '36px',
                        minHeight: '36px',
                        maxWidth: '50px',
                        maxHeight: '50px',
                        objectFit: 'contain',
                        marginRight: '12px',
                    }}
                    alt="certified"
                />
            </a>
            <img
                src={mui}
                alt="mui"
                style={{
                    width: '5vw',
                    height: '5vw',
                    minWidth: '36px',
                    minHeight: '36px',
                    maxWidth: '50px',
                    maxHeight: '50px',
                    objectFit: 'contain',
                    marginRight: '12px',
                }}
            />
            <img
                src={fda}
                alt="fda"
                style={{
                    width: '5.5vw',
                    height: '5vw',
                    minWidth: '50px',
                    minHeight: '36px',
                    maxWidth: '60px',
                    maxHeight: '50px',
                    objectFit: 'contain',
                    marginRight: '12px',
                }}
            />
            <img
                src={bpom}
                alt="bpom"
                style={{
                    width: '5vw',
                    height: '5vw',
                    minWidth: '36px',
                    minHeight: '36px',
                    maxWidth: '50px',
                    maxHeight: '50px',
                    objectFit: 'contain',
                }}
            />
        </div>
    );
};

const Copy = ({ lang }: { lang: 'en' | 'id' }) => {
    return (
        <div
            style={{
                marginTop: '-3.5vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100vh',
                paddingLeft: '4vw',
            }}
        >
            <p
                style={{
                    color: '#0e509f',
                    fontSize: '1.8em',
                    lineHeight: '1.2em',
                    fontFamily: 'Roboto',
                    fontWeight: 700,
                    marginBottom: 0,
                }}
            >
                {dict.hero_title1[lang]}
            </p>
            <p
                style={{
                    color: '#0e509f',
                    fontSize: '1.8em',
                    lineHeight: '1.2em',
                    fontFamily: 'Roboto',
                    fontWeight: 700,
                    marginBottom: 0,
                }}
            >
                {dict.hero_title2[lang]}
            </p>

            <p style={{ maxWidth: '500px' }}>
                <label
                    style={{
                        color: '#0e509f',
                        fontSize: '3em',
                        fontFamily: 'Roboto',
                        fontWeight: 300,
                        lineHeight: '1em',
                        margin: '16px 0px',
                    }}
                >
                    {dict.hero_tag[lang]}
                </label>
            </p>

            <div style={{ maxWidth: '300px', marginBottom: '12px' }}>
                <div style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '14px' }}>
                    {dict.hero_desc[lang]}
                </div>
            </div>

            <Link to="/about" style={styles.btn}>
                <div style={{ flex: 1 }}>
                    <p style={styles.btn_text}>{dict.btn_learn_more[lang]}</p>
                </div>
                <img src={arrow} style={styles.arrow} />
            </Link>
        </div>
    );
};

export default ({ lang }: { lang: 'en' | 'id' }) => (
    <div style={{ position: 'relative' }}>
        <Parallax blur={0} bgImage={hero} strength={200} className="d-none d-md-block">
            <div style={{ position: 'absolute', top: '95px', width: '100vw', height: '100vh' }}>
                <Container
                    fluid
                    style={{
                        height: '100vh',
                    }}
                >
                    <Row style={{ height: '100vh', position: 'relative' }}>
                        <Col md={12} lg={7}>
                            <Copy lang={lang} />
                        </Col>
                        <Col sm={12} md={{ size: 8, offset: 4 }}>
                            <img
                                src={family}
                                alt="family"
                                style={{
                                    width: '90%',
                                    height: 'auto',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    bottom: '80px',
                                    right: 0,
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ height: '100vh' }} />
            <div
                style={{
                    position: 'absolute',
                    right: '20px',
                    top: '0px',
                }}
            >
                <div style={{ height: '120px' }} />
                <Badges />
            </div>
        </Parallax>

        <div className="d-sm-block d-md-none">
            <div
                style={{
                    backgroundColor: 'white',
                    marginTop: '60px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    padding: '20px',
                }}
            ></div>
            <img
                src={mheroimage}
                style={{ width: '100%', height: 'auto', marginTop: '210px' }}
                alt="heroimage"
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px 20px',
                    position: 'absolute',
                    top: '90px',
                }}
            >
                <div style={{ maxWidth: '70%' }}>
                    <p style={styles.title2}>
                        {dict.hero_title1[lang]} {dict.hero_title2[lang]}
                    </p>
                </div>
                <p style={styles.title_light2}>{dict.hero_tag[lang]}</p>
                <div
                    style={{
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        fontSize: '12px',
                        marginBottom: '12px',
                        maxWidth: '70%',
                    }}
                >
                    {dict.hero_desc[lang]}
                </div>
                <Link to="/about" style={styles.btn}>
                    <div style={{ flex: 1 }}>
                        <p style={styles.btn_text}>{dict.btn_learn_more[lang]}</p>
                    </div>
                    <img src={arrow} style={styles.arrow} />
                </Link>
            </div>

            <div
                style={{
                    position: 'absolute',
                    left: '20px',
                    top: '10px',
                }}
            >
                <div style={{ height: '14px' }} />
                <Badges />
            </div>
        </div>
    </div>
);

const styles: { [x: string]: CSSProperties } = {
    verified: {
        width: '120px',
        height: '120px',
        objectFit: 'contain',
        position: 'absolute',
        top: '111px',
        right: '50px',
    },
    title2: {
        color: '#0e509f',
        fontSize: '1.3em',
        lineHeight: '1.3em',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginBottom: 0,
    },
    title_light2: {
        color: '#0e509f',
        fontSize: '1.9em',
        fontFamily: 'Roboto',
        fontWeight: 300,
        lineHeight: '1em',
        margin: '16px 0px',
    },
    btn: {
        width: '200px',
        height: '50px',
        background: 'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 16px',
    },
    btn_text: {
        color: '#fff',
        fontSize: '15px',
        fontFamily: 'Roboto',
        fontWeight: 500,
        marginBottom: 0,
    },
    arrow: {
        width: '25px',
        height: '20px',
        objectFit: 'contain',
    },
};
