import React, { CSSProperties, useCallback, useState, useEffect } from 'react';
import logo from '../img/logo-nucleus.png';
import logowhite from '../img/logo-nucleus-white.png';
import extra from '../img/navbar-extra-shape.png';
import extra_onoiwa from '../img/navbar-extra-shape-onoiwa.png';
import extra_onogate from '../img/navbar-extra-shape-onogate.png';
import extra_onoake from '../img/navbar-extra-shape-onoake.png';
import extra_rafa from '../img/navbar-extra-shape-rafa.png';
import location from '../img/icon-find-store.png';
// import ask from '../img/icon-ask-expert.png';
import {
    Container,
    Collapse,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import menu from '../img/menu.png';
import menuwhite from '../img/menu-white.png';
import dict from '../utils/dict';
import Cookies from 'js-cookie';
import { logout } from './Login/apiAuth';

interface INavbarProps {
    lang: 'en' | 'id';
    upperColor?: any;
    footerColor?: any;
    mode?: 'normal' | 'reversed';
    productType?: 'onoiwa' | 'onoake' | 'onogate' | 'rafa';
    selected?: string;
    setLang(lang: 'en' | 'id'): void;
}

const MenuSeparator = () => {
    return (
        <>
            <div
                className="d-sm-block d-md-none"
                style={{
                    width: '24px',
                }}
            />
            <div
                className="d-sm-none d-md-block"
                style={{
                    width: '2.5vw',
                }}
            />
            <div
                className="d-sm-none d-md-none d-lg-block"
                style={{
                    width: '2.4vw',
                }}
            />
        </>
    );
};

const getUpperColor = (isSelected: boolean, mode?: 'normal' | 'reversed') => {
    let color = '';

    if (mode === 'reversed') {
        color = isSelected ? '#0e509f' : 'black';
    } else {
        color = isSelected ? '#FFE179' : 'white';
    }

    return color;
};

const getColor = (isSelected: boolean, mode?: 'normal' | 'reversed') => {
    let color = '';

    if (mode === 'reversed') {
        color = isSelected ? '#FFE179' : 'white';
    } else {
        color = isSelected ? '#0e509f' : 'black';
    }

    return color;
};

const UpperSection = ({ lang, setLang, upperColor, productType, mode }: Partial<INavbarProps>) => {
    let cookies: any = Cookies.get('auth');
    if (cookies) cookies = JSON.parse(cookies);
    console.log(cookies);

    const [openLogout, setOpenLogout] = useState(false);
    const handleLogout = useCallback(() => {
        logout(cookies.accessToken);
        Cookies.remove('auth');
    }, [cookies]);

    return (
        <div
            style={{
                background:
                    upperColor ||
                    'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
            }}
            className="d-none d-md-block"
        >
            <Container fluid style={styles.upper_container}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '30px',
                        paddingRight: '12px',
                    }}
                >
                    {cookies && cookies.profile ? (
                        // <a style={{ color: getUpperColor(true, mode), ...styles.nav_button }}>
                        //     {cookies.profile.toUpperCase()}
                        // </a>
                        <ButtonDropdown
                            isOpen={openLogout}
                            toggle={() => setOpenLogout(!openLogout)}
                        >
                            <DropdownToggle
                                caret
                                style={{
                                    color: getUpperColor(true, mode),
                                    border: 'none',
                                    height: '30px',
                                    fontSize: '12px',
                                    textDecoration: 'none',
                                    padding: '0px 4px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontFamily: 'Roboto Condensed',
                                    fontWeight: 700,
                                    cursor: 'pointer',
                                    backgroundColor: 'transparent',
                                }}
                            >
                                {cookies.profile.toUpperCase()}
                            </DropdownToggle>
                            <DropdownMenu style={{ padding: 0 }}>
                                <DropdownItem
                                    header
                                    onClick={handleLogout}
                                    style={{
                                        backgroundColor: '#de391d',
                                        fontFamily: 'Roboto-Condensed',
                                        fontWeight: 700,
                                        color: '#fff',
                                        cursor: 'pointer',
                                        padding: '16px 12px',
                                    }}
                                >
                                    {lang === 'en' ? 'LOG OUT' : 'KELUAR'}
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    ) : (
                        <a
                            href="/login"
                            style={{ color: getUpperColor(true, mode), ...styles.nav_button }}
                        >
                            {dict.menu_sign_in[lang!]}
                        </a>
                    )}
                    <div style={{ width: '16px' }} />
                    <a
                        onClick={() => setLang!('en')}
                        style={{
                            color: getUpperColor(lang === 'en', mode),
                            ...styles.nav_button,
                        }}
                    >
                        ENG
                    </a>
                    <a
                        onClick={() => setLang!('id')}
                        style={{
                            color: getUpperColor(lang === 'id', mode),
                            ...styles.nav_button,
                        }}
                    >
                        ID
                    </a>
                </div>
            </Container>

            <div style={{ position: 'absolute', top: 7, width: '25vw' }}>
                <img
                    src={
                        productType === 'onoiwa'
                            ? extra_onoiwa
                            : productType === 'onogate'
                            ? extra_onogate
                            : productType === 'onoake'
                            ? extra_onoake
                            : productType === 'rafa'
                            ? extra_rafa
                            : extra
                    }
                    alt="extra"
                    style={{
                        height: '20px',
                        width: '80%',
                        minWidth: '190px',
                        maxWidth: '250px',
                    }}
                />
            </div>
        </div>
    );
};

const MobileMainSection = ({
    lang,
    setLang,
    toggleNav,
    mode,
    showNav,
    setShowNav,
    footerColor,
}: Partial<INavbarProps> & { toggleNav: any; showNav: any; setShowNav: any }) => {
    let cookies: any = Cookies.get('auth');
    if (cookies) cookies = JSON.parse(cookies);

    const handleLogout = useCallback(() => {
        const token = cookies.accessToken;
        Cookies.remove('auth');
        if (showNav) setShowNav(false);
        logout(token);
    }, []);

    return (
        <>
            <div
                className="d-sm-block d-md-none"
                style={{
                    position: 'relative',
                    WebkitBoxShadow: '0px 10px 29px 0px rgba(0,0,0,0.1)',
                    MozBoxShadow: '0px 10px 29px 0px rgba(0,0,0,0.1)',
                    boxShadow: '0px 10px 29px 0px rgba(0,0,0,0.1)',
                    paddingLeft: '2vw',
                    paddingRight: '2vw',
                    backgroundColor: footerColor || '#fff',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <div
                        onClick={toggleNav}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '60px',
                            height: '60px',
                            cursor: 'pointer',
                            marginLeft: '-8px',
                        }}
                    >
                        <img
                            src={mode === 'reversed' ? menuwhite : menu}
                            alt="hamburger"
                            style={{
                                width: '28px',
                                height: '28px',
                                objectFit: 'contain',
                                cursor: 'pointer',
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            padding: '0px 8px',
                        }}
                    >
                        <Link to="/">
                            <img
                                src={mode === 'reversed' ? logowhite : logo}
                                alt="logo"
                                style={{
                                    maxWidth: '130px',
                                    width: '20vw',
                                    minWidth: '110px',
                                    height: '60px',
                                    objectFit: 'contain',
                                }}
                            />
                        </Link>
                    </div>

                    {!showNav ? (
                        <Link
                            to="/store"
                            style={{
                                height: '60px',
                                width: '10vw',
                                minWidth: '80px',
                                maxWidth: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#e9bc1d',
                                textDecoration: 'none',
                            }}
                        >
                            <img
                                src={location}
                                alt="location"
                                style={{
                                    width: '36%',
                                    height: '36%x',
                                    objectFit: 'contain',
                                    marginBottom: '4px',
                                }}
                            />
                            <label style={styles.side_title}>{dict.menu_find_store[lang!]}</label>
                        </Link>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '60px',
                                paddingRight: '12px',
                                overflow: 'hidden',
                            }}
                        >
                            {cookies && cookies.profile ? (
                                <a
                                    style={{
                                        color: mode === 'reversed' ? '#e9bc1d' : '#0e509f',
                                        height: '60px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontFamily: 'Roboto Condensed',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        paddingTop: '4px',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                        textAlign: 'center',
                                    }}
                                >
                                    {cookies.profile.toUpperCase()}
                                </a>
                            ) : (
                                <a
                                    href="/login"
                                    style={{
                                        color: mode === 'reversed' ? '#e9bc1d' : '#0e509f',
                                        height: '60px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontFamily: 'Roboto Condensed',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        lineHeight: '15px',
                                        paddingTop: '4px',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                        textAlign: 'center',
                                    }}
                                >
                                    {dict.menu_sign_in[lang!]}
                                </a>
                            )}
                            <div style={{ width: '16px' }} />
                            <a
                                onClick={() => setLang!('en')}
                                style={{
                                    color:
                                        mode === 'reversed'
                                            ? lang === 'en'
                                                ? '#fff'
                                                : '#e9bc1d'
                                            : lang === 'en'
                                            ? '#000'
                                            : '#0e509f',
                                    height: '60px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Roboto Condensed',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    paddingTop: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                ENG
                            </a>
                            <div style={{ width: '8px' }} />
                            <a
                                onClick={() => setLang!('id')}
                                style={{
                                    color:
                                        mode === 'reversed'
                                            ? lang === 'id'
                                                ? '#fff'
                                                : '#e9bc1d'
                                            : lang === 'id'
                                            ? '#000'
                                            : '#0e509f',
                                    height: '60px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Roboto Condensed',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    paddingTop: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                ID
                            </a>
                        </div>
                    )}
                </div>
            </div>

            <Collapse isOpen={showNav} navbar>
                <div
                    onClick={() => setShowNav(false)}
                    style={{
                        backgroundColor: '#0006',
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute',
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        width: '100vw',
                        backgroundColor: '#fff',
                        padding: '0px 20px',
                        overflow: 'auto',
                    }}
                >
                    <Link to="/" onClick={toggleNav} style={styles.collapse_item}>
                        {dict.menu_home[lang!]}
                    </Link>

                    <div style={{ height: '1px', backgroundColor: '#d5d5d5' }} />

                    <Link
                        to="/prescription"
                        onClick={toggleNav}
                        style={{
                            fontFamily: 'Roboto Condensed',
                            fontWeight: 700,
                            fontSize: '12px',
                            marginTop: '16px',
                            marginBottom: '4px',
                            color: '#aaa',
                        }}
                    >
                        PRESCRIPTION
                    </Link>

                    <Link to="/onoiwa" onClick={toggleNav} style={styles.collapse_item}>
                        ONOIWA
                    </Link>

                    <div style={{ height: '1px', backgroundColor: '#f5f5f5' }} />

                    <Link to="/onogate" onClick={toggleNav} style={styles.collapse_item}>
                        ONOGATE
                    </Link>

                    <div style={{ height: '1px', backgroundColor: '#d5d5d5' }} />

                    <Link
                        to="/otc"
                        onClick={toggleNav}
                        style={{
                            fontFamily: 'Roboto Condensed',
                            fontWeight: 700,
                            fontSize: '12px',
                            marginTop: '16px',
                            marginBottom: '4px',
                            color: '#aaa',
                        }}
                    >
                        CONSUMER HEALTH (OTC)
                    </Link>

                    <Link to="/onoake" onClick={toggleNav} style={styles.collapse_item}>
                        ONOAKE
                    </Link>

                    <div style={{ height: '1px', backgroundColor: '#f5f5f5' }} />

                    <Link to="/rafa-khomsah" onClick={toggleNav} style={styles.collapse_item}>
                        RAFA KHOMSAH
                    </Link>

                    <div style={{ height: '1px', backgroundColor: '#d5d5d5' }} />

                    <Link to="/about" onClick={toggleNav} style={styles.collapse_item}>
                        ABOUT US
                    </Link>

                    <div style={{ height: '1px', backgroundColor: '#d5d5d5' }} />

                    <Link to="/contact" onClick={toggleNav} style={styles.collapse_item}>
                        {dict.menu_contact[lang!]}
                    </Link>

                    <div style={{ height: '1px', backgroundColor: '#d5d5d5' }} />

                    <Link to="/blog" onClick={toggleNav} style={styles.collapse_item}>
                        {dict.menu_blog[lang!]}
                    </Link>

                    <div style={{ height: '1px', backgroundColor: '#d5d5d5' }} />

                    <Link to="#" onClick={toggleNav} style={styles.collapse_item}>
                        {dict.menu_find_store[lang!]}
                    </Link>

                    {cookies && cookies.profile && (
                        <>
                            <div style={{ height: '1px', backgroundColor: '#d5d5d5' }} />

                            <button
                                type="button"
                                onClick={handleLogout}
                                style={{
                                    height: '45px',
                                    border: 'none',
                                    borderRadius: '4px',
                                    marginBottom: '8px',
                                    color: '#de391d',
                                    fontFamily: 'Roboto-Condensed',
                                    fontSize: '14px',
                                    width: '200px',
                                    textAlign: 'left',
                                    padding: '0px',
                                }}
                            >
                                {lang === 'en' ? 'LOG OUT' : 'KELUAR'}
                            </button>
                        </>
                    )}
                </div>
            </Collapse>
        </>
    );
};

const WebMainSection = ({
    mode,
    selected,
    lang,
    setShowPrescription,
    setShowOtc,
    setShowNutrition,
    showPrescription,
    showOtc,
    footerColor,
}: Partial<INavbarProps> & {
    setShowPrescription: any;
    setShowOtc: any;
    setShowNutrition: any;
    showPrescription: any;
    showOtc: any;
}) => {
    const [productDropdownOpen, setProductDropdownOpen] = useState(false);

    const productMouseEnter = useCallback(() => {
        setProductDropdownOpen(true);
    }, [productDropdownOpen]);

    const productMouseLeave = useCallback(() => {
        setProductDropdownOpen(false);
        setShowPrescription(false);
        setShowOtc(false);
        setShowNutrition(false);
    }, [productDropdownOpen, showPrescription, showOtc]);

    const toggleProductDropdown = useCallback(() => {
        setProductDropdownOpen(!productDropdownOpen);
    }, [productDropdownOpen]);

    return (
        <Container
            className="d-none d-md-block"
            fluid
            style={{
                backgroundColor: footerColor || '#fff',
                position: 'relative',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingRight: '12px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <div style={{ paddingLeft: '1.5vw' }}>
                        <Link to="/">
                            <img
                                src={mode === 'reversed' ? logowhite : logo}
                                alt="logo"
                                style={{ ...styles.logo, height: '70px' }}
                            />
                        </Link>
                    </div>

                    <div
                        className="d-md-block d-lg-none"
                        style={{ width: '5vw', maxWidth: '24px' }}
                    />
                    <div className="d-md-none d-lg-block" style={{ width: '5vw' }} />

                    <Link
                        to="/"
                        style={{
                            color: getColor(selected === 'home', mode),
                            ...styles.main_menu,
                        }}
                    >
                        {dict.menu_home[lang!]}
                    </Link>

                    <MenuSeparator />

                    <Link
                        style={{
                            color: getColor(selected === 'products', mode),
                            ...styles.main_menu,
                            position: 'relative',
                        }}
                        onClick={productMouseLeave}
                        onMouseEnter={productMouseEnter}
                        onMouseLeave={productMouseLeave}
                        to="/products"
                    >
                        {dict.menu_products[lang!]}
                        {productDropdownOpen && (
                            <div style={styles.drop_container}>
                                <Link
                                    to="/prescription"
                                    onClick={productMouseLeave}
                                    style={styles.drop_item}
                                    onMouseEnter={() => setShowPrescription(true)}
                                >
                                    PRESCRIPTION
                                </Link>
                                <div style={{ height: '1px', backgroundColor: '#0001' }} />
                                <Link
                                    to="/otc"
                                    onClick={productMouseLeave}
                                    style={styles.drop_item}
                                    onMouseEnter={() => setShowOtc(true)}
                                >
                                    {'CONSUMER HEALTH (OTC)'}
                                </Link>
                                {/* <div style={{ height: '1px', backgroundColor: '#0001' }} />
                                    <Link
                                        to="/nutrition"
                                        onClick={productMouseLeave}
                                        style={styles.drop_item}
                                        onMouseEnter={() => setShowNutrition(true)}
                                    >
                                        {'NUTRITION (VITAMIN)'}
                                    </Link> */}
                            </div>
                        )}
                        {showPrescription && (
                            <div style={{ ...styles.submenu, top: '65px' }}>
                                <Link
                                    to="/onoiwa"
                                    onClick={productMouseLeave}
                                    style={styles.drop_item}
                                >
                                    ONOIWA
                                </Link>
                                <div style={{ height: '1px', backgroundColor: '#0001' }} />
                                <Link
                                    to="/onogate"
                                    onClick={productMouseLeave}
                                    style={styles.drop_item}
                                >
                                    ONOGATE
                                </Link>
                            </div>
                        )}
                        {showOtc && (
                            <div style={{ ...styles.submenu, top: '105px' }}>
                                <Link
                                    to="/onoake"
                                    onClick={productMouseLeave}
                                    style={styles.drop_item}
                                >
                                    ONOAKE
                                </Link>
                                <div style={{ height: '1px', backgroundColor: '#0001' }} />
                                {/* <Link
                                        to="/obatkuat"
                                        onClick={productMouseLeave}
                                        style={styles.drop_item}
                                    >
                                        OBAT KUAT
                                    </Link>
                                    <div style={{ height: '1px', backgroundColor: '#0001' }} /> */}
                                <Link
                                    to="/rafa-khomsah"
                                    onClick={productMouseLeave}
                                    style={styles.drop_item}
                                >
                                    RAFA KHOMSAH
                                </Link>
                            </div>
                        )}
                    </Link>

                    <MenuSeparator />

                    <Link
                        to="/about"
                        style={{
                            color: getColor(selected === 'about', mode),
                            ...styles.main_menu,
                        }}
                    >
                        {dict.menu_about[lang!]}
                    </Link>

                    <MenuSeparator />

                    <Link
                        to="/contact"
                        style={{
                            color: getColor(selected === 'contact', mode),
                            ...styles.main_menu,
                        }}
                    >
                        {dict.menu_contact[lang!]}
                    </Link>

                    <MenuSeparator />

                    <Link
                        to="/blog"
                        style={{
                            color: getColor(selected === 'blog', mode),
                            ...styles.main_menu,
                        }}
                    >
                        {dict.menu_blog[lang!]}
                    </Link>
                </div>

                <Link to="/store" style={styles.find_store_container}>
                    <img
                        src={location}
                        alt="location"
                        style={{
                            width: '36%',
                            height: '36%',
                            objectFit: 'contain',
                            marginBottom: '4px',
                        }}
                    />
                    <label style={styles.side_title}>{dict.menu_find_store[lang!]}</label>
                </Link>

                {/* <Link to="/ask-expert" style={styles.ask_expert_container}>
                    <img
                        src={ask}
                        alt="ask-expert"
                        style={{
                            width: '26%',
                            height: '26%x',
                            objectFit: 'contain',
                            marginBottom: '4px',
                        }}
                    />
                    <label style={styles.side_title}>ASK AN EXPERT</label>
                </Link> */}
            </div>
        </Container>
    );
};

export default ({
    lang,
    setLang,
    upperColor,
    footerColor,
    mode,
    productType,
    selected,
}: INavbarProps) => {
    const [showNav, setShowNav] = useState(false);

    const [showPrescription, setShowPrescription] = useState(false);
    const [showOtc, setShowOtc] = useState(false);
    const [showNutrition, setShowNutrition] = useState(false);

    useEffect(() => {
        if (showPrescription) {
            if (showOtc) setShowOtc(false);
            if (showNutrition) setShowNutrition(false);
        }
    }, [showPrescription]);

    useEffect(() => {
        if (showOtc) {
            if (showPrescription) setShowPrescription(false);
            if (showNutrition) setShowNutrition(false);
        }
    }, [showOtc]);

    useEffect(() => {
        if (showNutrition) {
            if (showPrescription) setShowPrescription(false);
            if (showOtc) setShowOtc(false);
        }
    }, [showNutrition]);

    const toggleNav = useCallback(() => {
        setShowNav(!showNav);
    }, [showNav]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                top: 0,
                width: '100vw',
                zIndex: 9999,
            }}
        >
            <UpperSection {...{ lang, setLang, upperColor, productType, mode }} />

            <MobileMainSection
                {...{
                    mode,
                    toggleNav,
                    lang,
                    showNav,
                    setShowNav,
                    setLang,
                    footerColor,
                }}
            />

            <WebMainSection
                {...{
                    footerColor,
                    mode,
                    selected,
                    showPrescription,
                    showOtc,
                    setShowPrescription,
                    setShowOtc,
                    setShowNutrition,
                    lang,
                }}
            />
        </div>
    );
};

const styles: { [x: string]: CSSProperties } = {
    collapse_item: {
        height: '50px',
        fontFamily: 'Roboto Condensed',
        fontWeight: 500,
        fontSize: '14px',
        color: '#000',
        textDecoration: 'none',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    side_title: {
        color: 'white',
        fontSize: '10px',
        fontFamily: 'Roboto Condensed',
        fontWeight: 700,
        marginBottom: '0px',
        textAlign: 'center',
    },
    find_store_container: {
        height: '66px',
        width: '10vw',
        minWidth: '75px',
        maxWidth: '110px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#e9bc1d',
        textDecoration: 'none',
    },
    ask_expert_container: {
        height: '66px',
        width: '10vw',
        minWidth: '75px',
        maxWidth: '110px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#17cde4',
        textDecoration: 'none',
    },
    drop_container: {
        width: '180px',
        borderWidth: '3px',
        backgroundColor: '#e9bc1d',
        padding: '0px 12px',
        position: 'absolute',
        top: '65px',
        left: 0,
        WebkitBoxShadow: '0px 0px 20px -10px rgba(0,0,0,0.5)',
        MozBoxShadow: '0px 0px 20px -10px rgba(0,0,0,0.5)',
        boxShadow: '0px 0px 20px -10px rgba(0,0,0,0.5)',
    },
    drop_item: {
        width: '180px',
        height: '40px',
        fontFamily: 'Roboto Condensed',
        fontWeight: 700,
        fontSize: '12px',
        color: '#fff',
        textDecoration: 'none',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    nav_button: {
        height: '30px',
        fontSize: '12px',
        textDecoration: 'none',
        padding: '0px 4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontFamily: 'Roboto Condensed',
        fontWeight: 700,
        cursor: 'pointer',
    },
    logo: {
        maxWidth: '150px',
        width: '25vw',
        minWidth: '130px',
        objectFit: 'contain',
        marginTop: '-4px',
    },
    upper_container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
    },
    main_menu: {
        height: '65px',
        fontSize: '15px',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontFamily: 'Roboto Condensed',
        fontWeight: 700,
        cursor: 'pointer',
        paddingTop: '12px',
    },
    submenu: {
        width: '180px',
        borderWidth: '3px',
        backgroundColor: '#d69c00',
        padding: '0px 12px',
        position: 'absolute',
        left: '180px',
        WebkitBoxShadow: '0px 0px 20px -10px rgba(0,0,0,0.5)',
        MozBoxShadow: '0px 0px 20px -10px rgba(0,0,0,0.5)',
        boxShadow: '0px 0px 20px -10px rgba(0,0,0,0.5)',
    },
};
