import React, { CSSProperties, useState, useCallback, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Spinner,
} from 'reactstrap';
import blogbg from '../../img/blog-bg.png';
import mblogbg from '../../img/m-blog-bg.png';
import blogbgjournal from '../../img/bg-blog-journal.png';
import blogbghealthy from '../../img/bg-blog-healthy.png';
import iconsearch from '../../img/icon-search.png';
import authoravatar from '../../img/author-avatar.png';

import blog1 from '../../img/blog-thumb.jpeg';
import blog2 from '../../img/blog-2.png';
import blog3 from '../../img/blog-3.png';
import popular1 from '../../img/popular-1.png';
import popular2 from '../../img/popular-2.png';
import popular3 from '../../img/popular-3.png';
import Footer from '../Home/Footer';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar';
import dict from '../../utils/dict';
import { getPosts } from './apiBlog';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import BlogPostItem from './BlogPostItem';

export const popular_posts = [
    // {
    //     id: '0',
    //     tag_id: 1,
    //     title: 'In-depth Analysis Benefits of Channa Striata',
    //     datetime: '30 min ago',
    //     thumbnail: popular1,
    // },
    // {
    //     id: '1',
    //     tag_id: 1,
    //     title: '11 Ways To Prevent Cancer Without Big Costs',
    //     datetime: '30 min ago',
    //     thumbnail: popular2,
    // },
    // {
    //     id: '2',
    //     tag_id: 0,
    //     title: 'In-depth Analysis Benefits of Channa Striata',
    //     datetime: '30 min ago',
    //     thumbnail: popular3,
    // },
];

export default ({
    lang,
    setLang,
    isLoggedIn,
    settings,
}: {
    lang: 'en' | 'id';
    setLang: any;
    isLoggedIn: any;
    settings: any;
}) => {
    const [display, setDisplay] = useState<'default' | 'journal' | 'healthy'>('default');
    const [posts, setPosts] = useState<any>([
        // {
        //     id: '0',
        //     tag_id: 1,
        //     title: {
        //         en: 'Snakehead Fish & Albumin',
        //         id: 'Ikan Gabus dan Albumin',
        //     },
        //     short_desc: {
        //         en:
        //             'Snakehead or cork fish with the scientific name Channa striata is a predatory fish that live in freshwater...',
        //         id:
        //             'Ikan gabus dengan nama ilmiah Channa striata merupakan ikan predator yang hidup di air tawar...',
        //     },
        //     author: {
        //         avatar: authoravatar,
        //         name: 'dr. John Doe',
        //         datetime: 'Nov 12, 2019 - 14:00',
        //     },
        //     comments: [1, 2],
        //     share_link: '',
        //     thumbnail: blog1,
        // },
    ]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getPosts(
            (data) => {
                setPosts(data.posts);
                setLoading(false);
            },
            (e) => {
                setLoading(false);
            }
        );
    }, []);

    const handleSearch = useCallback(() => {
        //
    }, []);

    const gotoComment = useCallback((id: string) => {
        //
    }, []);

    const sharePost = useCallback((id: string) => {
        //
    }, []);

    const openBlogDetail = useCallback((id: string) => {
        //
    }, []);

    return (
        <>
            <div style={{ height: '100vh', position: 'relative' }}>
                <img
                    className="d-none d-md-block"
                    src={
                        display === 'default'
                            ? blogbg
                            : display === 'healthy'
                            ? blogbghealthy
                            : blogbgjournal
                    }
                    alt="background"
                    style={{
                        width: '100%',
                        height: '100vh',
                        objectFit: 'cover',
                        objectPosition: '80% 0%',
                    }}
                />

                <img
                    className="d-sm-block d-md-none"
                    src={
                        display === 'default'
                            ? mblogbg
                            : display === 'healthy'
                            ? blogbghealthy
                            : blogbgjournal
                    }
                    alt="background"
                    style={{
                        width: '100%',
                        height: '100vh',
                        objectFit: 'cover',
                    }}
                />

                <div style={{ position: 'absolute', width: '100vw', height: '100vh', top: 0 }}>
                    <div className="d-none d-md-block">
                        <Container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100vh',
                            }}
                        >
                            <Row>
                                <Col md={8} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p
                                        style={{
                                            color: '#0e509f',
                                            marginBottom: 0,
                                            ...styles.title,
                                        }}
                                    >
                                        {lang === 'en'
                                            ? 'Get the latest news'
                                            : 'Dapatkan berita terbaru'}
                                        <br />
                                        {lang === 'en'
                                            ? 'and healthy tips'
                                            : 'dan tips sehat bersama'}
                                        <br />
                                        {lang === 'en' ? 'with Nucleus Farma' : 'Nucleus Farma'}
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="d-sm-block d-md-none">
                        <div
                            style={{
                                color: '#0e509f',
                                fontWeight: 700,
                                fontFamily: 'Roboto',
                                fontSize: '2em',
                                padding: '0px 20px',
                                marginTop: '20vh',
                                lineHeight: '1.3em',
                                maxWidth: '400px',
                            }}
                        >
                            {dict.blog_title[lang]}
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ height: '56px', backgroundColor: '#0695ba' }}>
                <Container>
                    <Row>
                        <Col md={8} style={{ display: 'flex', flexDirection: 'row' }}>
                            {/* <a
                                onClick={() => setDisplay('journal')}
                                style={
                                    display === 'journal' ? styles.tab_menu_active : styles.tab_menu
                                }
                            >
                                <p style={styles.tab_text}>Journal & Research</p>
                            </a>
                            <div style={{ width: '28px' }} /> */}
                            <a
                                onClick={() => setDisplay('healthy')}
                                style={
                                    display === 'healthy' ? styles.tab_menu_active : styles.tab_menu
                                }
                            >
                                <p style={styles.tab_text}>{dict.healthy_tips[lang]}</p>
                            </a>
                        </Col>

                        {/* <Col md={4}>
                            <div style={styles.search_container}>
                                <Input
                                    placeholder={dict.search_articles[lang]}
                                    style={styles.input}
                                />
                                <a onClick={handleSearch} style={styles.btn_search}>
                                    <img src={iconsearch} style={styles.icon_search} />
                                </a>
                            </div>
                        </Col> */}
                    </Row>

                    <Row style={{ marginTop: '40px' }}>
                        <Col
                            md={7}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '60px',
                            }}
                        >
                            {display !== 'default' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '5px',
                                            height: '20px',
                                            backgroundColor: '#0e9f26',
                                            marginRight: '6px',
                                        }}
                                    />
                                    <p style={styles.popular_title}>
                                        {display === 'journal'
                                            ? 'Journal & Research'
                                            : dict.healthy_tips[lang]}
                                    </p>
                                </div>
                            )}

                            {loading && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Spinner size="sm" color="secondary" />
                                    <p
                                        style={{
                                            marginLeft: '12px',
                                            fontFamily: 'Roboto',
                                            fontSize: '16px',
                                            marginBottom: 0,
                                        }}
                                    >
                                        {lang === 'en' ? 'Loading...' : 'Memuat...'}
                                    </p>
                                </div>
                            )}

                            {posts.map((item: any, index: number) => (
                                <div key={index} style={{ maxWidth: '550px' }}>
                                    {index !== 0 && (
                                        <div
                                            style={{
                                                height: 1,
                                                display: 'flex',
                                                backgroundColor: '#ddd',
                                                margin: '24px 0px',
                                            }}
                                        />
                                    )}
                                    <BlogPostItem item={item} lang={lang} />
                                </div>
                            ))}

                            {/* <div
                                style={{
                                    marginTop: '40px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Pagination>
                                    <PaginationItem>
                                        <PaginationLink previous href="#" />
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink href="#">1</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink href="#">2</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink href="#">3</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink next href="#" />
                                    </PaginationItem>
                                </Pagination>
                            </div> */}
                        </Col>

                        <Col md={{ size: 4, offset: 1 }} style={{ marginBottom: '60px' }}>
                            {/* <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        width: '5px',
                                        height: '20px',
                                        backgroundColor: '#0e509f',
                                        marginRight: '6px',
                                    }}
                                />
                                <p style={styles.popular_title}>Popular Article</p>
                            </div> */}

                            {/* {popular_posts.map((item, index) => (
                                <div key={index} style={styles.popular_post_container}>
                                    <img
                                        src={item.thumbnail}
                                        style={styles.popular_post_thumbnail}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingLeft: '16px',
                                        }}
                                    >
                                        <p style={styles.popular_tag}>
                                            {item.tag_id === 0
                                                ? 'Journal & Research'
                                                : 'Healthy Tips'}
                                        </p>
                                        <Link to={`/blog/${item.id}`} style={styles.popular_post_title}>
                                            {item.title}
                                        </Link>
                                        <p style={styles.popular_post_datetime}>{item.datetime}</p>
                                    </div>
                                </div>
                            ))} */}
                        </Col>
                    </Row>
                </Container>

                <Footer lang={lang} settings={settings} />

                <Navbar {...{ lang, setLang, selected: 'blog' }} />
            </div>
        </>
    );
};

const styles: { [x: string]: CSSProperties } = {
    popular_post_datetime: {
        fontFamily: 'Roboto',
        fontWeight: 300,
        color: '#4e4e4e',
        fontSize: '10px',
    },
    popular_post_title: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '13px',
        marginBottom: '4px',
        lineHeight: '18px',
        color: '#000',
    },
    popular_tag: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        color: '#4e4e4e',
        fontSize: '12px',
        marginBottom: '0px',
    },
    popular_post_thumbnail: {
        width: '82px',
        height: '70px',
        objectFit: 'cover',
    },
    popular_post_container: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '8px',
    },
    popular_title: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '24px',
        marginBottom: 0,
    },
    datetime: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '10px',
        marginBottom: 0,
        color: '#707070',
    },
    author_name: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '12px',
        marginBottom: 0,
        lineHeight: '14px',
    },
    avatar: {
        width: '30px',
        height: '30px',
        borderRadius: 50,
        objectFit: 'cover',
    },
    title: {
        fontSize: '2.5em',
        fontFamily: 'Roboto',
        fontWeight: 500,
        lineHeight: '1.2em',
    },
    tab_menu_active: {
        padding: '0px 20px',
        marginLeft: '-20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '56px',
        textDecoration: 'none',
        cursor: 'pointer',
        backgroundColor: '#086077',
        borderBottomWidth: '2px',
        borderBottomColor: '#e9bc1d',
    },
    tab_menu: {
        padding: '0px 20px',
        marginLeft: '-20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '56px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    tab_text: {
        color: '#fff',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '16px',
        margin: '0px',
    },
    search_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '56px',
    },
    input: {
        borderRadius: 0,
        fontSize: '14px',
        height: '40px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        paddingRight: '80px',
        paddingLeft: '20px',
    },
    btn_search: {
        position: 'absolute',
        right: '15px',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#e9bc1d',
    },
    icon_search: {
        width: '16px',
        height: '16px',
        objectFit: 'contain',
    },
};
