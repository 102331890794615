import React, { CSSProperties, useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Input, Form, Button, Table, Alert, Spinner } from 'reactstrap';
import arrowback from '../../img/icon-arrow-back.png';
import popular1 from '../../img/popular-1.png';
import popular2 from '../../img/popular-2.png';
import popular3 from '../../img/popular-3.png';
import blogcover from '../../img/blog-thumb.jpeg';
import iconcomment from '../../img/icon-comment.png';
import authoravatar from '../../img/author-avatar.png';
import sharefb from '../../img/share-fb.png';
import copylink from '../../img/share-link.png';
import Footer from '../Home/Footer';
import { Link, Router, useParams } from 'react-router-dom';
import dict from '../../utils/dict';
import Navbar from '../Navbar';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getPostDetail, getComments, postComments } from './apiBlog';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

export const popular_posts = [
    // {
    //     id: '0',
    //     tag_id: 1,
    //     title: 'In-depth Analysis Benefits of Channa Striata',
    //     datetime: '30 min ago',
    //     thumbnail: popular1,
    // },
    // {
    //     id: '1',
    //     tag_id: 1,
    //     title: '11 Ways To Prevent Cancer Without Big Costs',
    //     datetime: '30 min ago',
    //     thumbnail: popular2,
    // },
    // {
    //     id: '2',
    //     tag_id: 0,
    //     title: 'In-depth Analysis Benefits of Channa Striata',
    //     datetime: '30 min ago',
    //     thumbnail: popular3,
    // },
];

export default ({
    history,
    lang,
    setLang,
    isLoggedIn,
    settings,
}: {
    history: any;
    lang: 'en' | 'id';
    setLang: any;
    isLoggedIn: any;
    settings: any;
}) => {
    let cookies: any = Cookies.get('auth');
    if (cookies) cookies = JSON.parse(cookies);
    const postId = useParams<any>().id;
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState<any>(null);
    const [commentLoading, setCommentLoading] = useState(false);
    const [comments, setComments] = useState<any[]>([]);
    const [inputComment, setInputComment] = useState('');
    const [postCommentLoading, setPostCommentLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        getPostDetail(
            parseInt(postId, 10),
            (res) => {
                if (res !== 'not_found') {
                    setDetail(res.post);

                    setCommentLoading(true);
                    getComments(
                        parseInt(postId, 10),
                        (obj) => {
                            console.log(obj);
                            setCommentLoading(false);
                            setComments(obj.post_comments);
                        },
                        (e) => {
                            setCommentLoading(false);
                            console.log(e);
                        }
                    );
                }
                setLoading(false);
            },
            (e) => {
                setLoading(false);
            }
        );
    }, []);

    const handlePostComment = useCallback(() => {
        if (inputComment === '') return;

        setPostCommentLoading(true);
        postComments(
            {
                post_id: parseInt(postId, 10),
                comment: inputComment,
            },
            (data) => {
                console.log(data);
                setPostCommentLoading(false);
                setComments([
                    {
                        ...data.post_comment,
                        user: {
                            name: cookies.profile,
                        },
                    },
                    ...comments,
                ]);
            },
            (e) => {
                setPostCommentLoading(false);
                console.log(e);
            }
        );
    }, [[inputComment, comments]]);

    return (
        <>
            <div className="d-none d-md-block" style={{ height: '86px' }} />
            <div className="d-sm-block d-md-none" style={{ height: '56px' }} />

            <div style={{ backgroundColor: '#0695ba' }}>
                <Container>
                    <a
                        onClick={() => history.goBack()}
                        style={{ marginTop: '8px', ...styles.back_button }}
                    >
                        <img
                            src={arrowback}
                            style={{
                                width: '12px',
                                height: '12px',
                                marginRight: '8px',
                            }}
                        />
                        <p style={styles.back_text}>{dict.blog_back[lang]}</p>
                    </a>
                </Container>
            </div>

            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '300px',
                    }}
                >
                    <Spinner size="sm" color="secondary" />
                    <p
                        style={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            marginLeft: '12px',
                            marginBottom: 0,
                        }}
                    >
                        {lang === 'en' ? 'Loading...' : 'Memuat...'}
                    </p>
                </div>
            ) : detail === null ? (
                <Container
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '300px',
                    }}
                >
                    <Alert color="warning">
                        {lang === 'en' ? 'Article not found' : 'Artikel tidak ditemukan'}
                    </Alert>
                </Container>
            ) : (
                        <Container style={{ marginTop: '40px' }}>
                            <Row>
                                <Col
                                    md={popular_posts.length > 0 ? 7 : 12}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: '60px',
                                    }}
                                >
                                    <p style={styles.tag}>{detail.category.name[lang]}</p>
                                    <p style={styles.post_title}>{detail.title[lang]}</p>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* <img src={authoravatar} style={styles.avatar} /> */}
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                // paddingLeft: '8px',
                                            }}
                                        >
                                            <p style={styles.author_name}>{detail.writer}</p>
                                            <p style={styles.datetime}>
                                                {format(
                                                    new Date(detail.published_at),
                                                    'dd MMM yyyy - HH:mm'
                                                )}
                                            </p>
                                        </div>
                                    </div>

                                    <img
                                        // src={blogcover}
                                        src={detail.image_url}
                                        style={{
                                            height: '300px',
                                            objectFit: 'contain',
                                            margin: '30px 0px',
                                            alignSelf: 'center',
                                        }}
                                    />

                                    <div dangerouslySetInnerHTML={{ __html: detail.content[lang] }}></div>

                                    {/* <p>
                                {lang === 'en'
                                    ? 'Snakehead or cork fish with the scientific name Channa striata is a predatory fish that live in freshwater such as lakes, swamps, rivers, and waterways to the rice fields. And if floods hit, snakehead fish can be carried into ponds or ditches around our home. These fish usually prey on various small fish, insects, or other various of aquatic animals including frogs and tadpoles. The fish is identified as the following:'
                                    : 'Ikan gabus dengan nama ilmiah Channa striata merupakan ikan predator yang hidup di air tawar seperti danau, rawa, sungai, dan saluran-saluran air hingga ke sawah-sawah. Dan jika banjir melanda, ikan gabus bisa terbawa ke kolam atau parit sekitar rumah Anda. Ikan ini biasanya memangsa aneka ikan - ikan kecil, serangga, atau berbagai hewan air lain termasuk katak dan berudu. Ikan ini diidentifikasikan sebagai berikut:'}
                            </p>

                            <Table borderless>
                                <tbody>
                                    <tr>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>Kingdom</p>
                                        </th>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>: Animalial</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>Filum</p>
                                        </th>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>: Chordata</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>Kelas</p>
                                        </th>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>: Actinopterygii</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>Ordo</p>
                                        </th>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>: Perciformes</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>Famili</p>
                                        </th>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>: Channidae</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>Genus</p>
                                        </th>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>: Channa</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>Spesies</p>
                                        </th>
                                        <th style={{ padding: 0, fontWeight: 400 }}>
                                            <p style={{ marginBottom: 0 }}>: C. striata</p>
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>

                            <p>
                                {lang === 'en'
                                    ? 'As we know, snakehead fish is often a favorite dish of Indonesian people. Not only because it tastes good, but also because its known as a high nutritional content and very good for our health. In fact, along with the development of technology and science, this fish already processed into many extracts that we can find and buy in pharmacies. So what are the nutrients contained in snakehead fish? Well, based on research conducted by the experts that besides containing high protein, snakehead fish is loaded with high albumin content, as well as vitamins, potassium, calcium, amino acids, and micronutrients such as zinc, selenium, and iron.'
                                    : 'Seperti yang kita ketahui, ikan gabus sering menjadi menu hidangan favorit oleh masyarakat Indonesia. Ternyata tidak hanya karena rasanya yang enak, tetapi juga karena dikenal memiliki jenis kandungan gizi yang tinggi dan sangat baik untuk kesehatan. Bahkan, seiring dengan perkembangan teknologi dan ilmu pengetahuan, ikan ini banyak diolah menjadi ekstrak yang bisa kita temukan dan beli di Apotek. Lalu apa saja nutrisi yang terkandung pada ikan gabus? Nah, berdasarkan penelitian yang dilakukan oleh para ahli, selain mengandung protein yang tinggi, ikan gabus sarat  kandungan albumin yang tinggi, juga vitamin, kalium, kalsium, asam amino, serta mikronutrien seperti zink, selenium, dan zat besi.'}
                            </p>

                            <p>
                                {lang === 'en'
                                    ? "Based on research conducted by the Faculty of Fisheries, Brawijaya University, the results show that the albumin content of snakehead fish is the highest, at 62.24 g/Kg. While the albumin content in other types of fish or meat & eggs, on average only at 10g/Kg. Albumin is a part of blood plasma protein that has many roles such as regulating blood osmotic pressure, maintaining fluid balance in the body, as a carrier of nutrients in the body, antioxidant activity, and helps repair damaged cells in the body. Albumin also has a very important task to pregnant women. Albumin can maintain the growth and development of the fetus in the womb because the need for protein for the fetus can be met from the protein consumed by the mother. If albumin needs fulfilled, then the prospective baby will develop quickly during in the mother's womb."
                                    : 'Berdasarkan riset yang dilakukan Fakultas Perikanan Universitas Brawijaya, hasilnya menunjukan bahwa kandungan albumin pada ikan gabus merupakan yang tertinggi yaitu 62,24 g/Kg. Sedangkan kandungan albumin pada jenis ikan lain atau daging & telur, rata-rata hanya 10g/Kg. Albumin merupakan bagian dari protein plasma darah yang memiliki banyak peran seperti mengatur tekanan osmotik darah, menjaga keseimbangan cairan di dalam tubuh, sebagai pengangkut nutrisi didalam tubuh, aktivitas antioksidan, dan membantu memperbaiki kerusakan jaringan sel di dalam tubuh. Albumin juga memiliki peran yang sangat penting pada ibu hamil. Albumin dapat menjaga tumbuh kembang janin dalam kandungan karena kebutuhan protein untuk janin dapat terpenuhi dari protein yang dikonsumsi ibu. Jika kebutuhan albumin terpenuhi, maka calon bayi akan berkembang cepat selama di dalam kandungan sang ibu.'}
                            </p>

                            <p>
                                {lang === 'en'
                                    ? 'Lack of albumin (hypoalbumin) will make our body feel weak, reduced energy, swelling, and easy to get sick because the nutrients in the body are not well spread throughout the body. Hypoalbumin is a condition in which the albumin level is low or below the normal serum albumin level <3.5 g / dL. Hypoalbumin is usually found in patients with chronic medical conditions such as kidney failure, liver disease, chronic gastrointestinal disease, cancer patients, surgical wounds, burns, inflammation or infection, and diabetes mellitus.'
                                    : 'Kekurangan Albumin (hipoalbumin) akan membuat tubuh terasa lemas, tenaga berkurang, timbul pembengkakan, dan mudah sakit karena nutrisi dalam tubuh tidak tersebar dengan baik ke seluruh tubuh. Hipoalbumin adalah suatu kondisi dimana kadar albuminnya rendah atau di bawah nilai normal kadar albumin serum <3,5 g/dL. Hipoalbumin biasanya ditemukan pada pasien dengan kondisi medis kronis seperti gagal ginjal, penyakit hati, penyakit saluran cerna kronik, penderita kanker, luka akibat pembedahan, luka bakar, radang atau infeksi, dan diabetes melitus.'}
                            </p>

                            <p>
                                {lang === 'en'
                                    ? "Because snakehead fish contain high albumin protein, consuming snakehead fish can help overcome malnutrition. Imagine, as reported by Hello Sehat, in 100 grams of snakehead fish is sufficient to meet all the important nutritional needs that are very good for our health. Snakehead fish is available in the form of fresh fish and dried fish that you can process into various types of dishes according to your taste. Let's consume snakehead fish!"
                                    : 'Karena ikan gabus mengandung protein albumin tinggi, maka mengkonsumsi ikan gabus dapat membantu mengatasi gizi buruk. Bayangkan, seperti dilansir dari Hello Sehat, dalam 100 gram ikan gabus saja sudah cukup lho untuk memenuhi berbagai kebutuhan gizi penting yang sangat baik bagi kesehatan kita. Ikan gabus tersedia dalam bentuk ikan segar dan ikan kering yang bisa Anda olah menjadi berbagai macam jenis masakan sesuai dengan selera Anda. Ayo konsumsi ikan gabus, yuk!'}
                            </p>

                            <p>
                                <b>
                                    {lang === 'en'
                                        ? 'Reference : IEESE International Journal of Science and Technology (IJSTE), Vol. 1 No. 2,  June 2012,1-8  ISSN : 2252-5297'
                                        : 'Referensi : IEESE International Journal of Science and Technology (IJSTE), Vol. 1 No. 2,  June 2012,1-8  ISSN : 2252-5297'}
                                </b>
                            </p> */}

                                    {/* <div style={{ display: 'flex', flexDirection: 'row', margin: '20px 0px' }}>
                            <div
                                style={{
                                    padding: '4px 10px',
                                    backgroundColor: '#ebebeb',
                                    marginRight: '4px',
                                }}
                            >
                                <p style={styles.bottom_tag_text}>Tips</p>
                            </div>
                            <div
                                style={{
                                    padding: '4px 10px',
                                    backgroundColor: '#ebebeb',
                                    marginRight: '4px',
                                }}
                            >
                                <p style={styles.bottom_tag_text}>Pregnant</p>
                            </div>
                            <div
                                style={{
                                    padding: '4px 10px',
                                    backgroundColor: '#ebebeb',
                                    marginRight: '4px',
                                }}
                            >
                                <p style={styles.bottom_tag_text}>Mom</p>
                            </div>
                            <div
                                style={{
                                    padding: '4px 10px',
                                    backgroundColor: '#ebebeb',
                                    marginRight: '4px',
                                }}
                            >
                                <p style={styles.bottom_tag_text}>Caesar</p>
                            </div>
                        </div> */}

                                    <div
                                        style={{
                                            height: '1px',
                                            backgroundColor: '#ddd',
                                            marginBottom: '12px',
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* COMMENT in BLOG */}
                                        {/* <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <img
                                            src={iconcomment}
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                marginRight: '4px',
                                            }}
                                        />
                                        <p style={styles.text_comment}>{dict.comment[lang]}</p>
                                    </div>
                                </div> */}

                                        <FacebookShareButton
                                            url={`https://nucleusfarma.com/blog/${postId}`}
                                        >
                                            <FacebookIcon size={32} round={true} />
                                        </FacebookShareButton>

                                        <div style={{ width: '12px' }} />

                                        {copied ? (
                                            <div
                                                style={{
                                                    height: '30px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: '12px',
                                                        fontFamily: 'Roboto',
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    {lang === 'en' ? 'Copied' : 'Disalin'}
                                                </p>
                                            </div>
                                        ) : (
                                                <CopyToClipboard
                                                    text={`https://nucleusfarma.com/blog/${postId}`}
                                                    onCopy={() => setCopied(true)}
                                                >
                                                    <img
                                                        src={copylink}
                                                        style={{
                                                            height: '30px',
                                                            width: '30px',
                                                            objectFit: 'contain',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </CopyToClipboard>
                                            )}

                                        {/* <a href="https://facebook.com">
                                <img
                                    src={sharefb}
                                    style={{ height: '30px', width: '30px', objectFit: 'contain' }}
                                />
                            </a>
                            <div style={{ width: '4px' }} />
                            <a href="https://facebook.com">
                                <img
                                    src={copylink}
                                    style={{ height: '30px', width: '30px', objectFit: 'contain' }}
                                />
                            </a> */}
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: '20px',
                                            marginTop: '40px',
                                        }}
                                    >
                                        {/* TEXT JUMLAH Comment */}

                                        {/* <div
                                            style={{
                                                width: '5px',
                                                height: '20px',
                                                backgroundColor: '#0e509f',
                                                marginRight: '6px',
                                            }}
                                        /> */}

                                        {/* <p style={styles.popular_title}>
                                            {comments.length} {dict.comment[lang]}
                                        </p> */}
                                    </div>

                                    {cookies && cookies.profile && (
                                        <div style={{ marginBottom: '40px' }}>
                                            <BlockUi tag="div" blocking={postCommentLoading}>
                                                <Form>
                                                    <Input
                                                        type="textarea"
                                                        name="comment"
                                                        id="comment"
                                                        value={inputComment}
                                                        onChange={(e) => setInputComment(e.target.value)}
                                                        placeholder={
                                                            lang === 'en'
                                                                ? 'Write a comment...'
                                                                : 'Tulis komentar...'
                                                        }
                                                        style={styles.input_comment}
                                                    />
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-end',
                                                        }}
                                                    >
                                                        <Button type="button" onClick={handlePostComment}>
                                                            {lang === 'en' ? 'Submit' : 'Kirim'}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </BlockUi>
                                        </div>
                                    )}

                                    {/* COMMENT MAP */}
                                    {/* {comments.map((item, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'row',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <img src={item.photo_profile_url} style={styles.avatar} />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    paddingLeft: '12px',
                                                }}
                                            >
                                                <p style={styles.comment_name}>{item.user.name}</p>
                                                <p style={styles.comment_body}>{item.comment}</p>
                                                <p style={styles.datetime}>
                                                    {format(
                                                        new Date(item.created_at),
                                                        'dd MMM yyyy - hh:mm'
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    ))} */}
                                </Col>

                                {popular_posts.length > 0 && (
                                    <Col md={{ size: 4, offset: 1 }} style={{ marginBottom: '60px' }}>
                                        <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    marginBottom: '20px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '5px',
                                                        height: '20px',
                                                        backgroundColor: '#0e509f',
                                                        marginRight: '6px',
                                                    }}
                                                />
                                                <p style={styles.popular_title}>Related Articles</p>
                                            </div>

                                            {/* {popular_posts.map((item, index) => (
                                    <div key={index} style={styles.popular_post_container}>
                                        <img
                                            src={item.thumbnail}
                                            style={styles.popular_post_thumbnail}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                paddingLeft: '16px',
                                            }}
                                        >
                                            <p style={styles.popular_tag}>
                                                {item.tag_id === 0
                                                    ? 'Journal & Research'
                                                    : 'Healthy Tips'}
                                            </p>
                                            <Link to={`/blog/${item.id}`} style={styles.popular_post_title}>
                                                {item.title}
                                            </Link>
                                            <p style={styles.popular_post_datetime}>
                                                {item.datetime}
                                            </p>
                                        </div>
                                    </div>
                                ))} */}
                                        </>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    )}

            <Footer lang={lang} settings={settings} />
            <Navbar {...{ lang, setLang, selected: 'blog' }} />
        </>
    );
};

const styles: { [x: string]: CSSProperties } = {
    input_comment: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '14px',
        marginBottom: '12px',
    },
    comment_body: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '14px',
        marginBottom: 0,
    },
    comment_name: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '14px',
        marginBottom: 0,
    },
    text_comment: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#707070',
        fontSize: '12px',
        marginBottom: 0,
    },
    bottom_tag_text: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#959595',
        fontSize: '12px',
        marginBottom: 0,
    },
    back_text: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        color: '#fff',
        fontSize: '14px',
        marginBottom: 0,
    },
    back_button: {
        padding: '0px 20px',
        marginLeft: '-20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '56px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    popular_post_datetime: {
        fontFamily: 'Roboto',
        fontWeight: 300,
        color: '#4e4e4e',
        fontSize: '10px',
    },
    popular_post_title: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '13px',
        marginBottom: '4px',
        lineHeight: '18px',
        color: '#000',
    },
    popular_tag: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        color: '#4e4e4e',
        fontSize: '12px',
        marginBottom: '0px',
    },
    popular_post_thumbnail: {
        width: '82px',
        height: '70px',
        objectFit: 'cover',
    },
    popular_post_container: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '8px',
    },
    popular_title: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '24px',
        marginBottom: 0,
    },
    btn_post: {
        width: '36px',
        height: '36px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    datetime: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '10px',
        marginBottom: 0,
        color: '#707070',
    },
    author_name: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '12px',
        marginBottom: 0,
        lineHeight: '14px',
    },
    avatar: {
        width: '30px',
        height: '30px',
        borderRadius: 50,
        objectFit: 'cover',
        backgroundColor: '#eee',
    },
    post_title: {
        fontFamily: 'Roboto-Black',
        fontWeight: 700,
        color: '#000',
        fontSize: '32px',
        lineHeight: '36px',
        marginBottom: '16px',
    },
    tag: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#4e4e4e',
        fontSize: '12px',
        marginBottom: '4px',
    },
};
