import React, { CSSProperties } from 'react';
import aboutusbg from '../../img/bg-about2.png';
import mobilebg from '../../img/mobile-bg-about.png';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../Home/Footer';
import Navbar from '../Navbar';
import dict from '../../utils/dict';

export default ({
    lang,
    setLang,
    isLoggedIn,
    settings,
}: {
    lang: 'en' | 'id';
    setLang: any;
    isLoggedIn: any;
    settings: any;
}) => {
    return (
        <div style={{ position: 'relative' }}>
            <div
                className="d-none d-md-block"
                style={{ marginTop: '80px', height: '55vw', position: 'relative' }}
            >
                <img
                    src={aboutusbg}
                    alt="background"
                    style={{
                        width: '60%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        width: '100vw',
                        top: 0,
                        height: '55vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Container>
                        <Row>
                            <Col
                                md={{ size: 6, offset: 6 }}
                                style={{ display: 'flex', flexDirection: 'column' }}
                            >
                                <div
                                    style={{
                                        width: '40px',
                                        height: '5px',
                                        backgroundColor: '#f2c731',
                                    }}
                                />
                                <span style={styles.title}>{dict.about_1[lang]}:</span>
                                <span style={styles.desc}>{dict.about_1_desc[lang]}</span>

                                <div
                                    style={{
                                        marginTop: '3vw',
                                        width: '40px',
                                        height: '5px',
                                        backgroundColor: '#f2c731',
                                    }}
                                />
                                <span style={styles.title}>{dict.about_2[lang]}:</span>
                                <span style={styles.desc}>{dict.about_2_desc[lang]}</span>

                                <div
                                    style={{
                                        marginTop: '3vw',
                                        width: '40px',
                                        height: '5px',
                                        backgroundColor: '#f2c731',
                                    }}
                                />
                                <span style={styles.title}>{dict.about_3[lang]}:</span>
                                <span style={styles.desc}>
                                    <span style={{ fontWeight: 700 }}>{dict.about_3_1[lang]}:</span>
                                    {` ${dict.about_3_1_desc[lang]}`}
                                </span>
                                <div style={{ height: '12px' }} />
                                <span style={styles.desc}>
                                    <span style={{ fontWeight: 700 }}>{dict.about_3_2[lang]}:</span>
                                    {` ${dict.about_3_2_desc[lang]}`}
                                </span>
                                <div style={{ height: '12px' }} />
                                <span style={styles.desc}>
                                    <span style={{ fontWeight: 700 }}>{dict.about_3_3[lang]}:</span>
                                    {` ${dict.about_3_3_desc[lang]}`}
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className="d-sm-block d-md-none">
                <img
                    src={mobilebg}
                    alt="background"
                    style={{
                        width: '100%',
                        height: 'auto',
                        marginTop: '30px',
                    }}
                />

                <div style={{ marginTop: '-40px', paddingBottom: '40px' }}>
                    <Container style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                            style={{
                                width: '40px',
                                height: '5px',
                                backgroundColor: '#f2c731',
                            }}
                        />
                        <span style={styles.title_mobile}>VISION STATEMENT:</span>
                        <span style={styles.desc_mobile}>
                            To be the preeminent manufacturer of Indonesian natural medicine which
                            applied good manufacturing standard and Halal Assurance System as the
                            manifestation of corporate commitment towards better living in society.
                        </span>

                        <div
                            style={{
                                marginTop: '3vw',
                                width: '40px',
                                height: '5px',
                                backgroundColor: '#f2c731',
                            }}
                        />
                        <span style={styles.title_mobile}>MISSION STATEMENT:</span>
                        <span style={styles.desc_mobile}>
                            Improve people's life by providing the best quality natural healthcare
                            product.
                        </span>

                        <div
                            style={{
                                marginTop: '3vw',
                                width: '40px',
                                height: '5px',
                                backgroundColor: '#f2c731',
                            }}
                        />
                        <span style={styles.title_mobile}>CORPORATE VALUE:</span>
                        <span style={styles.desc_mobile}>
                            <span style={{ fontWeight: 700 }}>{dict.about_3_1[lang]}:</span>
                            {` ${dict.about_3_1_desc[lang]}`}
                        </span>
                        <div style={{ height: '12px' }} />
                        <span style={styles.desc_mobile}>
                            <span style={{ fontWeight: 700 }}>{dict.about_3_2[lang]}:</span>
                            {` ${dict.about_3_2_desc[lang]}`}
                        </span>
                        <div style={{ height: '12px' }} />
                        <span style={styles.desc_mobile}>
                            <span style={{ fontWeight: 700 }}>{dict.about_3_3[lang]}:</span>
                            {` ${dict.about_3_3_desc[lang]}`}
                        </span>
                    </Container>
                </div>
            </div>

            <Footer lang={lang} settings={settings} />

            <Navbar {...{ lang, setLang, selected: 'about' }} />
        </div>
    );
};

const styles: { [x: string]: CSSProperties } = {
    title: {
        fontFamily: 'Roboto-Bold',
        fontWeight: 700,
        color: '#0e509f',
        fontSize: '1.8vw',
    },
    desc: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#000',
        fontSize: '1.3vw',
    },
    title_mobile: {
        fontFamily: 'Roboto-Bold',
        fontWeight: 700,
        color: '#0e509f',
        fontSize: '16px',
    },
    desc_mobile: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#000',
        fontSize: '14px',
    },
};
