import React, { CSSProperties, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
// import testi1 from '../../img/testi1.png';
// import { getTestimonies } from './apiHome';

const Content = ({ data, lang }: { data: any; lang: 'en' | 'id' }) => {
    const [index, setIndex] = useState(0);

    return (
        <Row style={{ display: 'flex' }}>
            <Col
                md={5}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <p
                        className="d-md-none d-lg-block"
                        style={{ ...styles.title, fontSize: '1.5em', lineHeight: '1.5em' }}
                    >
                        {lang === 'en' ? 'What do they say about' : 'Apa kata mereka tentang'}
                    </p>
                    {lang === 'en' ? (
                        <p
                            className="d-md-none d-lg-block"
                            style={{ ...styles.title, fontSize: '1.5em', lineHeight: '1.5em' }}
                        >
                            <span style={{ fontWeight: 700 }}>Nucleus Farma</span> products?
                        </p>
                    ) : (
                        <p
                            className="d-md-none d-lg-block"
                            style={{ ...styles.title, fontSize: '1.5em', lineHeight: '1.5em' }}
                        >
                            Produk <span style={{ fontWeight: 700 }}>Nucleus Farma</span>?
                        </p>
                    )}

                    <p
                        className="d-md-block d-lg-none"
                        style={{ ...styles.title, fontSize: '1.3em', lineHeight: '1.3em' }}
                    >
                        {lang === 'en' ? 'What do they say about' : 'Apa kata mereka tentang'}
                    </p>
                    {lang === 'en' ? (
                        <p
                            className="d-md-block d-lg-none"
                            style={{ ...styles.title, fontSize: '1.3em', lineHeight: '1.3em' }}
                        >
                            <span style={{ fontWeight: 700 }}>Nucleus Farma</span> products?
                        </p>
                    ) : (
                        <p
                            className="d-md-block d-lg-none"
                            style={{ ...styles.title, fontSize: '1.3em', lineHeight: '1.3em' }}
                        >
                            Produk <span style={{ fontWeight: 700 }}>Nucleus Farma</span>?
                        </p>
                    )}
                </div>
            </Col>

            <Col
                md={7}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        width: '50vw',
                        height: '50vw',
                        maxWidth: '480px',
                        maxHeight: '330px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            width: '40vw',
                            height: '40vw',
                            minWidth: '300px',
                            minHeight: '300px',
                            maxWidth: '320px',
                            maxHeight: '320px',
                            backgroundColor: '#f2c731',
                            position: 'absolute',
                        }}
                    />
                    <div
                        style={{
                            width: '40vw',
                            height: '40vw',
                            minWidth: '300px',
                            minHeight: '300px',
                            maxWidth: '320px',
                            maxHeight: '320px',
                            backgroundColor: '#f5f5f5',
                            position: 'absolute',
                            top: '6%',
                            left: '6%',
                        }}
                    >
                        <img
                            src={data[index].image_url}
                            alt="testi"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: '60%',
                            height: '40%',
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            paddingRight: '2%',
                        }}
                    >
                        <p
                            style={{
                                fontFamily: 'Roboto',
                                fontWeight: 400,
                                fontSize: '14px',
                                marginBottom: '12px',
                            }}
                        >
                            {data[index].testimony[lang]}
                        </p>
                        <p
                            style={{
                                fontFamily: 'Roboto',
                                fontWeight: 700,
                                fontSize: '12px',
                                color: 'rgba(14, 80, 159, 1)',
                                marginBottom: 0,
                            }}
                        >
                            {data[index].name.replace(/ .*/, '')} {data[index].age},{' '}
                            {data[index].city}
                        </p>
                    </div>
                </div>
                <div style={{ width: '20px', height: '90px', marginLeft: '12px' }}>
                    {data.map((d: any, i: number) => {
                        return (
                            <div
                                key={i}
                                onClick={() => setIndex(i)}
                                style={index === i ? styles.indicator_active : styles.indicator}
                            />
                        );
                    })}
                </div>
            </Col>
        </Row>
    );
};

export default ({ lang, testimonies }: { lang: 'en' | 'id'; testimonies: any }) => {
    return (
        <>
            <Container
                className="d-md-none d-lg-block"
                style={{
                    marginTop: '1.5vw',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Content data={testimonies} lang={lang} />
            </Container>

            <Container
                className="d-md-block d-lg-none"
                fluid
                style={{
                    marginTop: '5vw',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Content data={testimonies} lang={lang} />
            </Container>
        </>
    );
};

const styles: { [x: string]: CSSProperties } = {
    title: {
        color: 'rgba(14, 80, 159, 1)',
        fontFamily: 'Roboto',
        fontWeight: 300,
        marginBottom: 0,
    },
    indicator: {
        width: '15px',
        height: '15px',
        backgroundColor: '#01c7ce',
        marginBottom: '12px',
        cursor: 'pointer',
    },
    indicator_active: {
        width: '15px',
        height: '15px',
        backgroundColor: '#f2c731',
        marginBottom: '12px',
        cursor: 'pointer',
    },
};
