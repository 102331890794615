import React, { CSSProperties, useState, useEffect } from 'react';
import { Container, Carousel, CarouselItem, Col, Row } from 'reactstrap';
import mountain from '../../img/mountain-bg.png';
import form from '../../img/form.png';
import bpom from '../../img/logo-bpom.png';
import mui from '../../img/logo-mui.png';
import fda from '../../img/logo-fda.png';
import onoake from '../../img/slide-onoake.png';
import onoiwa from '../../img/home-product-onoiwa.png';
import onogate from '../../img/slide-onogate.png';
import rafa from '../../img/slide-rafa.png';
import { Link } from 'react-router-dom';
import arrow from '../../img/icon-arrow.png';
import Testimony from './Testimony';
import ProductSliderMobile from './ProductSliderMobile';
import dict from '../../utils/dict';
import badge from '../../img/icon-badge.png';
import { getTestimonies } from './apiHome';

const products = [
    {
        image: onoiwa,
        link: '/onoiwa',
    },
    {
        image: onogate,
        link: 'onogate',
    },
    {
        image: rafa,
        link: 'rafa-khomsah',
    },
    {
        image: onoake,
        link: 'onoake',
    },
];

const ProductsOverview = ({ lang }: { lang: 'en' | 'id' }) => {
    return (
        <Col
            md={6}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <div className="d-md-none d-lg-block" style={{ ...styles.title, fontSize: '1.8em' }}>
                {dict.home_product_slider_title[lang]}
            </div>
            <div
                className="d-md-block d-lg-none"
                style={{ ...styles.title, fontSize: '1.4em', marginTop: '40px' }}
            >
                {dict.home_product_slider_title[lang]}
            </div>

            {/* <span className="d-md-none d-lg-block" style={{ ...styles.title, fontSize: '1.8em' }}>
                every wellness aspect of your life
            </span>
            <span className="d-md-block d-lg-none" style={{ ...styles.title, fontSize: '1.4em' }}>
                every wellness aspect of your life
            </span> */}

            <div
                style={{
                    margin: '16px 0px',
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '18px',
                }}
            >
                {dict.home_product_slider_desc[lang]}
            </div>
            <span
                style={{
                    color: '#0e509f',
                    fontFamily: 'Roboto',
                    fontWeight: 700,
                    fontSize: '14px',
                    marginBottom: '12px',
                }}
            >
                {dict.home_product_slider_tag[lang]}
            </span>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Link to="/products" style={{ ...styles.btn, height: '50px' }}>
                    <div style={{ flex: 1 }}>
                        <p style={styles.btn_text}>{dict.btn_view_product[lang]}</p>
                    </div>
                    <img src={arrow} style={styles.arrow} />
                </Link>

                <div style={{ width: '30px' }} />

                <img
                    src={badge}
                    style={{
                        width: '3vw',
                        height: '3vw',
                        minWidth: '35px',
                        minHeight: '35px',
                        maxHeight: '45px',
                        maxWidth: '45px',
                        objectFit: 'contain',
                        marginRight: '12px',
                    }}
                    alt="certified"
                />
                <img
                    src={bpom}
                    alt="bpom"
                    style={{
                        width: '3vw',
                        height: '3vw',
                        minWidth: '35px',
                        minHeight: '35px',
                        maxHeight: '45px',
                        maxWidth: '45px',
                        objectFit: 'contain',
                    }}
                />
                <img
                    src={mui}
                    alt="mui"
                    style={{
                        width: '3vw',
                        height: '3vw',
                        minWidth: '35px',
                        minHeight: '35px',
                        maxHeight: '45px',
                        maxWidth: '45px',
                        objectFit: 'contain',
                        margin: '0px 12px',
                    }}
                />
                <img
                    src={fda}
                    alt="fda"
                    style={{
                        width: '3.5vw',
                        height: '3vw',
                        minWidth: '40px',
                        minHeight: '35px',
                        maxHeight: '45px',
                        maxWidth: '50px',
                        objectFit: 'contain',
                    }}
                />
            </div>
        </Col>
    );
};

export default ({ lang, settings }: { lang: 'en' | 'id'; settings: any }) => {
    const [index, setIndex] = useState(0);
    const [testimonies, setTestimonies] = useState<any[]>([]);

    useEffect(() => {
        getTestimonies(
            (data) => {
                setTestimonies(data.testimonies);
            },
            (e) => {}
        );
    }, []);

    return (
        <>
            <div
                style={{
                    height: '60vw',
                    minHeight:
                        !settings.showTestimony || testimonies.length === 0 ? '600px' : '1000px',
                    maxHeight:
                        !settings.showTestimony || testimonies.length === 0 ? '800px' : '1200px',
                    position: 'relative',
                    backgroundColor: '#fff',
                }}
                className="d-none d-md-block"
            >
                <img src={mountain} style={styles.bg} alt="mountain" />
                <img src={form} style={styles.form} alt="decoration" />
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <Container
                        className="d-md-none d-lg-block"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Content index={index} setIndex={setIndex} lang={lang} />
                    </Container>

                    <Container
                        fluid
                        className="d-md-block d-lg-none"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Content index={index} setIndex={setIndex} lang={lang} />
                    </Container>

                    {settings.showTestimony && testimonies.length > 0 && (
                        <Testimony lang={lang} testimonies={testimonies} />
                    )}
                </div>
            </div>

            <ProductSliderMobile
                products={products}
                index={index}
                setIndex={setIndex}
                lang={lang}
            />
        </>
    );
};

const Content = ({
    index,
    setIndex,
    lang,
}: {
    index: number;
    setIndex: (index: number) => void;
    lang: 'en' | 'id';
}) => {
    const slides = products.map((item, index) => {
        return (
            <CarouselItem key={item.link}>
                <Link to={item.link}>
                    <img
                        src={item.image}
                        alt={item.link}
                        style={
                            index === 0
                                ? {
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'contain',
                                  }
                                : styles.product
                        }
                    />
                </Link>
            </CarouselItem>
        );
    });

    return (
        <Row>
            <ProductsOverview lang={lang} />

            <Col
                md={6}
                style={{
                    // height: '400px',
                    marginTop: '-65px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingBottom: '8px',
                        paddingLeft: '20px',
                    }}
                >
                    <div
                        onClick={() => setIndex(0)}
                        style={index === 0 ? styles.indicator_active : styles.indicator}
                    />
                    <div
                        onClick={() => setIndex(1)}
                        style={index === 1 ? styles.indicator_active : styles.indicator}
                    />
                    <div
                        onClick={() => setIndex(2)}
                        style={index === 2 ? styles.indicator_active : styles.indicator}
                    />
                    <div
                        onClick={() => setIndex(3)}
                        style={index === 3 ? styles.indicator_active : styles.indicator}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <Carousel
                        interval={true}
                        activeIndex={index}
                        next={() => {}}
                        previous={() => {}}
                    >
                        {slides}
                    </Carousel>
                </div>
            </Col>
        </Row>
    );
};

const styles: { [x: string]: CSSProperties } = {
    btn: {
        minWidth: '150px',
        width: '80%',
        maxWidth: '200px',
        background: 'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 16px',
        overflow: 'hidden',
    },
    btn_text: {
        color: '#fff',
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 500,
        marginBottom: 0,
    },
    arrow: {
        width: '20px',
        height: '15px',
        objectFit: 'contain',
    },
    product: {
        width: '100%',
        height: '100%',
        maxWidth: '340px',
        maxHeight: '340px',
        objectFit: 'contain',
    },
    indicator: {
        width: '15px',
        height: '15px',
        backgroundColor: '#01c7ce',
        marginRight: '12px',
        cursor: 'pointer',
    },
    indicator_active: {
        width: '15px',
        height: '15px',
        backgroundColor: '#f2c731',
        marginRight: '12px',
        cursor: 'pointer',
    },
    bg: {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        position: 'absolute',
        bottom: 0,
    },
    form: {
        position: 'absolute',
        width: 'auto',
        height: '40%',
        maxHeight: '280px',
        right: 0,
        top: '40px',
    },
    title: {
        // background: 'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
        // WebkitBackgroundClip: 'text',
        // WebkitTextFillColor: 'transparent',
        color: '#0e509f',
        fontFamily: 'Roboto',
        fontWeight: 700,
        lineHeight: '1.2em',
    },
};
