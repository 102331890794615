import { BASE_URL } from './../../constants';

const URL_PRODUCT = `${BASE_URL}/products?item_per_page=999&sort_by=published_at&sort_type=desc`;

export const getProducts = (onSuccess: (data: any) => void, onFailed: (e: any) => void) => {
    fetch(URL_PRODUCT, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};
