import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import iconcomment from '../../img/icon-comment.png';
import iconshare from '../../img/icon-share.png';
import './BlogPost.css';

export default ({ item, lang }: { item: any; lang: 'en' | 'id' }) => {
    return (
        <Link to={`/blog/${item.id}`} style={styles.post_container}>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    paddingRight: '20px',
                }}
            >
                <p style={styles.tag}>{item.category.name[lang]}</p>
                <p style={styles.post_title}>{item.title[lang]}</p>
                <HTMLEllipsis
                    unsafeHTML={item.content[lang]}
                    maxLine="3"
                    ellipsis="..."
                    basedOn="letters"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {/* <img
                            src={item.author.avatar}
                            style={styles.avatar}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingLeft: '8px',
                            }}
                        >
                            <p style={styles.author_name}>
                                {item.author.name}
                            </p>
                            <p style={styles.datetime}>
                                {item.author.datetime}
                            </p>
                        </div> */}
                    </div>


                    {/* ICON COMMENT */}
                    {/* <a
                        // onClick={() => gotoComment(item.id)}
                        style={styles.btn_post}
                    >
                        <img src={iconcomment} style={{ width: '16px', height: '16px' }} />
                    </a> */}

                    {/* ICON SHARE */}
                    {/* <a
                        // onClick={() => sharePost(item.id)}
                        style={styles.btn_post}
                    >
                        <img src={iconshare} style={{ width: '16px', height: '16px' }} />
                    </a> */}

                </div>
            </div>
            <img src={item.image_url} style={styles.blog_thumb} />
        </Link>
    );
};

const styles: { [x: string]: CSSProperties } = {
    post_container: {
        display: 'flex',
        flexDirection: 'row',
        textDecoration: 'none',
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#626262',
        fontSize: '14px',
    },
    btn_post: {
        width: '36px',
        height: '36px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    blog_thumb: {
        width: '150px',
        height: '150px',
        objectFit: 'cover',
    },
    post_title: {
        fontFamily: 'Roboto-Black',
        fontWeight: 700,
        color: '#000',
        fontSize: '24px',
        lineHeight: '28px',
        marginBottom: '8px',
    },
    tag: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#4e4e4e',
        fontSize: '12px',
        marginBottom: '4px',
    },
};
