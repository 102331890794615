import React, { useCallback, useState } from 'react';
import bg from '../../img/contact-bg.png';
import {
    Container,
    Form,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import Footer from '../Home/Footer';
import bgcontact from '../../img/bg-contact.png';
import ContactMap from './ContactMap';
import Navbar from '../Navbar';
import dict from '../../utils/dict';
import { sendMessage } from './apiContact';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

export default ({
    lang,
    setLang,
    isLoggedIn,
    settings,
}: {
    lang: 'en' | 'id';
    setLang: any;
    isLoggedIn: any;
    settings: any;
}) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [textModal, setTextModal] = useState('');
    const [loading, setLoading] = useState(false);

    const submitMessage = useCallback(() => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            setTextModal(
                lang === 'en'
                    ? 'Email is not valid, please input a valid email.'
                    : 'Email tidak benar, tolong masukkan email yang benar.'
            );
            setShowModal(true);
            return;
        }

        setLoading(true);
        sendMessage(
            {
                name,
                email,
                subject,
                mobile: phone,
                country,
                message,
            },
            (res) => {
                setTextModal(
                    lang === 'en' ? 'Your message has been sent.' : 'Pesan Anda berhasil dikirim'
                );
                setShowModal(true);
                setLoading(false);

                setName('');
                setEmail('');
                setSubject('');
                setPhone('');
                setCountry('');
                setMessage('');
            },
            (e) => {
                setLoading(false);
            }
        );
    }, [name, email, subject, phone, country, message, showModal, textModal, loading]);

    const allowSubmit =
        name !== '' &&
        email !== '' &&
        subject !== '' &&
        phone !== '' &&
        country !== '' &&
        message !== '';

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    position: 'relative',
                }}
            >
                <img
                    src={bgcontact}
                    style={{
                        width: '100%',
                        height: '100vh',
                        position: 'absolute',
                        objectFit: 'cover',
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        // top: '56px',
                        width: '100%',
                        height: '100vh',
                        // maxHeight: '800px',
                        // minHeight: '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Container>
                        <Col lg={5} md={8} sm={10} xs={12}>
                            <p
                                className="d-none d-md-block"
                                style={{
                                    background:
                                        'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    fontFamily: 'Roboto',
                                    fontSize: '3.5em',
                                    lineHeight: '1.3em',
                                    fontWeight: 700,
                                    margin: 0,
                                }}
                            >
                                {dict.contact_title[lang]}
                            </p>
                            <p
                                className="d-sm-block d-md-none"
                                style={{
                                    background:
                                        'linear-gradient(90deg, rgba(14, 80, 159, 1) 0%, rgba(1, 199, 206, 1) 100%)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    fontFamily: 'Roboto',
                                    fontSize: '2.8em',
                                    lineHeight: '1.3em',
                                    fontWeight: 700,
                                    // marginBottom: '20px',
                                }}
                            >
                                {dict.contact_title[lang]}
                            </p>
                            {/* <span
                                style={{
                                    fontFamily: 'Roboto',
                                    fontWeight: 400,
                                    fontSize: '1.4em',
                                }}
                            >
                                {dict.contact_desc[lang]}
                            </span> */}
                        </Col>
                    </Container>
                </div>
            </div>

            <Container
                style={{
                    paddingTop: '80px',
                    paddingBottom: '120px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Col sm={{ size: 10, offset: 1 }}>
                    <label
                        style={{
                            fontFamily: 'Roboto',
                            fontWeight: 700,
                            fontSize: '2.5em',
                            marginBottom: '30px',
                        }}
                    >
                        {dict.contact_lets_keep[lang]}
                    </label>

                    <BlockUi tag="div" blocking={loading}>
                        <Form>
                            <FormGroup>
                                <Label
                                    for="name"
                                    style={{
                                        fontFamily: 'Roboto',
                                        fontSize: '13px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {dict.contact_msg_name[lang]}
                                </Label>
                                <Input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    name="name"
                                    id="name"
                                    placeholder={dict.contact_msg_name_placeholder[lang]}
                                    style={{
                                        backgroundColor: '#f5f5f5',
                                        fontFamily: 'Roboto',
                                        fontSize: '14px',
                                        height: '40px',
                                    }}
                                />
                            </FormGroup>

                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label
                                            for="email"
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontSize: '13px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {dict.contact_msg_email[lang]}
                                        </Label>
                                        <Input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder={dict.contact_msg_email_placeholder[lang]}
                                            style={{
                                                backgroundColor: '#f5f5f5',
                                                fontFamily: 'Roboto',
                                                fontSize: '14px',
                                                height: '40px',
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label
                                            for="subject"
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontSize: '13px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {dict.contact_msg_subject[lang]}
                                        </Label>
                                        <Input
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            name="subject"
                                            id="subject"
                                            placeholder={dict.contact_msg_subject_placeholder[lang]}
                                            style={{
                                                backgroundColor: '#f5f5f5',
                                                fontFamily: 'Roboto',
                                                fontSize: '14px',
                                                height: '40px',
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label
                                            for="phone"
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontSize: '13px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {dict.contact_msg_phone[lang]}
                                        </Label>
                                        <Input
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            type="tel"
                                            pattern="[0-9]*"
                                            name="phone"
                                            id="phone"
                                            placeholder={dict.contact_msg_phone_placeholder[lang]}
                                            style={{
                                                backgroundColor: '#f5f5f5',
                                                fontFamily: 'Roboto',
                                                fontSize: '14px',
                                                height: '40px',
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label
                                            for="country"
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontSize: '13px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {dict.contact_msg_country[lang]}
                                        </Label>
                                        <Input
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            name="country"
                                            id="country"
                                            placeholder={dict.contact_msg_country_placeholder[lang]}
                                            style={{
                                                backgroundColor: '#f5f5f5',
                                                fontFamily: 'Roboto',
                                                fontSize: '14px',
                                                height: '40px',
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label
                                    for="message"
                                    style={{
                                        fontFamily: 'Roboto',
                                        fontSize: '13px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {dict.contact_msg_message[lang]}
                                </Label>
                                <Input
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    type="textarea"
                                    name="message"
                                    id="message"
                                    placeholder={dict.contact_msg_message_placeholder[lang]}
                                    style={{
                                        backgroundColor: '#f5f5f5',
                                        fontFamily: 'Roboto',
                                        fontSize: '14px',
                                        height: '150px',
                                    }}
                                />
                            </FormGroup>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <button
                                    type="button"
                                    onClick={submitMessage}
                                    disabled={!allowSubmit || loading}
                                    style={{
                                        padding: '0px 30px',
                                        height: '40px',
                                        borderRadius: '4px',
                                        backgroundColor:
                                            allowSubmit && !loading
                                                ? 'rgba(14, 80, 159, 1)'
                                                : '#aaa',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        color: '#fff',
                                        fontFamily: 'Roboto',
                                        fontSize: '15px',
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {dict.btn_send_message[lang]}
                                </button>
                            </div>
                        </Form>
                    </BlockUi>
                </Col>
            </Container>

            <ContactMap lang={lang} />

            <Footer lang={lang} settings={settings} />

            <Navbar {...{ lang, setLang, selected: 'contact' }} />

            <Modal
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                style={{
                    marginTop: '150px',
                }}
            >
                <ModalBody>
                    <p style={{ marginBottom: 0, fontFamily: 'Roboto' }}>{textModal}</p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            backgroundColor: '#0e509f',
                            fontFamily: 'Roboto',
                            fontWeight: 700,
                        }}
                        onClick={() => setShowModal(false)}
                    >
                        OK
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
