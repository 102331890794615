import React, { CSSProperties } from 'react';
import { Container, Col } from 'reactstrap';
import map from '../../img/map.png';
import facebook_bk from '../../img/facebook-bk.png';
import instagram_bk from '../../img/instagram-bk.png';
import dict from '../../utils/dict';

export default ({ lang }: { lang: 'en' | 'id' }) => {
    return (
        <>
            <div className="d-none d-md-block">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '100px',
                        position: 'relative',
                    }}
                >
                    <div style={{ display: 'flex', flex: 0.3 }}></div>

                    <div
                        style={{
                            display: 'flex',
                            flex: 0.7,
                            backgroundColor: '#17cde4',
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingTop: '40px',
                            paddingBottom: '30px',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                height: '40vw',
                                width: '40vw',
                                marginLeft: '-20vw',
                                position: 'relative',
                            }}
                        >
                            <div
                                style={{
                                    width: '96%',
                                    height: '96%',
                                    backgroundColor: '#e9bc1d',
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                }}
                            />
                            <img
                                src={map}
                                style={{
                                    width: '96%',
                                    height: '96%',
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    left: '4%',
                                }}
                            />
                        </div>

                        <div
                            style={{
                                padding: '60px 80px 80px 80px',
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: '500px',
                            }}
                        >
                            <label style={styles.label}>INDONESIA</label>
                            <span style={styles.text}>
                                Bintaro Jaya Sektor IX, Tangerang Selatan, Banten Indonesia
                            </span>
                            <div style={{ marginBottom: '12px' }} />
                            <label style={styles.label}>JAPAN</label>
                            <span style={styles.text}>
                                3 Chome-9-26 Shimonabe, Higashi-ku, Kumamoto-shi, Kumamoto-ken,
                                Japan
                            </span>
                            <div style={{ marginBottom: '12px' }} />
                            <label style={styles.label}>USA</label>
                            <span style={styles.text}>26 Maple St, Darien, CT 06820, USA</span>
                            <div style={{ marginBottom: '20px' }} />

                            <span style={styles.text}>info@nucleusfarma.com</span>
                            <span style={styles.text}>+62 21 748 61 64 2</span>
                            <div style={{ marginBottom: '20px' }} />

                            <label style={styles.label}>{dict.find_us_socmed[lang]}</label>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <a
                                    href="https://www.facebook.com/Nucleus-Farma-Indonesia-108190173912320/"
                                    target="_blank"
                                >
                                    <img
                                        src={facebook_bk}
                                        style={{
                                            width: '22px',
                                            height: '22px',
                                            objectFit: 'contain',
                                            marginRight: '8px',
                                        }}
                                    />
                                </a>
                                <a href="https://www.instagram.com/nucleusfarma/" target="_blank">
                                    <img
                                        src={instagram_bk}
                                        style={{
                                            width: '22px',
                                            height: '22px',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MobileVersion lang={lang} />
        </>
    );
};

const MobileVersion = ({ lang }: { lang: 'en' | 'id' }) => {
    return (
        <div className="d-sm-block d-md-none">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '40px',
                    position: 'relative',
                    backgroundColor: '#17cde4',
                    paddingBottom: '120px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                }}
            >
                <div
                    style={{
                        width: '60vw',
                        height: '60vw',
                        minHeight: '240px',
                        minWidth: '240px',
                        position: 'relative',
                        marginTop: '-80px',
                        marginBottom: '40px',
                    }}
                >
                    <div
                        style={{
                            width: '96%',
                            height: '96%',
                            backgroundColor: '#e9bc1d',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                        }}
                    />
                    <img
                        src={map}
                        style={{
                            width: '96%',
                            height: '96%',
                            objectFit: 'cover',
                            position: 'absolute',
                            left: '4%',
                        }}
                    />
                </div>

                <label style={styles.label}>INDONESIA</label>
                <span style={{ textAlign: 'center', ...styles.text }}>
                    Bintaro Jaya Sektor IX, Tangerang Selatan, Banten Indonesia
                </span>
                <div style={{ marginBottom: '12px' }} />
                <label style={styles.label}>JAPAN</label>
                <span style={{ textAlign: 'center', ...styles.text }}>
                    3 Chome-9-26 Shimonabe, Higashi-ku, Kumamoto-shi, Kumamoto-ken, Japan
                </span>
                <div style={{ marginBottom: '12px' }} />
                <label style={styles.label}>USA</label>
                <span style={{ textAlign: 'center', ...styles.text }}>
                    26 Maple St, Darien, CT 06820, USA
                </span>
                <div style={{ marginBottom: '20px' }} />

                <span style={styles.text}>info@nucleusfarma.com</span>
                <span style={styles.text}>+62 21 748 61 64 2</span>
                <div style={{ marginBottom: '20px' }} />

                <label style={styles.label}>{dict.find_us_socmed[lang]}</label>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <a
                        href="https://www.facebook.com/Nucleus-Farma-Indonesia-108190173912320/"
                        target="_blank"
                    >
                        <img
                            src={facebook_bk}
                            style={{
                                width: '22px',
                                height: '22px',
                                objectFit: 'contain',
                                marginRight: '8px',
                            }}
                        />
                    </a>
                    <a href="https://www.instagram.com/nucleusfarma/" target="_blank">
                        <img
                            src={instagram_bk}
                            style={{
                                width: '22px',
                                height: '22px',
                                objectFit: 'contain',
                            }}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

const styles: { [x: string]: CSSProperties } = {
    label: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '20px',
        marginBottom: '4px',
        color: '#fff',
    },
    text: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '18px',
        color: '#fff',
    },
};
