import { BASE_URL } from './../../constants';

const URL_TOPICS = `${BASE_URL}/topics`;
const URL_DETAIL = `${BASE_URL}/faqs?item_per_page=99999&topic_id=`;

export const getFaqTopics = (onSuccess: (data: any) => void, onFailed: (e: any) => void) => {
    fetch(URL_TOPICS, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};

export const getFaqDetail = (
    topicId: number,
    onSuccess: (data: any) => void,
    onFailed: (e: any) => void
) => {
    fetch(`${URL_DETAIL}${topicId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};
