import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import arrowup from '../../img/arrow-blue-top.png';
import arrowdown from '../../img/arrow-blue-down.png';

interface IPrivacyCollapsibleProps {
    title: string;
    description: string[];
    noCollapse?: boolean;
}

export default ({ title, description, noCollapse }: IPrivacyCollapsibleProps) => {
    const [isOpen, setIsOpen] = useState(true);

    if (noCollapse) {
        return (
            <div style={{ padding: '20px' }}>
                <p style={styles.title}>{title}</p>
                {description.map((desc, index) => {
                    return (
                        <p style={styles.text} key={index}>
                            {desc}
                        </p>
                    );
                })}
            </div>
        );
    }

    return (
        <div style={{ padding: '20px', borderTop: '1px solid rgba(14, 80, 159, 1)' }}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginBottom: '12px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center',
                        paddingTop: '20px',
                    }}
                >
                    <p style={styles.title}>{title}</p>
                </div>
                <div
                    style={{
                        width: '40px',
                        height: '40px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <img
                        src={isOpen ? arrowup : arrowdown}
                        style={{ width: '14px', height: '14px', objectFit: 'contain' }}
                    />
                </div>
            </div>

            <Collapse isOpen={isOpen}>
                {description.map((desc, index) => {
                    return (
                        <p style={styles.text} key={index}>
                            {desc}
                        </p>
                    );
                })}
            </Collapse>
        </div>
    );
};

const styles = {
    title: {
        fontSize: '16px',
        color: 'rgba(14, 80, 159, 1)',
        fontFamily: 'Roboto',
        fontWeight: 700,
    },
    text: {
        fontSize: '13px',
        fontFamily: 'Roboto',
        marginBottom: '12px',
    },
};
