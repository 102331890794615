import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import onoiwa from '../../img/more-onoiwa.png';
import onoake from '../../img/more-onoake.png';
import onogate from '../../img/more-onogate.png';
import rafa from '../../img/more-rafa.png';
import { Link } from 'react-router-dom';
import dict from '../../utils/dict';

export default ({ exclude, lang }: { exclude: string; lang: 'en' | 'id' }) => {
    return (
        <Container style={{ paddingTop: '80px', paddingBottom: '40px' }}>
            <p
                className="d-none d-md-block"
                style={{
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    fontSize: '2em',
                    color: 'rgba(14, 80, 159, 1)',
                    marginBottom: '20px',
                }}
            >
                {dict.other_product[lang]}
            </p>
            <p
                className="d-sm-none d-md-none"
                style={{
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    fontSize: '1.5em',
                    color: 'rgba(14, 80, 159, 1)',
                    marginBottom: '20px',
                }}
            >
                {dict.other_product[lang]}
            </p>

            <Row>
                {exclude !== 'onoiwa' && (
                    <Col xs={4} style={{ marginBottom: '60px' }}>
                        <div className="d-none d-lg-block" style={{ height: '30px' }} />
                        <div className="d-none d-md-block d-lg-none" style={{ height: '20px' }} />
                        <div className="d-none d-sm-block d-md-none" style={{ height: '16px' }} />
                        <div className="d-xs-block d-sm-none" style={{ height: '10px' }} />
                        <Link to="/onoiwa">
                            <img
                                src={onoiwa}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '280px',
                                    objectFit: 'contain',
                                }}
                            />
                        </Link>
                    </Col>
                )}
                {exclude !== 'onogate' && (
                    <Col xs={4} style={{ marginBottom: '60px' }}>
                        <Link to="/onogate">
                            <img
                                src={onogate}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '280px',
                                    objectFit: 'contain',
                                }}
                            />
                        </Link>
                    </Col>
                )}
                {exclude !== 'onoake' && (
                    <Col xs={4} style={{ marginBottom: '60px' }}>
                        <div className="d-none d-lg-block" style={{ height: '10px' }} />
                        <div className="d-none d-sm-block d-lg-none" style={{ height: '6px' }} />
                        <div className="d-xs-block d-sm-none" style={{ height: '3px' }} />
                        <Link to="/onoake">
                            <img
                                src={onoake}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '280px',
                                    objectFit: 'contain',
                                }}
                            />
                        </Link>
                    </Col>
                )}
                {exclude !== 'rafa' && (
                    <Col xs={4} style={{ marginBottom: '60px' }}>
                        <div className="d-none d-sm-block" style={{ height: '5px' }} />
                        <div className="d-xs-block d-sm-none" style={{ height: '3px' }} />
                        <Link to="/rafa-khomsah">
                            <img
                                src={rafa}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '280px',
                                    objectFit: 'contain',
                                }}
                            />
                        </Link>
                    </Col>
                )}
            </Row>
        </Container>
    );
};
