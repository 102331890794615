import { BASE_URL } from './../../constants';

const URL = `${BASE_URL}/store-locations?item_per_page=9999999`;

export default (onSuccess: (data: any) => void, onFailed: (e: any) => void) => {
    fetch(URL, {
        method: 'GET',
        // mode: 'no-cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((e) => {
            onFailed(e);
        });
};
