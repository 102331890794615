import React, { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Dropdown, Input, Alert, Form, Button } from 'reactstrap';
import Footer from '../Home/Footer';
import bgstore from '../../img/bg-store.png';
import mapstore from '../../img/map-store.png';
import caret from '../../img/caret.png';
import mylocation from '../../img/icon-my-location.png';
import Navbar from '../Navbar';
import dict from '../../utils/dict';
import apiStore from './apiStore';

const style = {
    title: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        color: 'rgba(14, 80, 159, 1)',
    },
};

const Menu = ({
    lang,
    storeData,
    setStoreData,
    rawData,
}: {
    rawData: any;
    lang: 'en' | 'id';
    storeData: any;
    setStoreData: any;
}) => {
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [keyword, setKeyword] = useState('');

    const setMyLocation = useCallback(() => {}, []);

    const selectCategory = useCallback(
        (category) => {
            setSelectedCategory(category);
            setCategoryOpen(false);
        },
        [selectedCategory]
    );

    const handleSearch = useCallback(
        (e) => {
            e.preventDefault();
            const res = rawData.filter((d: any) => {
                return (
                    d.name.toLowerCase().includes(keyword.toLowerCase()) ||
                    d.mobile.toLowerCase().includes(keyword.toLowerCase()) ||
                    d.address.toLowerCase().includes(keyword.toLowerCase())
                );
            });
            setStoreData(res);
        },
        [keyword, storeData]
    );

    return (
        <Form onSubmit={handleSearch}>
            <Row>
                <Col
                    lg={4}
                    md={12}
                    style={{
                        height: '45px',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <p
                        style={{
                            fontSize: '1.3em',
                            lineHeight: '1.3em',
                            margin: 0,
                            ...style.title,
                        }}
                    >
                        {dict.store_title[lang]}
                    </p>
                </Col>

                <Col lg={8} md={12}>
                    <Row>
                        {/* <Col sm={2} style={{ position: 'relative' }}> */}
                        {/* <div
                                onClick={() => setCategoryOpen(!categoryOpen)}
                                style={{
                                    border: '1px solid #ccc',
                                    height: '45px',
                                    borderRadius: '4px',
                                    fontFamily: 'Roboto',
                                    fontSize: '14px',
                                    color: '#666',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    padding: '0px 12px',
                                    marginBottom: '12px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                }}
                            >
                                {selectedCategory || dict.store_category[lang]}
                                <img
                                    src={caret}
                                    style={{
                                        position: 'absolute',
                                        right: '12px',
                                        top: '18px',
                                        width: '10px',
                                        height: '10px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>

                            {categoryOpen && (
                                <div
                                    style={{
                                        backgroundColor: '#fff',
                                        position: 'absolute',
                                        top: '41px',
                                        width: '90%',
                                        zIndex: 999,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        border: '1px solid #ddd',
                                        WebkitBoxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                                        MozBoxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                                        boxShadow: '0px 10px 36px -13px rgba(0,0,0,0.75)',
                                    }}
                                >
                                    <a
                                        onClick={() => selectCategory('Onoiwa')}
                                        style={{
                                            height: '40px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            padding: '0px 16px',
                                            cursor: 'pointer',
                                            backgroundColor: '#f5f5f5',
                                            fontFamily: 'Roboto',
                                            fontWeight: 500,
                                        }}
                                    >
                                        Onoiwa
                                    </a>
                                    <div style={{ height: '1px', backgroundColor: '#e5e5e5' }} />
                                    <a
                                        onClick={() => selectCategory('Onoake')}
                                        style={{
                                            height: '40px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            padding: '0px 16px',
                                            cursor: 'pointer',
                                            backgroundColor: '#f5f5f5',
                                            fontFamily: 'Roboto',
                                            fontWeight: 500,
                                        }}
                                    >
                                        Onoake
                                    </a>
                                    <div style={{ height: '1px', backgroundColor: '#e5e5e5' }} />
                                    <a
                                        onClick={() => selectCategory('Onogate')}
                                        style={{
                                            height: '40px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            padding: '0px 16px',
                                            cursor: 'pointer',
                                            backgroundColor: '#f5f5f5',
                                            fontFamily: 'Roboto',
                                            fontWeight: 500,
                                        }}
                                    >
                                        Onogate
                                    </a>
                                    <div style={{ height: '1px', backgroundColor: '#e5e5e5' }} />
                                    <a
                                        onClick={() => selectCategory('Rafa Khomsah')}
                                        style={{
                                            height: '40px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            padding: '0px 16px',
                                            cursor: 'pointer',
                                            backgroundColor: '#f5f5f5',
                                            fontFamily: 'Roboto',
                                            fontWeight: 500,
                                        }}
                                    >
                                        Rafa Khomsah
                                    </a>
                                </div>
                            )} */}
                        {/* </Col> */}
                        <Col xs={9}>
                            <div
                                style={{
                                    height: '45px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginBottom: '12px',
                                    position: 'relative',
                                }}
                            >
                                <Input
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    placeholder={dict.store_location[lang]}
                                    style={{
                                        fontFamily: 'Roboto',
                                        fontSize: '14px',
                                        height: '45px',
                                    }}
                                />
                                <a
                                    onClick={setMyLocation}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        width: '45px',
                                        height: '45px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img
                                        src={mylocation}
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </a>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <Button
                                onClick={handleSearch}
                                style={{
                                    height: '45px',
                                    borderRadius: '4px',
                                    fontFamily: 'Roboto',
                                    fontSize: '16px',
                                    backgroundColor: 'rgba(14, 80, 159, 1)',
                                    display: 'flex',
                                    color: '#fff',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 700,
                                    marginBottom: '12px',
                                    cursor: 'pointer',
                                    width: '100%',
                                }}
                            >
                                {dict.store_search[lang]}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default ({ lang, setLang, isLoggedIn, settings }: any) => {
    const [loading, setLoading] = useState(false);
    const [rawData, setRawData] = useState([]);
    const [storeData, setStoreData] = useState([]);

    useEffect(() => {
        setLoading(true);

        apiStore(
            (data: any) => {
                setRawData(data.store_locations);
                setStoreData(data.store_locations);
                setLoading(false);
            },
            (e: any) => {
                setLoading(false);
            }
        );
    }, []);

    return (
        <>
            <Container
                className="d-none d-lg-block"
                style={{ marginTop: '140px', marginBottom: '40px' }}
            >
                <Menu
                    lang={lang}
                    rawData={rawData}
                    storeData={storeData}
                    setStoreData={setStoreData}
                />
            </Container>

            <Container
                className="d-md-block d-lg-none"
                fluid
                style={{ marginTop: '120px', marginBottom: '40px' }}
            >
                <Menu
                    lang={lang}
                    rawData={rawData}
                    storeData={storeData}
                    setStoreData={setStoreData}
                />
            </Container>

            <div
                style={{
                    width: '100%',
                    minHeight: '300px',
                    maxHeight: '1000px',
                    position: 'relative',
                    paddingBottom: '180px',
                }}
            >
                <img
                    src={bgstore}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        bottom: 0,
                    }}
                />

                <Container className="d-none d-lg-block">
                    <Content lang={lang} storeData={storeData} loading={loading} />
                </Container>

                <Container className="d-md-block d-lg-none" fluid>
                    <Content lang={lang} storeData={storeData} loading={loading} />
                </Container>
            </div>

            <Footer lang={lang} settings={settings} />

            <Navbar {...{ lang, setLang }} />
        </>
    );
};

const Content = ({
    lang,
    storeData,
    loading,
}: {
    lang: 'en' | 'id';
    storeData: any;
    loading: boolean;
}) => {
    const [detail, setDetail] = useState<any>(null);
    const openMap = useCallback(
        (link) => {
            const win = window.open(link, '_blank');
            win!.focus();
        },
        [detail]
    );

    if (loading) {
        return (
            <div
                style={{
                    padding: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '1em',
                }}
            >
                {lang === 'en' ? 'Loading...' : 'Sedang memuat...'}
            </div>
        );
    }

    return (
        <Row>
            <Col md={4} sm={12} style={{ marginBottom: '20px' }}>
                <div
                    style={{
                        border: '1px solid #ddd',
                        backgroundColor: '#fff',
                        maxHeight: '500px',
                        overflow: 'auto',
                    }}
                >
                    {storeData.length === 0 ? (
                        <Alert color="warning" style={{ marginBottom: 0 }}>
                            No result.
                        </Alert>
                    ) : (
                        storeData.map((n: any, i: number) => {
                            return (
                                <div
                                    key={i}
                                    onClick={() => setDetail(n)}
                                    style={{
                                        padding: '20px',
                                        borderTop: i === 0 ? 'none' : '1px solid #ddd',
                                        cursor: 'pointer',
                                        backgroundColor:
                                            detail !== null && detail.id === n.id
                                                ? '#f5f5f5'
                                                : '#fff',
                                    }}
                                >
                                    <p
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontSize: '14px',
                                            fontWeight: 700,
                                            marginBottom: '4px',
                                        }}
                                    >
                                        {n.name}
                                    </p>
                                    <p
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontSize: '12px',
                                            marginBottom: '4px',
                                        }}
                                    >
                                        {n.address}
                                    </p>
                                    <p
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontSize: '12px',
                                            marginBottom: '12px',
                                        }}
                                    >
                                        {n.mobile}
                                    </p>
                                    <a
                                        onClick={() => openMap(n.google_map_url)}
                                        style={{
                                            backgroundColor: '#0e509f',
                                            fontFamily: 'Roboto',
                                            fontWeight: 700,
                                            fontSize: '14px',
                                            color: '#fff',
                                            padding: '8px 16px',
                                        }}
                                    >
                                        {lang === 'en' ? 'Open in Maps' : 'Buka di Maps'}
                                    </a>
                                </div>
                            );
                        })
                    )}
                </div>
            </Col>

            <Col md={8} sm={12} style={{ position: 'relative' }}>
                <img
                    className="d-none d-md-block"
                    src={detail !== null ? detail.image_map_url : mapstore}
                    style={{ width: '100%', height: '502px', objectFit: 'cover' }}
                />
                <img
                    className="d-sm-block d-md-none"
                    src={detail !== null ? detail.image_map_url : mapstore}
                    style={{ width: '100%', height: '450px', objectFit: 'cover' }}
                />
            </Col>
        </Row>
    );
};
