import React from 'react';
import Footer from '../Home/Footer';
import HeroImage from '../Home/HeroImage';
import ProductBanner from './ProductBanner';
import ProductContent from './ProductContent';
import Navbar from '../Navbar';

export default ({ lang, setLang, isLoggedIn, settings }: any) => {
    return (
        <>
            <ProductBanner cover="prescription" lang={lang} />
            <ProductContent title="Prescription" data={['onoiwa', 'onogate']} lang={lang} />
            <Footer lang={lang} settings={settings} />

            <Navbar {...{ lang, setLang, selected: 'products' }} />
        </>
    );
};
