import React, { useState, useCallback } from 'react';
import {
    Form,
    InputGroup,
    InputGroupAddon,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import iconemail from '../../img/icon-email.png';
import iconpassword from '../../img/icon-password.png';
import iconeye from '../../img/icon-eye.png';
import iconname from '../../img/icon-name.png';
import iconphone from '../../img/icon-phone.png';
import { Link } from 'react-router-dom';
import { registerEmail } from './apiAuth';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

interface IRegisterProps {
    lang: 'en' | 'id';
    setMode(mode: 'login' | 'register'): void;
    handleRegister(): void;
}

export default ({ lang, setMode, handleRegister }: IRegisterProps) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [modalText, setModalText] = useState('');
    const [viewPassword, setViewPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(() => {
        if (name !== '' && email !== '' && phone !== '' && password !== '') {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(email).toLowerCase())) {
                setModalText(
                    lang === 'en'
                        ? 'Email is not valid, please input a valid email.'
                        : 'Email tidak benar, tolong masukkan email yang benar.'
                );
                setShowModalRegister(true);
                return;
            }

            if (password.length < 6) {
                setModalText(
                    lang === 'en'
                        ? 'Password must be at least 6 characters long.'
                        : 'Password harus minimal 6 karakter.'
                );
                setShowModalRegister(true);
                return;
            }

            setLoading(true);

            registerEmail(
                {
                    register_from: 'email',
                    email,
                    name,
                    mobile: phone,
                    password,
                    password_confirmation: password,
                },
                (res) => {
                    console.log(res);
                    setLoading(false);

                    if (res === 'taken') {
                        setModalText(
                            lang === 'en'
                                ? 'The email is already been taken'
                                : 'Email sudah pernah digunakan.'
                        );
                        setShowModalRegister(true);
                    } else {
                        setModalText(
                            lang === 'en'
                                ? 'Register success, please login again with your newly created account.'
                                : 'Registrasi berhasil. Silakan masuk dengan akun yang baru Anda buat.'
                        );
                        setShowModalRegister(true);

                        setEmail('');
                        setName('');
                        setPhone('');
                        setPassword('');
                    }
                },
                (e) => {
                    setLoading(false);
                }
            );
            // setShowModalRegister(true);
        }
    }, [name, email, phone, password]);

    return (
        <BlockUi tag="div" blocking={loading}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <InputGroup
                    style={{
                        width: '100%',
                        maxWidth: '300px',
                        marginBottom: '12px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}
                >
                    <InputGroupAddon
                        addonType="prepend"
                        style={{
                            width: '45px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={iconname}
                            style={{
                                width: '16px',
                                height: '16px',
                                objectFit: 'contain',
                            }}
                        />
                    </InputGroupAddon>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={lang === 'en' ? 'Full Name' : 'Nama Lengkap'}
                        style={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            height: '45px',
                            border: 'none',
                        }}
                    />
                </InputGroup>

                <InputGroup
                    style={{
                        width: '100%',
                        maxWidth: '300px',
                        marginBottom: '12px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}
                >
                    <InputGroupAddon
                        addonType="prepend"
                        style={{
                            width: '45px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={iconemail}
                            style={{
                                width: '16px',
                                height: '16px',
                                objectFit: 'contain',
                            }}
                        />
                    </InputGroupAddon>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        type="email"
                        style={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            height: '45px',
                            border: 'none',
                        }}
                    />
                </InputGroup>

                <InputGroup
                    style={{
                        width: '100%',
                        maxWidth: '300px',
                        marginBottom: '12px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}
                >
                    <InputGroupAddon
                        addonType="prepend"
                        style={{
                            width: '45px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={iconphone}
                            style={{
                                width: '16px',
                                height: '16px',
                                objectFit: 'contain',
                            }}
                        />
                    </InputGroupAddon>
                    <Input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder={lang === 'en' ? 'Phone Number' : 'Nomor Telepon'}
                        type="tel"
                        pattern="[0-9]*"
                        style={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            height: '45px',
                            border: 'none',
                        }}
                    />
                </InputGroup>

                <InputGroup
                    style={{
                        width: '100%',
                        maxWidth: '300px',
                        marginBottom: '8px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}
                >
                    <InputGroupAddon
                        addonType="prepend"
                        style={{
                            width: '45px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={iconpassword}
                            style={{
                                width: '16px',
                                height: '16px',
                                objectFit: 'contain',
                            }}
                        />
                    </InputGroupAddon>
                    <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        type={viewPassword ? 'text' : 'password'}
                        style={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            border: 'none',
                            height: '45px',
                        }}
                    />
                    <InputGroupAddon
                        addonType="append"
                        onClick={() => setViewPassword(!viewPassword)}
                        style={{
                            width: '45px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={iconeye}
                            style={{
                                width: '16px',
                                height: '16px',
                                objectFit: 'contain',
                            }}
                        />
                    </InputGroupAddon>
                </InputGroup>

                <a
                    // type="button"
                    style={{
                        display: 'flex',
                        width: '100%',
                        maxWidth: '300px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '45px',
                        backgroundColor:
                            loading ||
                            name === '' ||
                            email === '' ||
                            password === '' ||
                            phone === ''
                                ? '#999'
                                : 'rgba(14, 80, 159, 1)',
                        color: '#fff',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 700,
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    // disabled={loading}
                >
                    {lang === 'en' ? 'Register' : 'Daftar'}
                </a>

                <a
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        width: '100%',
                        maxWidth: '300px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '40px',
                        border: '1px solid rgba(14, 80, 159, 1)',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        color: 'rgba(14, 80, 159, 1)',
                        fontWeight: 700,
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => setMode('login')}
                >
                    {lang === 'en' ? '< Back' : '< Kembali'}
                </a>
            </div>

            <Modal
                isOpen={showModalRegister}
                toggle={() => setShowModalRegister(false)}
                style={{
                    marginTop: '150px',
                }}
            >
                <ModalBody>
                    <p style={{ marginBottom: 0, fontFamily: 'Roboto' }}>{modalText}</p>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        onClick={() => {
                            setShowModalRegister(false);
                        }}
                        style={{
                            backgroundColor: '#0e509f',
                            fontFamily: 'Roboto',
                            fontWeight: 700,
                            width: '150px',
                            height: '40px',
                            borderRadius: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#fff',
                            textDecoration: 'none',
                        }}
                    >
                        OK
                    </button>
                </ModalFooter>
            </Modal>
        </BlockUi>
    );
};
